import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-dialog-cambiar-correo',
  templateUrl: './dialog-cambiar-correo.component.html',
  styleUrls: ['./dialog-cambiar-correo.component.css']
})
export class DialogCambiarCorreoComponent implements OnInit {

  cambiarCorreoForm: FormGroup = new FormGroup({
    emailActual: new FormControl(),
    emailNuevo: new FormControl('', [Validators.required, Validators.email]),
    confirmarEmail: new FormControl('', [Validators.required, Validators.email])
  })

  loadButton: boolean = false;
  jsonUsuario: any;
  actualizo: boolean;
  hayMsje: boolean = false;
  emailsDistintos: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    public dialogo: MatDialogRef<DialogCambiarCorreoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.jsonUsuario = data;
  }

  async ngOnInit() {
    await this.iniciarForm();
  }

  async iniciarForm() {
    this.cambiarCorreoForm = this.formBuilder.group({
      emailActual: [{ value: this.jsonUsuario.email ? this.jsonUsuario.email : 'No tiene', disabled: true}],
      emailNuevo: new FormControl('', [Validators.required, Validators.email]),
      confirmarEmail: new FormControl('', [Validators.required, Validators.email])
    })
  }

  async cambiarCorreo() {
    this.loadButton = true;
    this.emailsDistintos = false;
    if (this.cambiarCorreoForm.get('emailNuevo').value != this.cambiarCorreoForm.get('confirmarEmail').value) {
      this.emailsDistintos = true;
      this.loadButton = false;
    } else if (!this.cambiarCorreoForm.invalid) {
      this.jsonUsuario.email = this.cambiarCorreoForm.get('emailNuevo').value;
      this.jsonUsuario.mailActualizado = true;
      await this.userService.guardarUsuario(this.jsonUsuario).toPromise().then(resp => {
        // console.log(resp)
        if (resp.ok) {
          this.actualizo = true;
        } else {
          this.actualizo = false;
        }
        this.hayMsje = true;
        this.loadButton = false;
      }, error => {
        console.log(error)
        this.actualizo = false;
        this.hayMsje = true;
        this.loadButton = false;
      })
    } else {
      this.loadButton = false;
      // console.log('error')
    }
  }

  // confirmarMail(): ValidatorFn {
  //   return (control: AbstractControl): ValidationErrors | null => {
  //     // const forbidden = nameRe.test(control.value);
  //     // return forbidden ? {forbiddenName: {value: control.value}} : null;
  //     console.log(control.value)
  //     return this.cambiarCorreoForm.get('emailNuevo').value != control.value ? {confirmarEmail: {value: control.value}} : null;
  //   };
  // }

  getErrorMessage(formControl) {
    if (this.cambiarCorreoForm.get(formControl).hasError('required')) {
      return "Este campo no puede estar vacio.";
    }

    if (this.cambiarCorreoForm.get(formControl).hasError('email')) {
      return 'El correo no es valido.';
    }
  }

}
