import { Component, OnInit, ViewChild  } from '@angular/core';
import { ComunicacionService } from '../services/comunicacion.service';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator'; 
import { Location } from '@angular/common';
import * as moment from 'moment';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import { Router } from '@angular/router';
import { Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { UserService } from '../services/user.service';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { RespuestaCarga } from '../dialog-mensaje/dialog-mensaje.component';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';

@Component({
  selector: 'app-listar-eventos-sociales',
  templateUrl: './listar-eventos-sociales.component.html',
  styleUrls: ['./listar-eventos-sociales.component.css']
})
export class ListarEventosSocialesComponent implements OnInit {
  @Input() inputSideNav: MatSidenav;

  dataSourceItems: any;
  displayedColumnasItems: string[] = [ 'tipoEvento', 'fechaEvento', 'sectorEvento', 'descripcionEvento', 'action'];
  loading = true;
  filtroBusquedaForm: FormGroup;
  sinEventos: any;
  usuarioLogueado; 
  mensajeSP: String;
  eventoForm: UntypedFormGroup;

  tipoEventos: any[] = [{id: null, descripcion: 'Todos'}]

  protected onDestroyProperty = new Subject<void>();


  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private userService: UserService,
    private comunicacionService: ComunicacionService,
    public location: Location,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private router: Router
  ) { }

  async ngOnInit() {
    this.getUser();
    this.buildFiltroBusquedaForm();
    this.getEventosSocialesTipo();
    this.buscarEventosSociales();
  }

  private buildFiltroBusquedaForm() {
    this.filtroBusquedaForm = this.formBuilder.group({
      fechaDesde: [new Date()] ,
      fechaHasta: [new Date()],
      tipoEvento: [null],
    });
  }

  async buscarEventosSociales() {
    //this.loading = false;
    this.loading = true;
    this.sinEventos = false;

    const filtroBusqueda = {
      fechaDesde: moment(this.filtroBusquedaForm.get('fechaDesde').value).format('DD/MM/YYYY'),
      fechaHasta: moment(this.filtroBusquedaForm.get('fechaHasta').value).format('DD/MM/YYYY'),
      tipoEvento: this.filtroBusquedaForm.get('tipoEvento').value,
    };
    
    //  RECUPERO EVENTOS SOCIALES
    await this.comunicacionService.getEventosSociales(filtroBusqueda).toPromise().then(
      (respuesta) => {
        if(respuesta){
          if (respuesta.ok) {
            if (respuesta.eventos.length !== 0) {
              this.sinEventos = false;
              this.dataSourceItems = new MatTableDataSource(respuesta.eventos);
              this.dataSourceItems.paginator = this.paginator;
            } else {
              this.sinEventos = true;
            }
            //this.loading = false;
          }else{
            this.sinEventos = true;
            this.mensajeSP = respuesta.mensaje;
          } 
          this.loading = false;
        }               
      },
      (error) => {
        console.log(error);
      }
    );  

  }

  agregarEvento(){
    this.comunicacionService.unsetEvento();
    this.router.navigateByUrl('/agregar-evento');
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceItems.filter = filterValue.trim().toLowerCase();
    if (this.dataSourceItems.paginator) {
      this.dataSourceItems.paginator.firstPage();
    }
  }

  // private buildFormEditar(evento) {
  //   this.eventoForm = this.formBuilder.group({
  //     id: [evento.id],
  //     tipo: [evento.tipo.descripcion, Validators.required],
  //     descripcion: [evento.descripcion, Validators.required],
  //     tipoDocumento: [evento.tipoDocumento],
  //     numeroDocumento: [evento.numeroDocumento],
  //     numeroLegajo: [evento.numeroLegajo],
  //     numeroMatricula: [evento.numeroMatricula],
  //     fecha: [evento.fecha, Validators.required],
  //     sector: [evento.sector, Validators.required],  
  //     estado: [evento.estado],    
  //     fechaCambioEstado: [evento.fechaCambioEstado],
  //     usuarioEstado: [evento.usuarioEstado],
  //     fechaCarga: [evento.fechaCarga],
  //     usuarioCarga: [evento.usuarioCarga],
  //     login_estado: [this.usuarioLogueado.usuario],      
  //   });
  // }

  editarEvento(evento) {   
    this.comunicacionService.setCurrentEvento(evento);
    this.router.navigateByUrl('/agregar-evento');
  }

  abrirDialogConfirmacion(evento) {
    this.dialog.open( DialogConfirmacionComponent, {
      data: '¿Está seguro que desea eliminar este evento? ',
    })

    .afterClosed()
      .subscribe(
        (confirmado: boolean) => {
          if (confirmado) {
            this.eliminarEvento(evento, this.usuarioLogueado);
            let r: RespuestaCarga
            r = {
              ok: true,
              mensaje: "Evento borrado satisfactoriamente.",
            }
            this.openDialog(r)
          } else {
            this.dialog.closeAll();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  openDialog(respuesta: RespuestaCarga): void {
    const dialogRef = this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: respuesta,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (respuesta.ok) {
        this.router.navigate(['/eventosSociales']);
      }
    });
  }

  eliminarEvento(evento, usuarioLogueado){
    this.comunicacionService.eliminarEvento(evento, usuarioLogueado).subscribe(
      (resul) => {
        if  (resul.ok) {
          this.buscarEventosSociales();
          /*this.dialog.open( DialogConfirmacionComponent, {
            data: resul.mensaje,
          })    */      
        }
      },
      (error) => {
        console.log(error);
      }
    );
  } 

  async getUser() {
    this.usuarioLogueado = this.userService.getLoggedIn();
  }


  async getEventosSocialesTipo(){
    await this.comunicacionService.getEventosSocialesTipo().subscribe(
      (resul) => {
        if (resul.ok) {
          for (let elem of resul.tipo_eventos) {
            this.tipoEventos.push(elem);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    )
  }

}
