 <div class="container-fluid" >
    <mat-progress-bar mode="indeterminate" [hidden]="!cargando"></mat-progress-bar>

    <div class="row" [hidden]="cargando" style="margin-top: 1%;">
        <div class="col">
            <h2>Guardia - Pacientes atendidos en 7 días</h2>
            <mat-form-field style="margin-top: 1%;">
                <input matInput [matDatepicker]="dp" placeholder="Fecha" [max]="maxDate" [formControl]="dateHasta" (dateChange)="cambioFechaDesde($event)">
                <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                <mat-datepicker #dp></mat-datepicker>
                <!-- <mat-error *ngIf="dateDesde.invalid">La fecha no es valida.</mat-error> -->
            </mat-form-field>
            <div class="row" [hidden]="errorGetTriage || noExistenPacientes" style="height: fit-content;">
            <div class="col" [hidden]="cargando" style="max-width: fit-content;">
                <figure class="table-container" style="max-height: fit-content; width: fit-content; margin-bottom: 5% ">
                    <table class="table table-striped table-bordered" style="max-height: 80%;">
                        <thead id="head-tabla" style="width: 1px;">
                            
                            <tr>
                                <th class="bkgDfl"> Horario </th>
                                <th> Total </th>                                
                                <th *ngFor="let item of fechasPacientes"> {{ item }} </th>
                            </tr>
                            
                        </thead>
                        <tbody id="body-tabla" style="white-space: nowrap;">
                            <tr *ngFor="let item of arrayTriageDelDia" style="width: 1px;">
                                <th> {{ item.horario }} </th>
                                <th> {{ item.total }} </th>
                                <th style="width: fit-content; white-space: nowrap"> {{ item.cantFecha1 }} </th>
                                <th> {{ item.cantFecha2 }} </th>
                                <th> {{ item.cantFecha3 }} </th>
                                <th> {{ item.cantFecha4 }} </th>
                                <th> {{ item.cantFecha5 }} </th>
                                <th> {{ item.cantFecha6 }} </th>
                                <th> {{ item.cantFecha7 }} </th>
                            </tr>
                            <tr *ngFor="let item of arrayTriageTotales">
                                <th> {{ item.total }} </th>
                                <th> {{ item.totalAbsoluto }} </th>
                                <th> {{ item.totalFecha1 }} </th>
                                <th> {{ item.totalFecha2 }} </th>
                                <th> {{ item.totalFecha3 }} </th>
                                <th> {{ item.totalFecha4 }} </th>
                                <th> {{ item.totalFecha5 }} </th>
                                <th> {{ item.totalFecha6 }} </th>
                                <th> {{ item.totalFecha7 }} </th>
                            </tr>
                        </tbody>
                    </table>
                </figure>
            </div>
            <div class="col" [hidden]="cargando">
                <div class="textos" *ngIf="!cargandoTotal">
                    <h2> Para las fechas del {{ fechasPacientes[0] }} al {{ fechasPacientes[6] }} se tienen:</h2>
                    <h2 >
                        - En total: {{ arrayTriageTotales[0].totalAbsoluto }} pacientes atendidos
                    </h2>                
                    <h2>
                        - Con un promedio de: {{ promedio }} pacientes atendidos por dia
                    </h2>
                </div>
            </div>
        </div>

            <div class="row">
                <div class="col" [hidden]="!errorGetTriage">
                    <h2>Ocurri&oacute; un error al obtener los datos</h2>
                </div>
                <div class="col" [hidden]="!noExistenPacientes">
                    <h2>  No existen pacientes ingresados para las fechas seleccionada</h2>
                </div>
            </div>
        </div>
    </div>
</div>
