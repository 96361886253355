import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { UrlResolverService } from './urlResolver.service';

@Injectable({
  providedIn: 'root'
})
export class MutualesService {

  baseUrl: string;
  horusUrl: string;

  constructor(
    private httpClient: HttpClient,
    private urlResolver: UrlResolverService
  ) {
    this.baseUrl = AppComponent.baseUrl;
  }

  getMutuales(horusUrl) {
    return this.httpClient.get<any>(horusUrl+'mutuales/obtenerMutuales');
  }
}
