import { Component, OnInit } from '@angular/core';
import { RespuestaCarga } from '../dialog-mensaje/dialog-mensaje.component';
import { TableroService } from '../services/tablero.service';

export interface Tableros {
  id: number;
  tablero: string;
  link: string;
}


@Component({
  selector: 'app-tableros',
  templateUrl: './tableros.component.html',
  styleUrls: ['./tableros.component.css']
})
export class TablerosComponent implements OnInit {
  cargado: boolean;  
  displayedColumns: string[] = ['tablero', 'link'];
  respuesta: Tableros[];
  mensaje: string;


  constructor(
    private tableroService: TableroService
  ) 
  {}

  ngOnInit() {
    this.cargado = false;
    this.tableroService.getBiTableros().subscribe((data)=> {      
      this.respuesta = data.tableros.sort(this.compare);      
      this.cargado= true;
      
      }, (error) => {
        console.log(error);
      });
  }

  //Funcion que compara el nombre de los tableros para ordernarlos alfabeticamente
  compare(a,b) {
    if (a.tablero < b.tablero){
      return -1
    }
    if (a.tablero > b.tablero){
      return 1
    }
    return 0;
  }

}
