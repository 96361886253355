<div class="container-fluid">
  <div class="col-sm-12 py-1 my-1">
    <div class="container py-0 my-0">
      <mat-card class="py-1 my-1" style="text-align: center">
        <mat-card-title class="py-0 my-0"
          >Modificar de Comunicación</mat-card-title
        >
        <form
          class="ui form"
          [formGroup]="noticiasForm"
          enctype="multipart/form-data"
          *ngIf="this.noticiaModificar != null"
        >
          <mat-card-content>
            <div class="form-row">
              <div
                class="form-group col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
              >
                <mat-form-field>
                  <input
                    matInput
                    formControlName="titulo"
                    placeholder="Título"
                    required
                  />
                </mat-form-field>
                <div
                  class="alert alert-danger p-1 m-1"
                  *ngIf="
                    noticiasForm.get('titulo').errors &&
                    noticiasForm.get('titulo').touched
                  "
                >
                  <p *ngIf="noticiasForm.get('titulo').hasError('required')">
                    Debe poseer titulo
                  </p>
                </div>
              </div>

              <!-- <div
                class="form-group col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
              >
                <mat-form-field>
                  <mat-select
                    formControlName="aplicaciones"
                    multiple
                    placeholder="Sitios a mostrar"
                    required
                  >
                    <mat-option
                      *ngFor="let aplicacion of listaAplicaciones"
                      [value]="aplicacion.app_id"
                      >{{ aplicacion.nombre | mayusPipe }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
                <div
                  class="alert alert-danger p-1 m-1"
                  *ngIf="
                    noticiasForm.get('aplicaciones').errors &&
                    noticiasForm.get('aplicaciones').touched
                  "
                >
                  <p
                    *ngIf="
                      noticiasForm.get('aplicaciones').hasError('required')
                    "
                  >
                    Debe poseer al menos una aplicacion
                  </p>
                </div>
              </div> -->
              <hr />

              <div
                class="form-group col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
              >
                <mat-form-field>
                  <mat-label>Fecha de publicacion</mat-label>
                  <input
                    matInput
                    [matDatepicker]="fecha_publicacion"
                    formControlName="fecha_publicacion"
                    required
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="fecha_publicacion"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #fecha_publicacion></mat-datepicker>
                </mat-form-field>
                <div
                  class="alert alert-danger p-1 m-1"
                  *ngIf="
                    noticiasForm.get('fecha_publicacion').errors &&
                    noticiasForm.get('fecha_publicacion').touched
                  "
                >
                  <p
                    *ngIf="
                      noticiasForm.get('fecha_publicacion').hasError('required')
                    "
                  >
                    Debe poseer fecha de publicacion
                  </p>
                </div>
              </div>
            </div>

            <div class="form-row-centre py-0 my-0">
              <ckeditor
                [editor]="editor"
                type="ck-editor__editable"
                [config]="{
                  toolbar: [
                    'heading',
                    '|',
                    'fontsize',
                    'fontfamily',
                    '|',
                    'bold',
                    'italic',
                    'underline',
                    'strikethrough',
                    'highlight',
                    '|',
                    'alignment',
                    '|',
                    'numberedlist',
                    'bulletedlist',
                    '|',
                    'link',
                    'blockquote',
                    'inserttable',
                    '|',
                    'undo',
                    'redo'
                  ]
                }"
                formControlName="texto"
                (ready)="onReady($event)"
                *ngIf="mostrartexto"
                required
              ></ckeditor>
              <div
                class="alert alert-danger p-1 m-1"
                *ngIf="
                  noticiasForm.get('texto').errors &&
                  noticiasForm.get('texto').touched
                "
              >
                <p *ngIf="noticiasForm.get('texto').hasError('required')">
                  Debe poseer un cuerpo
                </p>
              </div>
            </div>
            <div>
              <!-- <mat-slide-toggle formControlName="estado" class="float-right">
                Noticia Activa
              </mat-slide-toggle> -->

              
              <!-- <div>                
                <mat-form-field id="mySelect" appearance="fill" class="float-right">
                  
                  <mat-label>Sitios activos</mat-label>
                  <mat-select #mySelect [formControl]="sitiosActivosFormControl" (selectionChange)="seleccionar($event.value)" multiple>
                    
                    <mat-option value='todos' id="opcion_todos">Todos</mat-option>
                    <mat-option value='ninguno' id="opcion_ninguno">Ninguno</mat-option>
                    
                    <div *ngIf="noticiasForm.get('sitiosActivosFormControl').getRawValue()!= null && noticiasForm.value.sitiosActivosFormControl.getRawValue()[0] != ''">
                    <mat-option *ngFor="let sitio of sitios" [value]="sitio">
                      {{sitio.nombre}}
                    </mat-option>
                  </div>
                    
                  </mat-select>
                </mat-form-field>

                <div *ngIf="ninguno_selected" class="float-right" id="mensaje_ninguno_selected">
                  La noticia no se mostrará en <b>NINGÚN</b> sitio. <br>
                </div>

                <div *ngIf="!noticiasForm.value.sitiosActivosFormControl.touched && noticiasForm.value.sitiosActivosFormControl.getRawValue().length==1" appearance="fill" class="float-right">                
                  <b> Sitio activo hasta el momento: </b>
                    <div style="display: flex;">
                      <li>{{sitiosActivosFormControl.getRawValue()[0].nombre}}</li>
                    </div>              
                </div>

                <div *ngIf="!noticiasForm.value.sitiosActivosFormControl.touched && noticiasForm.value.sitiosActivosFormControl.getRawValue().length>1" appearance="fill" class="float-right">                
                  <b> Sitios activos hasta el momento: </b>
                    <div style="display: flex;" *ngFor="let s of noticiasForm.value.sitiosActivosFormControl.getRawValue()">
                      <li>{{s.nombre}}</li>
                    </div>              
                </div>
              </div> -->

              <!-- <section [formGroup]="noticiasForm" class="float-right">
                <span class="example-list-section">
                  <mat-checkbox class="example-margin"
                                [checked]="todos_seleccionados"
                                [color]="Warn"
                                [indeterminate]="someComplete()"
                                (change)="setAll($event.selected)">
                    Todos
                  </mat-checkbox>
                </span>

                <span>
                  <ul>
                    <li *ngFor="let sitio of sitios">
                      <mat-checkbox 
                        [(ngModel)]="sitio.selected"
                        [color]="sitio.color"
                        (ngModelChange)="updateAllComplete()"
                      >
                        {{sitio.nombre}}
                      </mat-checkbox>
                    </li>
                  </ul>
                </span>
              </section> -->

              <section class="float-right" style="margin: 1vh">
                <b>Sitios activos</b> <br>
                <div id="contenedor_checkboxes">
                  <div id="contenedor_sitios">
                    <div *ngFor="let sitio of sitios" id="sitio">
                      <input 
                      id="{{sitio.formControl}}"
                      type="checkbox"
                      formControlName="{{sitio.formControl}}"
                      >
                      {{sitio.nombre}}
                    </div>                    
                  </div>
                </div>
              </section>
            </div>
          </mat-card-content>

          <mat-card-actions class="p-0 m-0">
            <div style="text-align: left">
              <p>
                Imagen actual:
                {{
                  noticiaModificar.nombreImagen !== 'null'
                    ? noticiaModificar.nombreImagen
                    : 'no hay'
                }}
              </p>
              <input
                type="file"
                (change)="onFileChange($event)"
                accept="image/png, image/jpeg"
              />
            </div>
            <div style="margin-left: 20vw;">
              <button
                mat-raised-button
                color="warn"
                type="button"
                class="btn btn-primary btn"
                style="margin: 2px"
                (click)="cancelar()"
              >
                Cancelar
              </button>
              <button
                mat-raised-button
                color="primary"
                [disabled]="noticiasForm.invalid"
                class="btn btn-primary btn"
                style="margin: 2px"
                type="submit"
                (click)="onSubmit()"
              >
                Confirmar
              </button>
              <mat-spinner
                diameter="22"
                [hidden]="!loading"
                style="display: inline-block; margin-left: 5%; margin-top: 1%"
              ></mat-spinner>
            </div>
          </mat-card-actions>
        </form>
      </mat-card>
    </div>
  </div>
</div>
