import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class TableroService {

  baseUrl: string;
  private currentTriageSubject: BehaviorSubject<any>;
  public currentTriage: Observable<any>;

  constructor(
    private httpClient: HttpClient
  ) { 
    this.baseUrl = AppComponent.baseUrl;
    this.currentTriageSubject = new BehaviorSubject<any>(JSON.parse(sessionStorage.getItem('currentPatient')))
    this.currentTriage = this.currentTriageSubject.asObservable()
  }

  atencionesEnGuardia(fecha: String) {
    return this.httpClient.post<any>(this.baseUrl+ 'tablerocovid/atendicionesenguardia', fecha);
  }

  internadosCovidPosEstada() {
    return this.httpClient.get<any>(this.baseUrl+ 'tablerocovid/internadoscovidxestada');
  }

  cantInternadosCovid() {
    return this.httpClient.get<any>(this.baseUrl+ 'tablerocovid/cantinternadoscovid');
  }

  cantSeguimientoDomiciliario() {
    return this.httpClient.get<any>(this.baseUrl+ 'tablerocovid/cantseguimientodomiciliario');
  }

  getFarmaceuticoCovid() {
    return this.httpClient.get<any>(this.baseUrl+ 'tablerocovid/getfarmaceuticocovid');
  }

  getCasosCovid() {
    return this.httpClient.get<any>(this.baseUrl+ 'tablerocovid/getcasoscovid');
  }

  getBiTableros() {
    return this.httpClient.get<any>(this.baseUrl+ 'tableros/getbitableros');
  }

  getTriageDelDia(horusUrl, jsonTriage) {
    return this.httpClient.post<any>(horusUrl+'triage/getTriagePorFiltro', jsonTriage);
  }

  getTriagePorFecha(horusUrl, jsonTriage) {
    return this.httpClient.post<any>(horusUrl+'triage/getTriagePorFecha', jsonTriage);
  }

  getBoletasDelDia(jsonBoleta) {
    return this.httpClient.post<any>(this.baseUrl+'tableros/getBoletasPorFecha', jsonBoleta);
  }

  setCurrentTriage (triage){
    sessionStorage.setItem('currentTriage', JSON.stringify(triage));
  }

  getCurrentTriage () {
    return JSON.parse(sessionStorage.getItem('currentTriage'));
  }

  getDetalleBoleta(jsonDetalle) {
    return this.httpClient.post<any>(this.baseUrl+'tableros/getdetalleboleta', jsonDetalle);
  }
}
