import { Component, OnInit } from '@angular/core';
import { TableroService } from '../services/tablero.service';
import { UrlResolverService } from '../services/urlResolver.service';
import * as moment from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatTableDataSource } from '@angular/material/table';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-pacientes-guardia',
  templateUrl: './pacientes-guardia.component.html',
  styleUrls: ['./pacientes-guardia.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})

export class PacientesGuardiaComponent implements OnInit {

  cargando: boolean = true;
  errorGetTriage: boolean = false;
  noExistenPacientes: boolean = false;
  dateDesde = new FormControl(moment().subtract(1, 'day'));
  maxDate = moment();
  arrayPrfesionales = [];
  arrayTriageDelDia: any;
  totalTriages: number = 0;
  totalTriageProf: number = 0;
  existenRojo: boolean = false;
  cargandoBoletas: boolean = true;
  errorGetBoletas: boolean = false;
  noExistenBoletas: boolean = false;
  totalBoletas: number = 0;
  profesionales = [];
  header = [];
  cantProfesionales = [];
  totalProfesionales = {};
  serviciosProfesionalAux = [];
  cantTotalColores = {verde: 0, amarillo: 0, rojo: 0}; 

  constructor(
    private tableroService: TableroService,
    private urlResolverService: UrlResolverService
  ) { }

  ngOnInit() {
    this.getTriagesDelDia(moment().subtract(1, 'day').startOf('day').format("DD-MM-YYYY HH:mm:ss"), moment().subtract(1, 'day').endOf('day').format("DD-MM-YYYY HH:mm:ss"));
    this.getBoletasDelDia(moment().subtract(1, 'day').format("DD/MM/YYYY"));
  }

  async contarProfesionales(listaTriage) {
    this.arrayPrfesionales = [];

    listaTriage.forEach(triage => {
      if (!this.arrayPrfesionales.some(t => t.medDerivado.matricula == triage.medDerivado.matricula && t.servicio.id == triage.servicio.id)) {
        switch (triage.color.nombre) {
          case 'verde':
            triage.cantVerde = 1;
            triage.cantAmarillo = 0;
            triage.cantRojo = 0;
            triage.total = 1;
            this.totalTriageProf++;
            this.arrayPrfesionales.push(triage);
            break;
          case 'amarillo':
            triage.cantVerde = 0;
            triage.cantAmarillo = 1;
            triage.cantRojo = 0;
            triage.total = 1;
            this.totalTriageProf++;
            this.arrayPrfesionales.push(triage);
            break;
          case 'rojo':
            triage.cantVerde = 0;
            triage.cantAmarillo = 0;
            triage.cantRojo = 1;
            triage.total = 1;
            this.totalTriageProf++;
            this.arrayPrfesionales.push(triage);
            break;
          default:
            break;
        }
      } else {
        switch (triage.color.nombre) {
          case 'verde':
            const triageX = this.arrayPrfesionales.find(tri => tri.medDerivado.matricula == triage.medDerivado.matricula && tri.servicio.id == triage.servicio.id);
            triageX.cantVerde++;
            triageX.total++;
            this.totalTriageProf++;
            break;
          case 'amarillo':
            const triageY = this.arrayPrfesionales.find(tri => tri.medDerivado.matricula == triage.medDerivado.matricula && tri.servicio.id == triage.servicio.id);
            triageY.cantAmarillo++;
            triageY.total++;
            this.totalTriageProf++;
            break;
          case 'rojo':
            const triageZ = this.arrayPrfesionales.find(tri => tri.medDerivado.matricula == triage.medDerivado.matricula && tri.servicio.id == triage.servicio.id);
            triageZ.cantRojo++;
            triageZ.total++;
            this.totalTriageProf++;
            break;
          default:
            break;
        }
      }
    });

    this.arrayPrfesionales.sort((a,b) => {
      if(a.medDerivado.nombreCompleto < b.medDerivado.nombreCompleto) { return -1; }
      if(a.medDerivado.nombreCompleto > b.medDerivado.nombreCompleto) { return 1; }
    });
  }

  async contarTriage(listaTriage) {
    this.cantTotalColores = {verde: 0, amarillo: 0, rojo: 0};
    this.arrayTriageDelDia = [
      { hora: '00:00 ', cantAmarillo: 0, cantVerde: 0, cantRojo: 0, total: 0 },
      { hora: '01:00 ', cantAmarillo: 0, cantVerde: 0, cantRojo: 0, total: 0 },
      { hora: '02:00 ', cantAmarillo: 0, cantVerde: 0, cantRojo: 0, total: 0 },
      { hora: '03:00 ', cantAmarillo: 0, cantVerde: 0, cantRojo: 0, total: 0 },
      { hora: '04:00 ', cantAmarillo: 0, cantVerde: 0, cantRojo: 0, total: 0 },
      { hora: '05:00 ', cantAmarillo: 0, cantVerde: 0, cantRojo: 0, total: 0 },
      { hora: '06:00 ', cantAmarillo: 0, cantVerde: 0, cantRojo: 0, total: 0 },
      { hora: '07:00 ', cantAmarillo: 0, cantVerde: 0, cantRojo: 0, total: 0 },
      { hora: '08:00 ', cantAmarillo: 0, cantVerde: 0, cantRojo: 0, total: 0 },
      { hora: '09:00 ', cantAmarillo: 0, cantVerde: 0, cantRojo: 0, total: 0 },
      { hora: '10:00 ', cantAmarillo: 0, cantVerde: 0, cantRojo: 0, total: 0 },
      { hora: '11:00 ', cantAmarillo: 0, cantVerde: 0, cantRojo: 0, total: 0 },
      { hora: '12:00 ', cantAmarillo: 0, cantVerde: 0, cantRojo: 0, total: 0 },
      { hora: '13:00 ', cantAmarillo: 0, cantVerde: 0, cantRojo: 0, total: 0 },
      { hora: '14:00 ', cantAmarillo: 0, cantVerde: 0, cantRojo: 0, total: 0 },
      { hora: '15:00 ', cantAmarillo: 0, cantVerde: 0, cantRojo: 0, total: 0 },
      { hora: '16:00 ', cantAmarillo: 0, cantVerde: 0, cantRojo: 0, total: 0 },
      { hora: '17:00 ', cantAmarillo: 0, cantVerde: 0, cantRojo: 0, total: 0 },
      { hora: '18:00 ', cantAmarillo: 0, cantVerde: 0, cantRojo: 0, total: 0 },
      { hora: '19:00 ', cantAmarillo: 0, cantVerde: 0, cantRojo: 0, total: 0 },
      { hora: '20:00 ', cantAmarillo: 0, cantVerde: 0, cantRojo: 0, total: 0 },
      { hora: '21:00 ', cantAmarillo: 0, cantVerde: 0, cantRojo: 0, total: 0 },
      { hora: '22:00 ', cantAmarillo: 0, cantVerde: 0, cantRojo: 0, total: 0 },
      { hora: '23:00 ', cantAmarillo: 0, cantVerde: 0, cantRojo: 0, total: 0 },
    ];

    listaTriage.forEach(triage => {
      this.cantTotalColores[triage.color.nombre]++;
      switch (triage.color.nombre) {
        case 'verde':
          this.arrayTriageDelDia[moment(triage.fechaIngreso +' '+triage.horaIngreso, 'DD/MM/YYYY HH:mm').hour()].cantVerde++;
          this.arrayTriageDelDia[moment(triage.fechaIngreso +' '+triage.horaIngreso, 'DD/MM/YYYY HH:mm').hour()].total++;
          this.totalTriages++;
          break;
        case 'amarillo':
          this.arrayTriageDelDia[moment(triage.fechaIngreso +' '+triage.horaIngreso, 'DD/MM/YYYY HH:mm').hour()].cantAmarillo++;
          this.arrayTriageDelDia[moment(triage.fechaIngreso +' '+triage.horaIngreso, 'DD/MM/YYYY HH:mm').hour()].total++;
          this.totalTriages++;
          break;
        case 'rojo':
          this.arrayTriageDelDia[moment(triage.fechaIngreso +' '+triage.horaIngreso, 'DD/MM/YYYY HH:mm').hour()].cantRojo++;
          this.arrayTriageDelDia[moment(triage.fechaIngreso +' '+triage.horaIngreso, 'DD/MM/YYYY HH:mm').hour()].total++;
          this.totalTriages++;
          this.existenRojo = true;
          break;
        default:
          break;
      }
    });
    console.log(this.cantTotalColores);
  }


  async contarBoletas(listaBoletas) {
    /// Función que guarda los profesionales y servicios del día en un arreglo para ser mostrados en una tabla 
    this.noExistenBoletas = false;
    this.profesionales = [];
    this.cantProfesionales = [];
    this.header = ['Profesional'];
    let posicionProfesional = {};
    this.totalProfesionales = {nombre: 'Totales', servicios: {}, total: 0};
    let mapa = new Map();
    let i: number = 0;
    let j: number;

    listaBoletas.forEach(boleta => {
      if (!this.profesionales.includes(boleta.profesionalSolicitante.nombreCompleto)) { /// Guardo todos los profesionales del día junto con su posición en un arreglo auxiliar (profesionales)
        this.profesionales.push(boleta.profesionalSolicitante.nombreCompleto);
        posicionProfesional[boleta.profesionalSolicitante.nombreCompleto] = i;
        i++;
      }
      if (!this.header.includes(boleta.servicio.nombre)) { // Guardo los servicios que serán mostrados en el header de la tabla
        this.header.push(boleta.servicio.nombre);
      }
    });

    let profesionalAux: any = {};
    this.header.sort((a,b) => { //ordeno los servicios a mostrar en la tabla
      if ((a != 'Profesional') && (b != 'Profesional')) {
        if(a < b) { return -1; }
        if(a > b) { return 1; }
      }
        
        
    });

    console.log(this.header);

    for (i = 0; i < this.profesionales.length; i++) { // Inicializo a cada profesional con su nombre y un mapa que contiene los servicios inicializados en 0
      profesionalAux = {nombre: this.profesionales[i], servicios: mapa, total: 0};
      mapa = new Map();
      for (j = 1; j < this.header.length; j++) {   
        mapa.set(this.header[j], 0);     
      }
      profesionalAux.servicios = mapa;
      this.cantProfesionales.push(profesionalAux); // añado el profesional al arreglo a mostrar
    }

    mapa = new Map();
    for (j = 1; j < this.header.length; j++) {   
        mapa.set(this.header[j], 0);     
    }
    this.totalProfesionales['servicios'] = mapa;

    listaBoletas.forEach(boleta => { //recuento de la cantidad de servicios emitidos para cada profesional
      let pro = posicionProfesional[boleta.profesionalSolicitante.nombreCompleto];
      let serv = boleta.servicio.nombre;
      this.cantProfesionales[pro]['total']++;
      this.cantProfesionales[pro].servicios.set(serv, this.cantProfesionales[pro].servicios.get(serv) + 1);
      this.totalProfesionales['total']++;
      this.totalProfesionales['servicios'].set(serv, this.totalProfesionales['servicios'].get(serv) + 1);
    });

    this.cantProfesionales.sort((a,b) => { //ordeno la información a mostrar en la tabla
      if(a.nombre < b.nombre) { return -1; }
      if(a.nombre > b.nombre) { return 1; }
    });

    this.cantProfesionales.push(this.totalProfesionales); //Añado los totales al arreglo a mostrar
    this.header.push('Total');
  }

  async getTriagesDelDia(fechaDesde, fechaHasta) {
    this.cargando = true;
    this.existenRojo = false;
    this.errorGetTriage = false;
    this.noExistenPacientes = false;
    
    this.totalTriages = 0;
    this.totalTriageProf = 0;

    let jsonTriage = {
      paciente: { },
      fechaDesde: fechaDesde,
      fechaHasta: fechaHasta
    }

    await this.urlResolverService.getHorusRestfullUrl().toPromise().then(async respGetUrl => {
      // console.log(respGetUrl);
      await this.tableroService.getTriageDelDia(respGetUrl, jsonTriage).toPromise().then(async resp => {
        // console.log(resp);
        if (resp.ok && resp.data.length > 0) {
          this.errorGetTriage = false;
          this.contarTriage(resp.data);
          await this.contarProfesionales(resp.data).then(() => this.cargando = false);
        } else {
          this.noExistenPacientes = true;
          this.cargando = false;
        }
      }, error => {
        //console.log(error);
        this.errorGetTriage = true;
        this.cargando = false;
      })
    }, error => {
      console.log(error);
      this.errorGetTriage = true;
      this.cargando = false;
    })
  }

  async getBoletasDelDia(fecha) {
    this.cargandoBoletas = true;
    this.errorGetBoletas = false;
    this.noExistenBoletas = false;

    let jsonBoleta = {
      fecha: fecha,
    }

    await this.tableroService.getBoletasDelDia(jsonBoleta).toPromise().then(async resp => {
        //console.log(resp);
        if (resp.ok && resp.elementos.length > 0) {
          await this.contarBoletas(resp.elementos).then(() => this.cargandoBoletas = false);
        } else {
          this.noExistenBoletas = true;
          this.cargandoBoletas = false;
        }
    }, error => {
      console.log(error);
      this.errorGetBoletas = true;
      this.cargandoBoletas = false;
    })
  }

  cambioFechaDesde(event: MatDatepickerInputEvent<Date>) {
    this.getTriagesDelDia(moment(event.value).startOf('day').format("DD-MM-YYYY HH:mm:ss"), moment(event.value).endOf('day').format("DD-MM-YYYY HH:mm:ss"));
    this.getBoletasDelDia(moment(event.value).format("DD/MM/YYYY"));
  }

  chequearServicio(servicios: Map<any, any>) {
    //Función que crea un arreglo usando el mapa recibido como parámetro y devuelve true en caso de que el mapa tenga elementos 
    this.serviciosProfesionalAux = [];
    let iterator = servicios.entries();
    this.serviciosProfesionalAux = Array.from(iterator);
    return servicios.size > 0;
  }

}
