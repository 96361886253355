<mat-accordion>
  <mat-expansion-panel [disabled]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <a routerLink="/inicio" (click)="inputSideNav.toggle()" class="link-menu">Inicio</a>
      </mat-panel-title>
      <!-- <mat-panel-description></mat-panel-description> -->
    </mat-expansion-panel-header>
  </mat-expansion-panel>

  <mat-expansion-panel [disabled]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <a
          routerLink="/recibos"
          (click)="inputSideNav.toggle()"
          class="link-menu"
          >Recibos de sueldo</a>
      </mat-panel-title>
      <!-- <mat-panel-description></mat-panel-description> -->
    </mat-expansion-panel-header>
  </mat-expansion-panel>

  <mat-expansion-panel [disabled]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <a
          routerLink="/documentos"
          (click)="inputSideNav.toggle()"
          class="link-menu"
          >Documentos</a
        >
      </mat-panel-title>
      <!-- <mat-panel-description></mat-panel-description> -->
    </mat-expansion-panel-header>
  </mat-expansion-panel>

  <mat-expansion-panel [disabled]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <a
          (click)="navigate('TI')"
          class="link-menu"
          >Tecnología de la Información</a>
      </mat-panel-title>
      <!-- <mat-panel-description></mat-panel-description> -->
    </mat-expansion-panel-header>
  </mat-expansion-panel>

  <mat-expansion-panel *ngFor="let group of this.grouped">
    <mat-expansion-panel-header>
      <mat-panel-title class="link-menu">
        {{ group[0].nombreMenu }}
      </mat-panel-title>
      <!-- <mat-panel-description></mat-panel-description> -->
    </mat-expansion-panel-header>
    <ul>
      <li *ngFor="let funcion of group">
        <a
          routerLink="{{ funcion.url }}"
          [state]="{ data: funcion.includeUrl }"
          (click)="inputSideNav.toggle()"
          class="link-menu"
          >{{ funcion.nombreSubMenu }}</a>
      </li>
    </ul>
  </mat-expansion-panel>
</mat-accordion>