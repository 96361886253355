import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTreeModule } from '@angular/material/tree';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DocstreeComponent } from './docstree/docstree.component';
import { UserService } from './services/user.service';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { HeaderxComponent } from './headerx/headerx.component';
import { FooterxComponent } from './footerx/footerx.component';
import { LoginxComponent } from './loginx/loginx.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MayusPipePipe } from '../app/pipes/mayus-pipe.pipe';
import { MatExpansionModule } from '@angular/material/expansion';
import { MenuAccordionComponent } from './menu-accordion/menu-accordion.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RecibosComponent } from './recibos/recibos.component';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { safeUrlPipe } from './pipes/safe-url.pipe';
import { TableroCovidComponent } from './tablero-covid/tablero-covid.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { AgregarNoticiasComponent } from './agregar-noticias/agregar-noticias.component';
import { MostrarNoticiasComponent } from './mostrar-noticias/mostrar-noticias.component';
import { SafeHTMLPipe } from './pipes/safe-html.pipe';
import { SplitStringPipe } from './pipes/split-string.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatSidenavModule } from '@angular/material/sidenav';
import { PublicarRecibosComponent } from './publicar-recibos/publicar-recibos.component';
import { ObservatorioDePacientesComponent } from './observatorio-de-pacientes/observatorio-de-pacientes.component';
import { EditarNoticiasComponent } from './editar-noticias/editar-noticias.component';
import { DialogConfirmacionComponent } from './dialog-confirmacion/dialog-confirmacion.component';
import { DialogMensajeComponent } from './dialog-mensaje/dialog-mensaje.component';
import { DialogCrearNovedadComponent } from './dialog-crear-novedad/dialog-crear-novedad.component';
import { ValidarUsuariosComponent } from './validar-usuarios/validar-usuarios.component';
import { MatRadioModule } from '@angular/material/radio';
import { OrganigramaSectorComponent } from './organigrama-sector/organigrama-sector.component';
import { TablerosComponent } from './tableros/tableros.component';
import { DialogValidacionMotivoComponent } from './dialog-validacion-motivo/dialog-validacion-motivo.component';
import { NotificacionCorreoPacientesComponent } from './notificacion-correo-pacientes/notificacion-correo-pacientes.component';
import { DialogEditarNotificacionesComponent } from './dialog-editar-notificaciones/dialog-editar-notificaciones.component';
import { ListarEventosSocialesComponent } from './listar-eventos-sociales/listar-eventos-sociales.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AgregarEventoComponent } from './agregar-evento/agregar-evento.component';
import { DialogCambiarCorreoComponent } from './dialog-cambiar-correo/dialog-cambiar-correo.component';
import { ListarNoticiasComponent } from './listar-noticias/listar-noticias.component';
import { PacientesGuardiaComponent } from './pacientes-guardia/pacientes-guardia.component';
import { PacientesGuardiaHoyComponent } from './pacientes-guardia-hoy/pacientes-guardia-hoy.component';
import { PacientesGuardiaSemanaComponent } from './pacientes-guardia-semana/pacientes-guardia-semana.component';
import { PacientesDetalleBoletaComponent } from './pacientes-detalle-boleta/pacientes-detalle-boleta.component';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { EditarNotificacionComponent } from './editar-notificacion/editar-notificacion.component';
import { DialogRecuperarContrasenaComponent } from './dialog-recuperar-contrasena/dialog-recuperar-contrasena.component';
import { DialogCambiarContrasenaComponent } from './dialog-cambiar-contrasena/dialog-cambiar-contrasena.component';
import { TreeModule } from 'primeng/tree';
import { PrimeIcons } from 'primeng/api';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DialogAgregarNoticiaTvComponent } from './dialog-agregar-noticia-tv/dialog-agregar-noticia-tv.component';
import { DialogModificarNoticiaTvComponent } from './dialog-modificar-noticia-tv/dialog-modificar-noticia-tv.component';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        DocstreeComponent,
        HeaderxComponent,
        FooterxComponent,
        LoginxComponent,
        MayusPipePipe,
        MenuAccordionComponent,
        RecibosComponent,
        safeUrlPipe,
        TableroCovidComponent,
        AgregarNoticiasComponent,
        MostrarNoticiasComponent,
        SafeHTMLPipe,
        SplitStringPipe,
        PublicarRecibosComponent,
        ObservatorioDePacientesComponent,
        EditarNoticiasComponent,
        DialogConfirmacionComponent,
        DialogMensajeComponent,
        DialogCrearNovedadComponent,
        ValidarUsuariosComponent,
        OrganigramaSectorComponent,
        TablerosComponent,
        DialogValidacionMotivoComponent,
        NotificacionCorreoPacientesComponent,
        DialogEditarNotificacionesComponent,
        ListarEventosSocialesComponent,
        AgregarEventoComponent,
        DialogCambiarCorreoComponent,
        ListarNoticiasComponent,
        PacientesGuardiaComponent,
        PacientesGuardiaHoyComponent,
        PacientesGuardiaSemanaComponent,
        PacientesDetalleBoletaComponent,
        EditarNotificacionComponent,
        DialogRecuperarContrasenaComponent,
        DialogCambiarContrasenaComponent,
        DialogAgregarNoticiaTvComponent,
        DialogModificarNoticiaTvComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatTreeModule,
        MatIconModule,
        MatButtonModule,
        MatToolbarModule,
        MatSidenavModule,
        MatMenuModule,
        MatDividerModule,
        MatListModule,
        MatBottomSheetModule,
        MatFormFieldModule,
        MatInputModule,
        MatExpansionModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatSelectModule,
        MatTableModule,
        MatSortModule,
        MatTooltipModule,
        MatCardModule,
        MatDatepickerModule,
        MatNativeDateModule,
        InfiniteScrollModule,
        MatSlideToggleModule,
        MatDialogModule,
        MatRadioModule,
        FormsModule,
        CKEditorModule,
        MatPaginatorModule,
        OrganizationChartModule,
        TreeModule,
        DragDropModule,
    ],
    providers: [UserService, { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }],
    bootstrap: [AppComponent]
})
export class AppModule {}
