import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';


@Component({
  selector: 'app-dialog-modificar-noticia-tv',
  templateUrl: './dialog-modificar-noticia-tv.component.html',
  styleUrls: ['./dialog-modificar-noticia-tv.component.css']
})
export class DialogModificarNoticiaTvComponent {
  numberValue: number;
  noticiasForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogModificarNoticiaTvComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  private buildForm() {
    this.noticiasForm = this.formBuilder.group({
      tiempo: ['', [Validators.required, Validators.min(1), Validators.max(300)]],
    });
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onSaveClick(): void {
    this.dialog
      .open(DialogConfirmacionComponent, {
        data: '¿Desea modificar la duración de noticia ' + this.data.titulo +'?',
      })
      .afterClosed()
      .subscribe((confirmado: boolean) => {
        if (confirmado) {
          this.dialogRef.close(this.numberValue);
        }
      });
  }

}
