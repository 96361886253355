import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { Response } from '../recibos/recibos.component';

const httpOptions = {
  headers: new HttpHeaders({})
};

@Injectable({
  providedIn: 'root'
})

export class ReciboService {

  baseUrl: string;

  constructor(
    private httpClient: HttpClient
  ) { 
    this.baseUrl = AppComponent.baseUrl;
  }

  obtenerRecibos(nroLegajo: bigint) {
    return this.httpClient.get<Response>(this.baseUrl+ 'reciboSueldoEmpleado/obtenerRecibos/' +nroLegajo);
  }

  copiarRecibos() {
    return this.httpClient.get<Response>(this.baseUrl+ 'reciboSueldoEmpleado/pasarRecibos');
  }

  verPdf() {
    httpOptions.headers.append('ResponseType', 'Blob');
    return this.httpClient.post<any>(this.baseUrl+ 'restricted/fs/getFilePdf', 
      'C:/Users/francoma/Desktop/2020-01-MES-050120-00040.pdf',);
  }
}
