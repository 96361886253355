import { Component, OnInit } from '@angular/core';
// import { checkView } from '@angular/core/src/render3/instructions';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-validacion-motivo',
  templateUrl: './dialog-validacion-motivo.component.html',
  styleUrls: ['./dialog-validacion-motivo.component.css']
})
export class DialogValidacionMotivoComponent implements OnInit { 
  motivo = new UntypedFormControl('', [Validators.required, Validators.pattern(/^[a-zA-ZÀ-ÿ\u00f1\u00d10-9\.\, ]+$/)])
  submitted:boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DialogValidacionMotivoComponent>
  ) {
   
   }

  ngOnInit() {
  }

  confirmar(){
    this.submitted = true;
    if (this.motivo.valid) {
      this.dialogRef.close(this.motivo.value);
    }
      
  }

  onNoClick(){
    this.dialogRef.close(false);
  }

}
