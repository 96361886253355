<footer class="footer">
  <div class="container-fluid">
    <p class="parrafo m-0 py-1 align-middle">
      <img
        src="../../assets/images/login/logo_footer.png"
        class="align-middle"
      />
      Copyright &copy; 2019 Hospital Espa&ntilde;ol de La Plata | Asociacion
      Espa&ntilde;ola de Socorros Mutuos y Beneficencia | Intranet v 1.8
    </p>
  </div>
</footer>
