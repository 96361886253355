import { Component, OnInit } from '@angular/core';
import { TableroService } from '../services/tablero.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-tablero-covid',
  templateUrl: './tablero-covid.component.html',
  styleUrls: ['./tablero-covid.component.css'],
})
export class TableroCovidComponent implements OnInit {
  fecha: String;
  atencionesGuardia;
  internadosPorCovid;
  internadosPorEstada;
  pacSegDom;
  hayAtenciones: boolean = false;
  date;
  stockFarm;
  stockCovid;
  diasCompr;
  porcentaje;
  duracionEstimadaCovid;
  duracion: number = 14;
  casosCovid;

  constructor(private tableroCovidService: TableroService) {}

  ngOnInit() {
    this.porcentaje = new UntypedFormControl('');
    this.duracionEstimadaCovid = new UntypedFormControl(14);

    this.date = new UntypedFormControl(new Date());
    this.fecha = new Date().toLocaleDateString();

    this.getAtencionesEnGuardia(this.fecha);

    this.getInternadosCovidPosEstada();
    this.getCantInternadosCovid();
    this.getCantSeguimientoDomiciliario();
    this.getFarmaceuticoCovid();
    this.getCasosCovid();
  }

  seleccionarFecha(event: MatDatepickerInputEvent<Date>) {
    this.fecha = event.value.toLocaleDateString();
    this.getAtencionesEnGuardia(this.fecha);
  }

  getAtencionesEnGuardia(fecha: String) {
    this.tableroCovidService.atencionesEnGuardia(fecha).subscribe(
      (res) => {
        this.atencionesGuardia = res.paginador.elementos;
        if (this.atencionesGuardia.length > 0) {
          this.hayAtenciones = true;
        } else {
          this.hayAtenciones = false;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getInternadosCovidPosEstada() {
    this.tableroCovidService.internadosCovidPosEstada().subscribe(
      (res) => {
        this.internadosPorEstada = res.paginador.elementos;
        this.calcularDiascomprometidos();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getCantInternadosCovid() {
    this.tableroCovidService.cantInternadosCovid().subscribe(
      (res) => {
        this.internadosPorCovid = res.paginador.elementos;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getCantSeguimientoDomiciliario() {
    this.tableroCovidService.cantSeguimientoDomiciliario().subscribe(
      (res) => {
        this.pacSegDom = res.paginador.elementos;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getFarmaceuticoCovid() {
    this.tableroCovidService.getFarmaceuticoCovid().subscribe(
      (res) => {
        this.stockFarm = res.paginador.elementos;
        this.stockFarm.forEach((element) => {
          element.stockCovid =
            (element.stockDia * element.porcentajeCovid) / 100;
          element.diasPac = element.stockCovid / element.cantidadDiariaOMS;
          element.stockComp = element.cantidadDiariaOMS * this.diasCompr;
          element.stockExcedente = element.stockCovid - element.stockComp;
          element.diasExcedentes =
            element.stockExcedente / element.cantidadDiariaOMS;
          element.tratamientosExcedentes =
            element.diasExcedentes / this.duracion;
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  calcularDiascomprometidos() {
    let totalEstadias = 0;
    let totalInternados = 0;

    this.internadosPorEstada.forEach(function (e) {
      totalEstadias += e.cantidad * e.estada;
      totalInternados += e.cantidad;
    });
    this.diasCompr = totalInternados * this.duracion - totalEstadias;
  }

  actualizarDuracion() {
    this.duracion = this.duracionEstimadaCovid.value;
    this.calcularDiascomprometidos();
    this.getFarmaceuticoCovid();
  }

  actualizarPorcentaje() {
    this.stockFarm.forEach((element) => {
      element.porcentajeCovid = this.porcentaje.value;

      element.stockCovid = (element.stockDia * element.porcentajeCovid) / 100;
      element.diasPac = element.stockCovid / element.cantidadDiariaOMS;
      element.stockComp = element.cantidadDiariaOMS * this.diasCompr;
      element.stockExcedente = element.stockCovid - element.stockComp;
      element.diasExcedentes =
        element.stockExcedente / element.cantidadDiariaOMS;
      element.tratamientosExcedentes = element.diasExcedentes / this.duracion;
    });
  }

  getCasosCovid() {
    this.tableroCovidService.getCasosCovid().subscribe(
      (res) => {
        const data = res.paginador.elementos;
        this.casosCovid = data.filter((x) => x.descripcion !== 'FALLECIDOS');
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
