import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Route, Router } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ComunicacionService } from '../services/comunicacion.service';
@Component({
  selector: 'app-listar-noticias',
  templateUrl: './listar-noticias.component.html',
  styleUrls: ['./listar-noticias.component.css']
})
export class ListarNoticiasComponent implements OnInit {
  noticias : any[];
  
  fechaActual:Date;
  dataSourceItems: any;
  displayedColumns: string[] = [ 'Titulo', 'Descripcion', 'NombreImagen', 'Sitios', 'FechaPublicacion','FechaFin','Acciones'];
  hayNoticias = false;
  noticiasForm:FormGroup = new FormGroup({
    titulo: new FormControl(),
    desc: new FormControl(),
    img: new FormControl(),
    sitios: new FormControl(),
    fpubli: new FormControl(),
    ffin: new FormControl(),
    acciones: new FormControl()
  });
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private comunicacionService: ComunicacionService,
    private router: Router
  ) {
    this.fechaActual = new Date();
  }

  ngOnInit(): void {
    this.buildForm();
    this.listarNoticias();
    
  }

  private buildForm(){
    this.noticiasForm = this.formBuilder.group({
      titulo:[''],
      desc:[''],
      img:[''],
      sitios:[''],
      fpubli:[''],
      ffin:[''],
      acciones:['']

    })

  }
  async listarNoticias(){
    
    await this.comunicacionService.getNoticias().toPromise().then(
      (data)=>{
        if(data.ok){
          this.noticias = data.elementos;
          
          if(data.elementos.length > 0 ){
            this.dataSourceItems = new MatTableDataSource(this.noticias);
            this.dataSourceItems.paginator = this.paginator;
            this.hayNoticias = true;
          }
        
        }
      }
    )
  }
  buscarNoticia(event){
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceItems.filter = filterValue.trim().toLowerCase();
  }
  compararFechaPublicacion(element){
    element.st_fechaPublicacion.split('/');
    const [day,month,year] =  element.st_fechaPublicacion.split('/');
    let fp = new Date(year,month - 1,day);//fecha publicacion 
    let fa = new Date(this.fechaActual);//fecha actual
    
    if(fp > fa){
      return true;
    }
    return false;  
    
    
  }

  
  modificarNoticia(id: number) {
      
      this.router.navigate(['/editar-noticia', id]);
    }
  
}
