  <h2 mat-dialog-title>Modificar duración de la noticia {{ data.titulo }}</h2>
    <mat-dialog-content>
      <div class="container">
        <p for="">La duración actual es de {{ data.tiempo }} <b>segundos</b></p>
        <form [formGroup]="noticiasForm">
          <mat-form-field class="field">
            <input matInput type="number" [(ngModel)]="numberValue" name="numberInput" placeholder="Ingrese la duración en segundos" formControlName="tiempo" max="500" min="1"S/>
            <mat-hint class="duracion">La duración debe ser mayor a 0 y menor o igual a 300 segundos (5 minutos)</mat-hint>
          </mat-form-field>
        </form>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-raised-button color="warn" (click)="onCancelClick()">Cancelar</button>
      <button mat-raised-button color="primary" (click)="onSaveClick()" [disabled]="noticiasForm.invalid">Modificar noticia</button>
    </mat-dialog-actions> 