import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import { HttpClient } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { UrlResolverService } from '../services/urlResolver.service';

@Injectable()

export class UserService {
  baseUrl:string;
  public usserLogged: User;
  private horusUrl;

  constructor(
    private http: HttpClient,
    private urlResolver: UrlResolverService
  ) {
    this.baseUrl = AppComponent.baseUrl;

    this.urlResolver.getHorusRestfullUrl().subscribe((res) => {
      this.horusUrl = res;
    }, (error) => {
      console.log(error);
    });
  }

  getLoggedIn() {
    return JSON.parse(sessionStorage.getItem('currentUser'));
  }

  getConvenioConf(usuario: String) {
    return this.http.get<any>(this.baseUrl + 'usuariosLDAP/getConvenioConf/'+ usuario);
  }

  guardarUsuario(jsonUsuario) {
    return this.http.post<any>(this.baseUrl + 'usuariosLDAP/guardarUsuario', jsonUsuario);
  }

  aceptarConvenioConf(usuario: string) {
    return this.http.get<any>(this.baseUrl + 'usuariosLDAP/aceptarConvenioConf/' + usuario);
  }

  registrarConvenioDeConf(usuario) {

    let form = {
      usuario: usuario.usuario,
      nombreCompleto: usuario.nombreCompleto,
    };

    return this.http.post<any>(this.horusUrl + 'hcdigital/registro-convenio-de-confidencialidad', form);
  }

  getPdfConvenioFirmado(usuario:String) {
    
    const httpOptions = {
      'responseType'  : 'arraybuffer' as 'json'
    };

    return this.http.get<any>(this.horusUrl + 'hcdigital/get-pdf-convenio-firmado/' + usuario, httpOptions);
  }

  supportMessage(formValue) {
    let body = {
      nombreEmisor: formValue.nombreApellido,
      emailEmisor: formValue.email.toLowerCase(),
      asuntoFrontEnd: formValue.asunto,
      cuerpo: formValue.mensaje,
      telefonoContacto: formValue.telefonoContacto

    };
    
    return this.http.post<any>(this.baseUrl + 'login/enviarMensajeASoporteTI', body);
  }

  recuperarClave(nombreUsuario) {

    let form = {
      usuario: nombreUsuario,
      idAplicacion: 2,
    };

    return this.http.post<any>(this.horusUrl + 'login/enviarMensajeRecuperarContrasenia', form);
  }

  passwordChange(nombreUsuario, formValue) {

    let form = {
      usuario: nombreUsuario,
      oldPassword: formValue.actual,
      newPassword: formValue.confirmPassword,
      nombreApp: 'Intranet'
    };

    return this.http.post<any>(this.horusUrl + 'usuariosLDAP/cambiarContrasena', form);
  }
}

