import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppComponent } from '../app.component';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
  }),
};

@Injectable({ providedIn: 'root' })
export class UrlResolverService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = AppComponent.baseUrl;
  }

  getHorusRestfullUrl(): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'urlResolver/parametroHorus', httpOptions);
  }

  getValorParametro(nombre: String){
    return this.http.get<any>(this.baseUrl + '/core/parametros/buscar/' + nombre);
  }
}
