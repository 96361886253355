import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ComunicacionService } from '../services/comunicacion.service';
import {MatInputModule} from '@angular/material/input';
//import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';

@Component({
  selector: 'app-editar-notificacion',
  templateUrl: './editar-notificacion.component.html',
  styleUrls: ['./editar-notificacion.component.css'],
})
export class EditarNotificacionComponent implements OnInit {
  notificacionesForm;
  public editor = DecoupledEditor;
  notificacion: any;
  loading: boolean;
  confirmo: boolean;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private comunicacionService: ComunicacionService,
    private formBuilder:UntypedFormBuilder,
  ) {

  }

  ngOnInit(): void {
    this.loading = false;
    this.notificacion = this.comunicacionService.getCurrentNotificacion();
    this.buildForm();
  }

  buildForm() {
    this.notificacionesForm = this.formBuilder.group({
      id:[this.notificacion.notificacionId],
      estado:[this.notificacion.estado],
      asunto:[this.notificacion.asunto],
      contAdicional:[this.notificacion.contAdicional],


    })
  }

  public onReady(editor) {
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );
  }

  

  async onSubmitForm() {
    //Función que luego de chequear que no esté vacío alguno de los campos, modifica el asunto y/o el contenido adicional de la notificación
    this.loading = true;


    if (this.notificacionesForm.value.asunto == "" || this.notificacionesForm.value.contAdicional == "") {
        this.dialog.open(DialogMensajeComponent,{
          width: '500px',
          data:{
            ok: false,
            mensaje: "Los campos asunto/contenido adicional no pueden estar vacíos"
          }
    }).afterClosed().subscribe(()=> {
      this.loading = false;
    })
    } else { 
      this.dialog.open(DialogConfirmacionComponent, { //Pop up para previsualizar el contenido y confirmar
        data: '<h3>¿Desea confirmar los cambios?</h3>' + '\n\n' +
              '<strong>Asunto:</strong> ' +  '\n\n' +
              this.notificacionesForm.value.asunto + '\n' +
              '<strong>Contenido adicional:</strong> ' + '\n\n' +
              this.notificacionesForm.value.contAdicional,
      })
      .afterClosed()
      .subscribe((confirmado: boolean) => {
        if (confirmado) { 
          if (this.notificacion.asunto == this.notificacionesForm.value.asunto)
            this.notificacionesForm.value.asunto = null;
          if (this.notificacion.contAdicional == this.notificacionesForm.value.contAdicional) //Se guardan los valores en null en caso de ser iguales a los originales
            this.notificacionesForm.value.contAdicional = null;
          this.comunicacionService.setNotificacionesCorreo(this.notificacion.notificacionId, this.notificacion.estado, this.notificacionesForm.value.contAdicional, this.notificacionesForm.value.asunto).subscribe(
            (data)=>{
              this.loading = false;
              if(data.ok) {
                if (this.notificacionesForm.value.asunto != null)
                  this.notificacion.asunto = this.notificacionesForm.value.asunto;
                if (this.notificacionesForm.value.contAdicional != null)  //Se actualiza la notificación actual para mostrar correctamente la información modificada
                  this.notificacion.contAdicional = this.notificacionesForm.value.contAdicional
                this.comunicacionService.setCurrentNotificacion(this.notificacion);
                this.dialog.open(DialogMensajeComponent,{
                  width: '500px',
                  data:{
                    ok: true,
                    mensaje: "Notificación modificada exitosamente"
                  }
                }).afterClosed().subscribe(()=>{
                  this.router.navigate(['/notificacionPaciente']);
                })
            } else {
                this.dialog.open(DialogMensajeComponent,{
                  width: '500px',
                  data:{
                    ok: false,
                    mensaje: "Ocurrió un error al modificar la notificación"
                  }
                }).afterClosed().subscribe(() => {
                  window.location.reload();
                })
            }
          })
      }
    })  
    this.loading = false;
  }}

  volver() {
    if (this.notificacionesForm.dirty) {
      this.dialog.open(DialogConfirmacionComponent, { //Pop up para previsualizar el contenido y confirmar
        data: '<h3>¿Está seguro que desea salir de esta pantalla? Los cambios que no se hayan guardado se perderán</h3>'
      })
      .afterClosed()
      .subscribe((confirmado: boolean) => {
        if (confirmado) {
          this.router.navigate(['/notificacionPaciente']);
        }
      })
    } else {
      this.router.navigate(['/notificacionPaciente']);
    }
    
  }

  //usar el sp sp_horus_set_notificaciones_contenido para modificar los datos

}
