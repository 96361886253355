<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <div class="row" style="margin-top: 1%; ">
                <div class="col">
                    <h2>Pacientes Hisopados</h2>
                    <div class="table-responsive" style="margin-top: 1%;">
                        <table class="table table-striped table-bordered" style="width: 100%;">
                            <thead>
                                <tr>
                                    <th>Descripci&oacute;n</th>
                                    <th>Total</th>
                                    <th>Unidad coronaria</th>
                                    <th>Terapia intensiva</th>
                                    <th>Neonatologia</th>
                                    <th>Internaci&oacute;n general</th>
                                    <th>Seguimiento<br>domiciliario</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of casosCovid">
                                    <th>{{item.descripcion}}</th>
                                    <th>{{item.unidadCoronaria +item.terapiaIntensiva + item.neonatologia + item.internados + item.seguimientoDomiciliario}}</th>
                                    <th>{{item.unidadCoronaria}}</th>
                                    <th>{{item.terapiaIntensiva}}</th>
                                    <th>{{item.neonatologia}}</th>
                                    <th>{{item.internados}}</th>
                                    <th>{{item.seguimientoDomiciliario}}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <h2>Pacientes Egresados</h2>
                    <div class="table-responsive" style="margin-top: 1%;">
                        <table class="table table-striped table-bordered" style="width: 100%;">
                            <thead>
                                <tr>
                                    <th>Descripci&oacute;n</th>
                                    <th>Total</th>
                                    <th>Alta</th>
                                    <th>Fallecidos</th>
                                    <th>Traslados</th>
                                    <th>Sin internación</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of casosCovid">
                                    <th>{{item.descripcion}}</th>
                                    <th>{{item.alta + item.obito + item.traslado + item.sinInternacion}}</th>
                                    <th>{{item.alta}}</th>
                                    <th>{{item.obito}}</th>
                                    <th>{{item.traslado}}</th>
                                    <th>{{item.sinInternacion}}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h2>Pacientes atendidos por guardia el:</h2>
                    <mat-form-field style="font-size: 16px;">
                        <!-- <p style="font-size: 16px;">Pacientes atendidos por guardia el:</p> -->
                        <!-- <mat-label>Choose a date</mat-label> -->
                        <input matInput [matDatepicker]="picker" (dateInput)="seleccionarFecha($event)" [formControl]="date">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="row justify-content-md-center">
                <div class="col">
                    <h2>Pacientes Internados</h2>
                    <table class="table table-striped table-bordered" style="margin-top: 3%;">
                        <thead>
                            <tr>
                                <th>Descripci&oacute;n</th>
                                <th>Cantidad</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of internadosPorCovid">
                                <th>{{item.descripcion}}</th>
                                <th>{{item.cantidad}}</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col">
                    <h2>Pacientes con Seguimiento Domiciliario</h2>
                    <table class="table table-striped table-bordered" style="margin-top: 3%;">
                        <thead>
                            <tr>
                                <th>Descripci&oacute;n</th>
                                <th>Cantidad</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of pacSegDom">
                                <th>{{item.descripcion}}</th>
                                <th>{{item.cantidad}}</th>
                            </tr>
                        </tbody>
                    </table>
                    <br>
                    <div class="col">
                        <table class="table table-striped table-bordered" [hidden]="!hayAtenciones">
                            <thead>
                                <tr>
                                    <th scope="col">Servicio</th>
                                    <th scope="col">Cantidad</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of atencionesGuardia">
                                    <th>{{item.descripcion}}</th>
                                    <th>{{item.cantidad}}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- <h2>Pacientes Internados por Estada (confirmados o sospechosos)</h2>
              <table class="table table-striped table-bordered" style="margin-top: 3%;">
                <thead>
                  <tr>
                    <th>Estada</th>
                    <th>Cantidad</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of internadosPorEstada">
                    <th>{{item.estada}}</th>
                    <th>{{item.cantidad}}</th>
                  </tr>
                </tbody>
              </table>
              <br> -->
                </div>

                <!-- <div class="row" style="margin-top: 1%; border: 0px red solid; width: 90%;">
              
            <div class="col">

              <h2>Calculadora COVID</h2>

              <div id="input">
                <mat-form-field id="campo-num">
                  <mat-label>Porcentaje</mat-label>
                  <input matInput type="number" [formControl]="porcentaje">
                </mat-form-field>
                <mat-icon (click)="actualizarPorcentaje()">cached</mat-icon>
              </div>

              <div id="input">
                <mat-form-field id="campo-num">
                  <mat-label>Duraci&oacute;n tratamiento de COVID</mat-label>
                  <input matInput type="number" [formControl]="duracionEstimadaCovid">
                </mat-form-field>
                <mat-icon (click)="actualizarDuracion()">cached</mat-icon>
              </div>
              
              <div class="table-responsive">
                <table class="table table-striped table-bordered" style="width: 100%;">
                  <thead>
                    <tr>
                      <th>Art&iacute;culo</th>
                      <th>Consumo del d&iacute;a (OMS)</th>
                      <th>Stock dep&oacute;sito</th>
                      <th>% Stock covid</th>
                      <th>Stock covid</th>
                      <th>D&iacute;as paciente</th>
                      <th>Stock comprometido</th>
                      <th>Stock excedente</th>
                      <th>D&iacute;as excedentes</th>
                      <th>Tratamientos excedentes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of stockFarm">
                      <th>{{item.descripcion}}</th>
                      <th>{{item.cantidadDiariaOMS}}</th>
                      <th>{{item.stockDia}}</th>
                      <th>{{item.porcentajeCovid}}</th>
                      <th>{{item.stockCovid}}</th>
                      <th>{{item.diasPac}}</th>
                      <th>{{item.stockComp}}</th>
                      <th>{{item.stockExcedente}}</th>
                      <th>{{item.diasExcedentes | number: '1.2-2'}}</th>
                      <th>{{item.tratamientosExcedentes | number: '1.2-2'}}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
              
            </div>
          </div> -->
            </div>
        </div>
    </div>