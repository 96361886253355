<div class="container-fluid" style="text-align: center;">
  <br>
  <button mat-raised-button color="primary" (click)="copiarRecibos()" data-toggle="modal"
    data-target="#exampleModal">Publicar Recibos</button>


</div>



<!-- Modal -->
<div class="modal fade" id="exampleModal" data-backdrop="false" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel" style="font-size: medium;">Publicando los recibos..</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="text-align: center;">
        <mat-spinner diameter="50" style="margin: auto;" [hidden]="!publicandoRecibos"></mat-spinner><br>
        <mat-icon class="doneIcon" [hidden]="!respuesta">done_outline</mat-icon>
        <mat-icon class="errorIcon" [hidden]="respuesta">error_outline</mat-icon>
        <p>{{mensaje}}</p>
      </div>
    </div>
  </div>
</div>