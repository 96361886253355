import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogCambiarContrasenaComponent } from '../dialog-cambiar-contrasena/dialog-cambiar-contrasena.component';

declare var $: any;

@Component({
  selector: 'app-headerx',
  templateUrl: './headerx.component.html',
  styleUrls: ['./headerx.component.css'],
})
export class HeaderxComponent implements OnInit {
  today: number = Date.now();

  public usserLogged;
  urlPdfConvenio;

  constructor(
    private userService: UserService,
    private authService: AuthenticationService,
    private router: Router,
    public dialog: MatDialog,
  ) {
    this.authService.currentUser.subscribe(async (resp) => {
      // console.log(resp)
      this.usserLogged = resp;
    }, (error) => {
      console.log(error);
    });
  }

  ngOnInit() {
    $('#modalVerConvenioConf').appendTo('body');
  }

  logout() {
    this.authService.logout();
  }

  goToRecibos() {
    this.router.navigate(['/recibos']);
  }

  getUsserLogged() {
    this.usserLogged = this.userService.getLoggedIn();
  }

  goHome() {
    if (this.authService.currentUserValue) {
      this.router.navigate(['/inicio']);
    }
  }

  verConvenioConf() {
    this.getUrlConvenioConf();
    document.getElementById('openModalVerConvenioConf').click();
  }

  getUrlConvenioConf() {
    this.urlPdfConvenio = this.userService
      .getPdfConvenioFirmado(this.usserLogged.usuario)
      .subscribe((response) => {
        let file = new Blob([response], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        document
          .getElementById('pdfDinamico')
          .setAttribute('src', fileURL + '#toolbar=0');
      });
  }

  cambiarContrasena(objUsuarioLog) {
    // objUsuarioLog.olvidoPw = false;
    this.dialog.open(DialogCambiarContrasenaComponent, {
      width: '500px',
      data: objUsuarioLog,
      hasBackdrop: true,
    });
  }
}
