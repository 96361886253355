import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Folder } from '../docstree/docstree.component';
import { AppComponent } from '../app.component';

const httpOptions = {
    headers: new HttpHeaders({})
};

@Injectable({
  providedIn: 'root'
})

export class DocumentsService {

    baseUrl: string;

    constructor(
        private httpClient: HttpClient,
    ) {
        this.baseUrl = AppComponent.baseUrl;
    }

    getDocuments(param: string) {        
        httpOptions.headers.append('Accept', 'application/json;charset=utf8');
        httpOptions.headers.append('Content-Type', 'application/json;charset=utf8');
        //return this.httpClient.get<Folder>('http://172.20.32.246:8080/horus_restfull/api/restricted/fs/getAllFilesInAllDirectory/francoma');
        return this.httpClient.get<Folder>(this.baseUrl + 'restricted/fs/getAllFilesInAllDirectory/' + param);
    }

    getOrganigrama(param: string) {        
        httpOptions.headers.append('Accept', 'application/json;charset=utf8');
        httpOptions.headers.append('Content-Type', 'application/json;charset=utf8');
        //return this.httpClient.get<Folder>('http://172.20.32.246:8080/horus_restfull/api/restricted/fs/getAllFilesInAllDirectory/francoma');
        return this.httpClient.get<Folder>(this.baseUrl + 'restricted/fs/getAllFilesInAllDirectoryForSector/' + param);
    }

}
