<div class="container-fluid">
    <mat-progress-bar mode="indeterminate" [hidden]="!(cargando || cargandoBoletas)"></mat-progress-bar>

    <div class="row" [hidden]="cargando && cargandoBoletas" style="margin-top: 1%;">
        <div class="col">
            <h2>Guardia - Pacientes atendidos en un día</h2>
            <mat-form-field style="margin-top: 1%;">
                <input matInput [matDatepicker]="dp" placeholder="Fecha" [max]="maxDate" [formControl]="dateDesde" (dateChange)="cambioFechaDesde($event)">
                <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                <mat-datepicker #dp></mat-datepicker>
                <!-- <mat-error *ngIf="dateDesde.invalid">La fecha no es valida.</mat-error> -->
            </mat-form-field>
        
            <div class="row">
                <div class="col" [hidden]="!errorGetTriage">
                    <h2>Ocurri&oacute; un error al obtener los datos</h2>
                </div>
                <div class="col" [hidden]="!noExistenPacientes">
                    <h2>No existen pacientes ingresados para la fecha seleccionada</h2>
                </div>
            </div>

            <div class="row">
                <div class="col" [hidden]="!errorGetBoletas">
                    <h2>Ocurri&oacute; un error al obtener las boletas</h2>
                </div>
                <div class="col" [hidden]="!noExistenBoletas">
                    <h2>No existen boletas emitidas para la fecha seleccionada</h2>
                </div>
            </div>
                    
                    
                <div class="row" [hidden]="cargandoBoletas || cargando">
                    <div  style="max-height: fit-content; margin-bottom: 5%;" [hidden]="errorGetTriage || noExistenPacientes" style="margin-right: 3%;">
                        <div class="table-responsive" id="tabla_triages" >
                            <table class="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th class="bkgDfl"> Horas </th>
                                        <th class="bkgRoj" *ngIf="existenRojo"> Rojo </th>
                                        <th class="bkgAro"> Amarillo </th>
                                        <th class="bkgVrd"> Verde </th>
                                        <th class="bkgDfl"> Total </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of arrayTriageDelDia">
                                        <th> {{ item.hora }} </th>
                                        <th *ngIf="existenRojo"> {{ item.cantRojo }} </th>
                                        <th> {{ item.cantAmarillo }} </th>
                                        <th> {{ item.cantVerde }} </th>
                                        <th> {{ item.total }} </th>
                                    </tr>
                                    <tr>
                                        <th> Total </th>
                                        <th *ngIf="existenRojo"> {{ cantTotalColores.rojo }} </th>
                                        <th> {{ cantTotalColores.amarillo }} </th>
                                        <th> {{ cantTotalColores.verde }} </th>
                                        <th> {{ totalTriages }} </th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                <!-- </div>
    
                <div class="row" [hidden]="errorGetTriage"> -->
                <div class="row">
                    <div class="col">
                        <div class="table-responsive" style="margin-top: 0%; margin-left: 1.5%; text-align: center;" [hidden]="errorGetTriage || noExistenPacientes">
                            <table class="table table-striped table-bordered" id="tabla_profesionales_color">
                                <thead>
                                    <tr>
                                        <th class="bkgDfl"> Profesional </th>
                                        <th class="bkgDfl"> Servicio Derivado </th>
                                        <th class="bkgRoj" *ngIf="existenRojo"> Rojo </th>
                                        <th class="bkgAro"> Amarillo </th>
                                        <th class="bkgVrd"> Verde </th>
                                        <th class="bkgDfl"> Total </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of arrayPrfesionales">
                                        <th> {{ item.medDerivado.nombreCompleto ? item.medDerivado.nombreCompleto : 'Sin Asignar' }} </th>
                                        <th> {{ item.servicio.nombre }} </th>
                                        <th *ngIf="existenRojo"> {{ item.cantRojo }} </th>
                                        <th> {{ item.cantAmarillo }} </th>
                                        <th> {{ item.cantVerde }} </th>
                                        <th> {{ item.total }} </th>
                                    </tr>
                                    <tr>
                                        <th [attr.colspan]="existenRojo ? 5 : 4"> Total </th>
                                        <th> {{ totalTriageProf }} </th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="table-responsive" style="margin-top: 0%; margin-bottom: 5%; text-align: center; margin-left: 1.5%;  overflow-x: hidden;" [hidden]="noExistenBoletas" >
                            <table class="table table-striped table-bordered" id="tabla_profesionales">
                                <thead id="head-tabla">
                                    <tr>
                                        <th style="background: rgb(219, 219, 219); position: sticky; top: -0.1vh;" *ngFor="let item of header"> {{ item }} </th>
                                    </tr> 
                                </thead>
                                <tbody  id="body-tabla">
                                    <ng-container *ngFor="let item of cantProfesionales">
                                        <tr  *ngIf="chequearServicio(item.servicios)">
                                            <th> {{ item.nombre }} </th>
                                            <th *ngFor="let attribute of serviciosProfesionalAux"> {{ attribute[1] }} </th>
                                            <th> {{ item.total }} </th>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>           
        </div>
</div>