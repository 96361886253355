import { Component, OnInit, HostListener } from '@angular/core';
import { ComunicacionService } from '../services/comunicacion.service';
import { AppComponent } from '../app.component';
import { EmbedUrlsService } from '../services/embedUrls.service';
import { Router } from '@angular/router';

export interface Noticia {
  idComunicacion: number;
  nombreImagen: string;
  titulo: string;
  fechaPublicacion: string;
  texto: string;
  estado?: string;
  fechaFinPublicacion: string;
}
@Component({
  selector: 'app-mostrar-noticias',
  templateUrl: './mostrar-noticias.component.html',
  styleUrls: ['./mostrar-noticias.component.css'],
})

export class MostrarNoticiasComponent implements OnInit {
  baseUrl: string;
  files: string;

  listaNoticiasTotal: Noticia[];
  private listaNoticiasActual: Noticia[];
  private desde: number;
  fin: boolean;
  loading: boolean;
  showGoUpButton: boolean;
  private showScrollHeight: number;
  private hideScrollHeight: number;
  private usuarioLog: string;
  private IDAPLICACION;
  editor: boolean;
  puedeEditar: boolean;
  constructor(
    public comunicacionService: ComunicacionService,
    private EmbedUrlSer: EmbedUrlsService,
    private router: Router
  ) {
    this.IDAPLICACION = 33;
    this.showGoUpButton = false;
    this.fin = false;
    this.showScrollHeight = 200;
    this.hideScrollHeight = 100;
    this.baseUrl = AppComponent.baseUrl;
    this.loading = false;
    this.comunicacionService.getParametroComunicacion().subscribe(
      (res) => {
        const respuesta = res.paginador.elementos.find((elemento) => {
          return elemento.nombre === 'comunicacion';
        });
        this.files =
          this.baseUrl + 'restricted/fs/getImage?pathString=' + respuesta.valor;
        // console.log(this.files, "prueba");
      },
      (error: any) => {
        console.log('error al buscar parametro!');
        console.log(error);
      }
    );
    // console.log(this.files, "probando");
    this.EmbedUrlSer.embedUrls.subscribe((funciones) => {
      if (
        funciones.find((funcion) => {
          return funcion.url === 'agregar-noticias';
        })
      ) {
        this.editor = true;
      } else {
        this.editor = false;
      }
    });
    this.usuarioLog = JSON.parse(sessionStorage.getItem('currentUser')).usuario;
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (
      (window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop) > this.showScrollHeight
    ) {
      this.showGoUpButton = true;
    } else if (
      this.showGoUpButton &&
      (window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop) < this.hideScrollHeight
    ) {
      this.showGoUpButton = false;
    }
  }

  ngOnInit() {
    this.listaNoticiasActual = new Array<Noticia>();
    this.listaNoticiasTotal = new Array<Noticia>();
    this.desde = 0;
    this.getNoticiasProgramadas();
    this.getNoticiasFraccionadas();
    this.puedeEditar = false;
  }
  modificarNoticia(id: number) {
    this.router.navigate(['/editar-noticia', id]);
  }
  mostrarMas() {
    this.listaNoticiasActual.forEach((elemento) => {
      this.listaNoticiasTotal.push(elemento);
    });
    this.desde = this.desde + this.listaNoticiasActual.length;
  }

  onScroll() {
    if (!this.fin) {
      this.loading = true;
      this.getNoticiasFraccionadas();
      this.loading = false;
    }
  }
  scrollTop() {
    document.body.scrollTop = 0; // Safari
    document.documentElement.scrollTop = 0; // Other
  }

  async getNoticiasFraccionadas() {
    await this.comunicacionService.getNoticiasFraccionadas(
      this.IDAPLICACION,
      this.desde,
      5,
      this.usuarioLog
    ).toPromise().then((res) => {
      // console.log(res)
      if (res.length !== 0) {
        this.listaNoticiasActual = res;
        this.mostrarMas();
      } else {
        this.fin = true;
      }
    }, (error) => {
      console.log(error);
    });
  }

  async getNoticiasProgramadas() {
    await this.comunicacionService.getNoticiasProgramadas(
      this.IDAPLICACION,
    ).toPromise().then((res) => {
      // console.log(res)
      res.forEach(element => {
        element.estado = 'PRO';
      });
      this.listaNoticiasTotal = res;
    }, (error) => {
      console.log(error);
    });
  }
}
