<div class="container-fluid">

  <div class="row">


    <div class="col-sm-12">
      <br>
      <mat-progress-bar mode="indeterminate" [hidden]="!loading" *ngIf="!mensajeError"></mat-progress-bar>
      
      <h2 [hidden]="loading">DOCUMENTOS</h2>
      <!--
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="dataSource" [hidden]="loading">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
          <button mat-icon-button disabled></button>
          <mat-icon class="type-icon" [attr.aria-label]="node.type + 'icon'">
            {{ node.type === 'File' ? 'description' : 'folder' }}
          </mat-icon>
          <a target="_blank" href="{{files}}{{node.url}}">{{node.name | mayusPipe}}</a>
        </mat-tree-node>

        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
          <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          <mat-icon class="type-icon" [attr.aria-label]="node.type + 'icon'">
            {{ node.type === 'file' ? 'description' : 'folder' }}
          </mat-icon>
          {{node.name | mayusPipe}}
        </mat-tree-node>
      </mat-tree>
      -->
      <mat-card class="mensajeCard" [hidden]="!mensajeError">
        <mat-card-content>
          <mat-icon class="errorIcon">error_outline</mat-icon>
          <p>No se pudo obtener los Documentos.</p>
        </mat-card-content>
      </mat-card>

      <div *ngIf="!loading" style="padding-bottom: 5%;" >
        <p-tree [value]="archivos" selectionMode="single" [(selection)]="selectedFile" 
        (onNodeSelect)="nodeSelect($event)" [filter]="true" filterPlaceholder="Buscar" emptyMessage="No se han encontrado resultados"></p-tree>
      </div>
    </div>
  </div>

</div>