import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginxComponent } from './loginx/loginx.component';
import { DocstreeComponent } from './docstree/docstree.component';
import { AuthGuard } from './guards/auth.guard';
import { AgregarNoticiasComponent } from './agregar-noticias/agregar-noticias.component';
import { MostrarNoticiasComponent } from './mostrar-noticias/mostrar-noticias.component';
import { RecibosComponent } from './recibos/recibos.component';
import { TableroCovidComponent } from './tablero-covid/tablero-covid.component';
import { PublicarRecibosComponent } from './publicar-recibos/publicar-recibos.component';
import { ObservatorioDePacientesComponent } from './observatorio-de-pacientes/observatorio-de-pacientes.component';
import { EditarNoticiasComponent } from './editar-noticias/editar-noticias.component';
import { ValidarUsuariosComponent } from './validar-usuarios/validar-usuarios.component';
import { OrganigramaSectorComponent } from './organigrama-sector/organigrama-sector.component';
import { TablerosComponent } from './tableros/tableros.component';
import { NotificacionCorreoPacientesComponent } from './notificacion-correo-pacientes/notificacion-correo-pacientes.component';
import { ListarEventosSocialesComponent } from './listar-eventos-sociales/listar-eventos-sociales.component';
import { AgregarEventoComponent } from './agregar-evento/agregar-evento.component';
import { ListarNoticiasComponent } from './listar-noticias/listar-noticias.component';
import { PacientesGuardiaComponent } from './pacientes-guardia/pacientes-guardia.component';
import { PacientesGuardiaHoyComponent } from './pacientes-guardia-hoy/pacientes-guardia-hoy.component';
import { PacientesGuardiaSemanaComponent } from './pacientes-guardia-semana/pacientes-guardia-semana.component';
import { PacientesDetalleBoletaComponent } from './pacientes-detalle-boleta/pacientes-detalle-boleta.component';
import { EditarNotificacionComponent } from './editar-notificacion/editar-notificacion.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginxComponent },
  { path: 'inicio', component: HomeComponent, canActivate: [AuthGuard] },
  {
    path: 'documentos',
    component: DocstreeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'agregar-noticias',
    component: AgregarNoticiasComponent,
    canActivate: [AuthGuard],
  },
  { path: 'recibos', component: RecibosComponent, canActivate: [AuthGuard] },
  {
    path: 'tablero-covid',
    component: TableroCovidComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tableros',
    component: TablerosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'mostrar-noticias',
    component: MostrarNoticiasComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'observatorio-de-pacientes',
    component: ObservatorioDePacientesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'publicar-recibos',
    component: PublicarRecibosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'editar-noticia/:numero',
    component: EditarNoticiasComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'validar-usuarios',
    component: ValidarUsuariosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'organigrama-sector/:sector',
    component: OrganigramaSectorComponent,
    canActivate: [AuthGuard],
  },
  { 
    path: 'notificacionPaciente',  
    component: NotificacionCorreoPacientesComponent, 
    canActivate: [AuthGuard] 
  },
  { 
    path: 'eventosSociales',  
    component: ListarEventosSocialesComponent, 
    canActivate: [AuthGuard] 
  },
  {
    path: 'agregar-evento',
    component: AgregarEventoComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'listar-noticias',
    component: ListarNoticiasComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'pacientes-atendidos',
    component: PacientesGuardiaComponent,
    canActivate: [AuthGuard],
  },{
    path: 'pacientes-atendidos-hoy',
    component: PacientesGuardiaHoyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'pacientes-atendidos-7-dias',
    component: PacientesGuardiaSemanaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'pacientes-detalle-boleta',
    component: PacientesDetalleBoletaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'editar-notificacion',
    component: EditarNotificacionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'notificacion-correo-pacientes',
    component: NotificacionCorreoPacientesComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    // onSameUrlNavigation: 'ignore',
    onSameUrlNavigation: 'reload'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
