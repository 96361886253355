import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user.model';
import { Router } from '@angular/router';

const httpOptions = {
  headers: new HttpHeaders({}),
};

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  baseUrl: string;
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>( JSON.parse(sessionStorage.getItem('currentUser')) );
    this.currentUser = this.currentUserSubject.asObservable();
    this.baseUrl = this.getBaseUrl();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  getBaseUrl(): string {
    if (window.location.hostname == 'localhost') {
      return (
        window.location.protocol +
        '//' +
        window.location.hostname +
        ':8090/thot_restfull/api/'
      );
    } else {
      return (
        window.location.protocol +
        '//' +
        window.location.hostname +
        '/thot_restfull/api/'
      );
    }
  }

  login(username: string, pass: string) {
    httpOptions.headers.append('Accept', 'application/json');
    httpOptions.headers.append('Content-Type', 'application/json;charset=utf8');
    return this.http
      .post<any>(
        this.baseUrl + `login/doLogin`,
        JSON.stringify({
          usuario: username,
          password: pass,
        }),
        httpOptions
      )
      .pipe(
        map((resp) => {
          // console.log(resp)
          if (resp.user && resp.user.token) {
            sessionStorage.setItem('currentUser', JSON.stringify(resp.user));
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(resp.user));
            this.currentUserSubject.next(resp.user);
          }

          return resp;
        })
      );
  }

  guardarDatosUsuarioRecibos(
    nombrePdf: String,
    nombreUsuario: String,
    nroLegajo: bigint
  ) {
    return this.http.get<any>(
      this.baseUrl +
        'login/guardarDatosUsuarioRecibos/' +
        nombrePdf +
        '/' +
        nombreUsuario +
        '/' +
        nroLegajo
    );
  }

  guardarDatosPublicarRecibos(nombreUsuario: String) {
    return this.http.get<any>(
      this.baseUrl + 'login/guardarDatosPublicarRecibos/' + nombreUsuario
    );
  }

  logout() {
    // localStorage.removeItem('currentUser');
    this.router.navigate(['/login']);
    sessionStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
}
