import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-observatorio-de-pacientes',
  templateUrl: './observatorio-de-pacientes.component.html',
  styleUrls: ['./observatorio-de-pacientes.component.css']
})
export class ObservatorioDePacientesComponent implements OnInit {
  embedUrl: any;
  navigationSubscription;

  constructor(private router: Router,
    private route: ActivatedRoute) {
    this.navigationSubscription = this.router.events.subscribe(event => {
      if (history.state.data) {
        this.embedUrl = history.state.data;
      }
      else {
        this.router.navigate(['/inicio'])
      }
    });

  }

  ngOnInit() {
  }

  ngOnDestroy() {

    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

}
