import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../services/authentication.service';
import { UserService } from '../services/user.service';
import { first } from 'rxjs/operators';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogRecuperarContrasenaComponent } from '../dialog-recuperar-contrasena/dialog-recuperar-contrasena.component';
import { DialogCambiarCorreoComponent } from '../dialog-cambiar-correo/dialog-cambiar-correo.component';

@Component({
  selector: 'app-loginx',
  templateUrl: './loginx.component.html',
  styleUrls: ['./loginx.component.css'],
})

export class LoginxComponent implements OnInit {

  loginForm: UntypedFormGroup;
  supportMessageForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  msje: string;
  nombreUsuario: string;
  supportMessageSubmitted: boolean;
  supportMessageLoading: boolean;
  showPass = false;
  mayusActivado;
  supportMessageSent: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private dialog: MatDialog, 
  ) {
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/inicio']);
    }
  }

  ngOnInit() {
    this.msje = null;

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
    /*'[a-zA-Z ]*'*/
    /*/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/*/
    this.supportMessageForm = this.formBuilder.group({
      nombreApellido: [ '', [ Validators.required, Validators.pattern('[a-zA-Z ]*')]],
      email: [ '', [ Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
      asunto: ['', Validators.required],
      mensaje: ['', Validators.required],
      telefonoContacto:['', [Validators.required,Validators.pattern('[0-9]+')]]
      //agregar campo telefonoContacto con sus validadores
    });

    window.addEventListener('keydown', this.mayusActivo);
  }

  get f() {
    return this.loginForm.controls;
  }
  get sp() {
    return this.supportMessageForm.controls;
  }

  mayusActivo(event: KeyboardEvent) {
    const mayusElement = document.getElementById('mayus');
    if (mayusElement && event instanceof KeyboardEvent) {
      if (event.getModifierState('CapsLock')) {
        mayusElement.style.display = 'block';
      } else {
        mayusElement.style.display = 'none';
      }
    }
  }
  
  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService
      .login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe((res) => {
        // console.log(res)
        if (res.ok) {
          if (!res.user.mailActualizado) {
            this.openDialogCambiarCorreo(res);
          }
          this.navigate();
        } else {
          this.msje = res.mensaje;
          this.loading = false;
        }
      }, (error) => {
        console.error(error);
        this.loading = false;
      });
  }

  openDialogCambiarCorreo(usuario) {
    const dialogRef = this.dialog.open(DialogCambiarCorreoComponent, {
      data: usuario,
    });
  }

  navigate() {
    this.router.navigate(['/inicio']);
  }
  
  supportMessage() {
    this.supportMessageSubmitted = true;
    if (this.supportMessageForm.invalid) {
      return;
    }
    this.supportMessageLoading = true;
    this.userService
      .supportMessage(this.supportMessageForm.value)
      .pipe(first())
      .subscribe(
        (data) => {
          this.supportMessageSent = true;
          this.dialog.open(DialogMensajeComponent, {
            width: '500px',
              data: {ok:true, mensaje:'El mail se envió con éxito.'}
          })
          .afterClosed()
          .subscribe((x) => {            
            window.location.reload();
          });      
        },
        (error) => {}
      );
  }

  convertirEnMinusculas(){
    this.f.username.setValue(this.f.username.value.toLowerCase());
  }
  showHidePassword() {
    this.showPass = !this.showPass;
  }

  recuperarClave() {
    this.dialog.open(DialogRecuperarContrasenaComponent, {
      width: '500px',
      hasBackdrop: true
    })
  }
}
