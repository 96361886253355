import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { Aplicacion } from '../agregar-noticias/agregar-noticias.component';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class ComunicacionService {
  baseUrl: string;

  constructor(private httpClient: HttpClient) {
    this.baseUrl = AppComponent.baseUrl;
  }

  editarNoticia(
    idComunicacion: number,
    usuarioEstado: string,
    titulo: string,
    texto: string,
    nombreImagen: string,
    imagenValor: string,
    fechaPublicacion: string,
    estado: string,
    sitios_activos: Aplicacion[],
    sitios: Aplicacion[],
    fechaFinPublicacion: string,
    orden?: number,
    tiempo?: number
  ) {
    let datos: string 
    if (orden || tiempo){
      datos = JSON.stringify({
        idComunicacion,
        usuarioEstado,
        titulo,
        texto,
        nombreImagen,
        imagenValor,
        fechaPublicacion,
        estado,
        sitios_activos,
        sitios,
        fechaFinPublicacion,
        orden,
        tiempo
      });
    } else {
       datos = JSON.stringify({
        idComunicacion,
        usuarioEstado,
        titulo,
        texto,
        nombreImagen,
        imagenValor,
        fechaPublicacion,
        estado,
        sitios_activos,
        sitios,
        fechaFinPublicacion,
      });
    }    
    return this.httpClient.post<any>(
      this.baseUrl + 'comunicacion2/modificarComunicacion',
      datos,
      httpOptions
    );
  }

  setNoticias(
    usuarioEstado: string,
    titulo: string,
    texto: string,
    nombreImagen: string,
    imagenValor: string,
    aplicaciones: number[],
    fechaPublicacion: string,
    orden?: number,
    tiempo?: number
  ) {
    let datos: string;
    if (orden && tiempo){ // si es de tv turnos
      datos = JSON.stringify({
        usuarioEstado,
        titulo,
        texto,
        nombreImagen,
        imagenValor,
        aplicaciones,
        fechaPublicacion,
        orden,
        tiempo
      });
    } else {
      datos = JSON.stringify({
        usuarioEstado,
        titulo,
        texto,
        nombreImagen,
        imagenValor,
        aplicaciones,
        fechaPublicacion,
      });
    }
    return this.httpClient.post<any>(
      this.baseUrl + 'comunicacion2/agregarComunicacion',
      datos,
      httpOptions
    );
  }
  getNoticiaEditar(idComunicacion: number, usuarioEstado: string) {
    const datos: string = JSON.stringify({
      idComunicacion,
      usuarioEstado,
    });
    return this.httpClient.post<any>(
      this.baseUrl + 'comunicacion2/getNoticiaEditar',
      datos,
      httpOptions
    );
  }

  getNoticiasFraccionadas(
    aplicacion: number,
    desde: number,
    cuantas: number,
    usuarioEstado: string
  ) {
    const datos: string = JSON.stringify({
      aplicacion,
      desde,
      cuantas,
      usuarioEstado,
    });
    return this.httpClient.post<any>(
      this.baseUrl + 'comunicacion2/listarComunicacionesFraccionadas',
      datos,
      httpOptions
    );
  }

  getNoticiasProgramadas(
    aplicacion: number
  ) {
    return this.httpClient.post<any>(
      this.baseUrl + 'comunicacion2/listarComunicacionesProgramadas',
      aplicacion,
      httpOptions
    );
  }

  getParametroComunicacion() {
    return this.httpClient.get<any>(
      this.baseUrl + '/core/parametros/buscar/' + 'comunicacion',
      httpOptions
    );
  }
  getAplicaciones(usuarioAccion: string) {
    return this.httpClient.post<any>(
      this.baseUrl + 'comunicacion2/listarAplicaciones',
      usuarioAccion,
      httpOptions
    );
  }
  
  getAplicacionesActivas(idComunicacion: number, usuario: string){
    const datos: string = JSON.stringify({
      idComunicacion,
      usuario,
    });
    return this.httpClient.post<any>(
      this.baseUrl + 'comunicacion2/listarAplicacionesActivas',
      datos,
      httpOptions
    );
  }


  setNovedad(usuarioEstado: string, texto: string, fechaPublicacion: string, fechaFinPublicacion: string) {
    const datos: string = JSON.stringify({
      usuarioEstado,
      texto,
      fechaPublicacion,
      fechaFinPublicacion,
    });
    return this.httpClient.post<any>(
      this.baseUrl + 'comunicacion2/agregarNovedad',
      datos,
      httpOptions
    );
  }

  getNovedadesFraccionadas(
    desde: number,
    cuantas: number,
    usuarioEstado: string
  ) {
    const datos: string = JSON.stringify({
      desde,
      cuantas,
      usuarioEstado,
    });
    return this.httpClient.post<any>(
      this.baseUrl + 'comunicacion2/listarNovedadesFraccionadas',
      datos,
      httpOptions
    );
  }

  getNotificacionesCorreo(estado) {
    
    const body = {
      estado:estado,
    }
  
    return this.httpClient.post<any>( 
      this.baseUrl + 'comunicacion2/listarNotificaciones',body,httpOptions);
    }

    setNotificacionesCorreo(
      notiId,
      estado,
      contenido,
      asunto
    ){
      const body = {
        notificacionId: notiId,
        estado: estado,
        contAdicional: contenido,
        asunto: asunto,
      }
     
      return this.httpClient.post<any>( 
        this.baseUrl + 'comunicacion2/editarNotificaciones',body,httpOptions);

    }


  /*
  subirImagen(datos: any): Observable<any> {
    httpOptions.headers.append('Accept', 'application/json;charset=utf8');
    httpOptions.headers.append('Content-Type', 'application/json;charset=utf8');
    return this.httpClient.post<any>(this.baseUrl + 'comunicacion2/guardarImagen', datos);
  }

  obtenerImagen(nombre: string): Observable<any> {
    httpOptions.headers.append('Accept', 'application/json;charset=utf8');
    httpOptions.headers.append('Content-Type', 'application/json;charset=utf8');
    return this.httpClient.get<any[]>(this.baseUrl + 'comunicacion2/obtenerImagen?nombre=' + nombre);

    // return this.httpClient.get<any>(this.baseUrl + 'fs/getFile?pathString='+ '//datos2008/Repositorio_Digital/comunicacion/imagenes_noticias/desarrollo/'+ nombre);
  }

  */

 
  getEventosSociales(filtroBusqueda)
  {
    const datos: string = JSON.stringify({
      filtro: filtroBusqueda,
      estado: 'ACT',
    });
    return this.httpClient.post<any>
    (
      this.baseUrl + 'comunicacion2/getEventosSociales', 
      datos, 
      httpOptions
    );
  }

  getSectores() 
  {
    return this.httpClient.post<any>
    (
      this.baseUrl + 'comunicacion2/getSectores',
      httpOptions
    );
  }

  getEventosSocialesTipo() 
  {
    return this.httpClient.post<any>
    (
      this.baseUrl + 'comunicacion2/getEventosSocialesTipo',
      httpOptions
    );
  }

  setEventoSocial ( formulario )
  {
    const datos: string = JSON.stringify({
      id: formulario.value.id,
      descripcion: formulario.value.descripcion,
      tipo: { 
        id: formulario.value.tipo.id,
        descripcion: formulario.value.tipo.descripcion,
      },
      sector: {
        sector: formulario.value.sector.sector,
        descripcion: formulario.value.sector.descripcion,
      },
      fecha: formulario.value.fecha,
      tipo_documento: formulario.value.tipo_documento,
      numero_documento: formulario.value.numero_documento,
      numero_legajo: formulario.value.numero_legajo,
      matricula: formulario.value.matricula,
      login_estado: formulario.value.login_estado,
      estado: formulario.value.estado,
    });
    return this.httpClient.post<any>(
      this.baseUrl + 'comunicacion2/agregarEvento',
      datos,
      httpOptions
    );
  }

  modificarEventoSocial( formulario ) {
    const datos: string = JSON.stringify({
      id: formulario.value.id,
      descripcion: formulario.value.descripcion,
      tipo: { 
        id: formulario.value.tipo.id,
        descripcion: formulario.value.tipo.descripcion,
      },
      sector: formulario.value.sector,
      fecha: formulario.value.fecha,
      tipo_documento: formulario.value.tipo_documento,
      numero_documento: formulario.value.numero_documento,
      numero_legajo: formulario.value.numero_legajo,
      matricula: formulario.value.matricula,
      login_estado: formulario.value.login_estado,
      estado: formulario.value.estado,
    });
    return this.httpClient.post<any>(
      this.baseUrl + 'comunicacion2/modificarEvento',
      datos,
      httpOptions
    );  
  }

  eliminarEvento(evento, usuarioLogueado)
  {
    const datos: string = JSON.stringify({
      id: evento.id,
      login_estado: usuarioLogueado.usuario,
      estado: 'INA',
    });
    return this.httpClient.post<any>(
      this.baseUrl + 'comunicacion2/eliminarEvento',
      datos,
      httpOptions
    );
  }

  ordenarNoticiasTV(noticias){
    const datos: string = JSON.stringify({
      comunicaciones: noticias
    })
    this.httpClient.post<any>(
      this.baseUrl + 'comunicacion2/ordenarNoticiasTV',
      datos,
      httpOptions
    ).subscribe(
      response => {
        return response;
      },
      error => {
        console.error(error);
      }
    );
    
  }

  setCurrentEvento(evento)
  {
    sessionStorage.setItem( 'evento' , JSON.stringify(evento));
  }

  getCurrentEvento()
  {
    const r = JSON.parse(sessionStorage.getItem('evento'));
    return r;
  }

  unsetEvento() { 
    sessionStorage.removeItem('evento');
  }

  getNoticias(){
    let tipo = 1;
    return this.httpClient.get<any>( 
      this.baseUrl + 'comunicacion2/listarNoticias/'+ tipo);
  }
 
  setCurrentNotificacion(notificacion) {
    sessionStorage.setItem('currentNotificacion', JSON.stringify(notificacion));  
  }

  getCurrentNotificacion () {
    return JSON.parse(sessionStorage.getItem('currentNotificacion'));
  }

}
    

