<div class="container-fluid" style="min-height: 100%; padding: 0px;" [class.example-is-mobile]="mobileQuery.matches">
  <mat-toolbar
    class="example-toolbar"
    style="
      background: #0a3d6e !important;
      color: #fff !important;
      opacity: 0.9 !important;
      height: 64px;
    "
    *ngIf="usserLogged"
  >
    <button mat-icon-button (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <app-headerx (click)="sidenav.close()" class="w-100"></app-headerx>
  </mat-toolbar>
  <mat-sidenav-container class="example-sidenav-container">
    <mat-sidenav
      #sidenav
      [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches"
      fixedTopGap="64"
    >
      <app-menu-accordion [inputSideNav]="sidenav"></app-menu-accordion>
    </mat-sidenav>

    <mat-sidenav-content (click)="sidenav.close()">
      <router-outlet></router-outlet>
      <app-footerx></app-footerx>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>