<div class="container-fluid">
    <mat-progress-bar mode="indeterminate" [hidden]="!cargando"></mat-progress-bar>

    <div class="row" [hidden]="cargando" style="margin-top: 1%;">
        <div class="col">
            <h2>Guardia - Pacientes atendidos hoy</h2>
            <mat-form-field style="margin-top: 1%;">
                <input matInput [matDatepicker]="dp" placeholder="Fecha" [max]="maxDate" [formControl]="dateDesde" (dateChange)="cambioFechaDesde($event)">
                <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                <mat-datepicker #dp></mat-datepicker>
            </mat-form-field>
            
            <mat-card-content>
                <div  class="row" style="margin-top: 1%;">
                  <div [hidden]="cargando" class="col" style="overflow: auto;">
                    
                    <div style="text-align: center; margin-top: 5%;  " *ngIf="noExistenPacientes">
                      <mat-card class="sin-pacientes-card">
                        <h5 style="color:rgb(13, 52, 80)" >No existen pacientes ingresados para la fecha seleccionada</h5>
                      </mat-card>
                    </div>
                    <span style="margin-left:1%;"></span>
                    <mat-form-field *ngIf="!noExistenPacientes">
                      <mat-label>Filtrar</mat-label>
                      <input matInput (keyup)="applyFilter($event)" autocomplete="off" />
                    </mat-form-field>
                    <span style="margin-left:1%;"></span>
                    <div class="contenedor-tabla">
                    <table
                      mat-table
                      [dataSource]="dataSourceItems"
                      class="mat-elevation-z8"
                      *ngIf="!noExistenPacientes"
                    >
                      <!-- Columna Paciente -->
                      <div style="text-align: center; margin-right: 1px">
                        <ng-container matColumnDef="paciente">
                          <th mat-header-cell *matHeaderCellDef>Paciente</th>
                          <td mat-cell *matCellDef="let item"> {{ item.paciente }} </td>
                        </ng-container>
                      </div>
          
                      <!-- Columna Color -->
                      <div style="text-align: center;">
                        <ng-container matColumnDef="color">
                          <th mat-header-cell *matHeaderCellDef>Color</th>
                          <td mat-cell *matCellDef="let item" style="vertical-align: auto;">
                            <mat-icon [ngStyle]="{'color': getColor(item.color)}">label</mat-icon> 
                          </td>
                        </ng-container>
                      </div>
          
                      <!-- Columna Hora de Ingreso -->
                      <div style="text-align: center; margin-right: 1px">
                        <ng-container matColumnDef="horaIngreso">
                          <th mat-header-cell *matHeaderCellDef>Hora de Ingreso</th>
                          <td mat-cell *matCellDef="let item"> {{ item.horaIngreso}} </td>
                        </ng-container>
                      </div>
          
                      <!-- Columna Enfermero -->
                      <div style="text-align: center; margin-right: 1px">
                        <ng-container matColumnDef="enfermero">
                          <th mat-header-cell *matHeaderCellDef>Enfermero</th>
                          <td mat-cell *matCellDef="let item"> {{ item.enfermero}} </td>
                        </ng-container>
                      </div>

                      <!-- Columna Profesional -->
                      <div style="text-align: center; margin-right: 1px">
                        <ng-container matColumnDef="profesional">
                          <th mat-header-cell *matHeaderCellDef>Profesional</th>
                          <td mat-cell *matCellDef="let item"> {{ item.medico}} </td>
                        </ng-container>
                      </div>
          
                      <!-- Columna Acciones -->
                      <div style="text-align: center; margin-right: 1px;">
                        <ng-container matColumnDef="action">
                          <th mat-header-cell *matHeaderCellDef>Acciones</th>
                          <td mat-cell *matCellDef="let item" class="action-link">

                                <!-- Botón ver detalles de boleta -->
                                <button mat-icon-button class="icon-button"  color="primary" matTooltip="Ver detalles de boleta" class="icon" (click)="verDetalleBoleta(item.triage)">
                                  <mat-icon>remove_red_eye</mat-icon>
                                </button>            

                          </td>
                        </ng-container>
                      </div>
                      <tr mat-header-row *matHeaderRowDef="displayedColumnasItems; sticky: true"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumnasItems"></tr>
                    </table>
                  </div>
          
                  </div>
                </div>
              </mat-card-content>
        </div>
    </div>
</div>