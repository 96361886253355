<div
  class="container-fluid"
  infiniteScroll
  [infiniteScrollDistance]="2"
  (scrolled)="onScroll()"
>
  <div *ngFor="let noticia of listaNoticiasTotal">
    <div class="row m-4" [hidden]="noticia.estado == 'PRO' && !editor">
      <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <img
          class="img-responsive img-fluid center-block"
          alt="Responsive image"
          *ngIf="noticia.nombreImagen != 'null'"
          [src]="files + noticia.nombreImagen"
          [alt]="noticia.nombreImagen"
        />
        <!-- 
                                src='data:image/jpg;base64,{{btoa(this.comunicacionService.obtenerImagen(nombreImagen))}}'

              <img *ngIf="noticia.imagenValor!=null" src='data:image/{{noticia.nombreImagen|splitString}};base64,{{noticia.imagenValor}}' style="max-height: 300px;width:auto;height:auto;">
              <h1> Este es el texto que obtengo {{noticia.nombreImagen|splitString}}</h1> -->
      </div>
      <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
        <button
          mat-mini-fab
          color="primary"
          *ngIf="editor"
          class="btn btn-primary mx-auto float-right my-fab"
          (click)="modificarNoticia(noticia.idComunicacion)"
        >
          <mat-icon>create</mat-icon>
        </button>
        <h3 id="titulo" style="text-align: left; overflow-wrap: break-word">
          {{ noticia.titulo | uppercase }}
        </h3>

        <h6 id="fecha"> <span *ngIf="noticia.estado == 'PRO'" style="color: red;">Programada para el</span>
          {{ noticia.fechaPublicacion | date: 'dd/MM/yyyy' }}
        </h6>
        <div>
          <div
            id="cuerpo"
            [innerHTML]="noticia.texto | safeHTML"
            style="text-align: justify; overflow-wrap: break-word"
          ></div>
        </div>
      </div>
    </div>
  </div>
  <div class="alert alert-primary" role="alert" *ngIf="fin">
    Se terminaron las noticias
  </div>
  <button
    mat-fab
    color="primary"
    [ngClass]="'no-hidden'"
    *ngIf="showGoUpButton"
    class="btn btn-primary mx-auto"
    (click)="scrollTop()"
  >
    <mat-icon>arrow_upward</mat-icon>
  </button>
  <mat-progress-bar
    diameter="22"
    [hidden]="!loading"
    style="display: inline-block; margin-left: 5%; margin-top: 1%"
  ></mat-progress-bar>
  <!-- <button [ngClass]="'no-hidden'" *ngIf="showGoUpButton" class="btn btn-primary mx-auto" 
          (click)="scrollTop()"><span class="material-icons">arrow_upward</span></button> -->
</div>
