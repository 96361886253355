import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SplitStringPipe } from '../pipes/split-string.pipe';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';

@Component({
  selector: 'app-dialog-agregar-noticia-tv',
  templateUrl: './dialog-agregar-noticia-tv.component.html',
  styleUrls: ['./dialog-agregar-noticia-tv.component.css'],
  providers: [SplitStringPipe],
})
export class DialogAgregarNoticiaTvComponent {
  selectedFile: File;
  numberValue: number;
  dateValue: string;
  titleValue: string;
  noticiasForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogAgregarNoticiaTvComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private splitString: SplitStringPipe,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    this.buildForm();
  }
  
  private buildForm() {
    this.noticiasForm = this.formBuilder.group({
      titulo: ['', Validators.required],
      imagen: ['', Validators.required],
      fecha_publicacion: ['', Validators.required],
      tiempo: ['', [Validators.required, Validators.min(1), Validators.max(300)]],
    });
  }

  onFileChange(event: any): void {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.noticiasForm.get('imagen').setValue({
          nombre: file.name,
          tipo: '.' + this.splitString.transform(file.name),
          valor: (reader.result as string).split(',')[1],
        });
      };
    }
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onSaveClick(): void {
    this.dialog
      .open(DialogConfirmacionComponent, {
        data: `¿Desea agregar esta noticia a TV turnos?`,
      })
      .afterClosed()
      .subscribe((confirmado: boolean) => {
        if (confirmado) {
          const result = {
            fechaPublicacion: this.dateValue,
            nombre: this.noticiasForm.value.imagen.nombre,
            valor: this.noticiasForm.value.imagen.valor,
            titulo: this.titleValue,
            tiempo: this.noticiasForm.value.tiempo,
          };
          this.dialogRef.close(result);
        }
      });
  }
}
