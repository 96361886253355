import { Component, OnInit } from '@angular/core';
import { TableroService } from '../services/tablero.service';
import { UrlResolverService } from '../services/urlResolver.service';
import * as moment from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-pacientes-guardia-hoy',
  templateUrl: './pacientes-guardia-hoy.component.html',
  styleUrls: ['./pacientes-guardia-hoy.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class PacientesGuardiaHoyComponent implements OnInit {
  cargando: boolean = true;
  errorGetTriage: boolean = false;
  noExistenPacientes: boolean = false;
  dateDesde = new FormControl(moment());
  maxDate = moment();
  arrayTriageDelDia = [];
  dataSourceItems: any;
  displayedColumnasItems: string[] = ['paciente', 'color', 'horaIngreso','enfermero', 'profesional', 'action'];

  constructor(
    private tableroService: TableroService,
    private urlResolverService: UrlResolverService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getTriagesDelDia(moment().startOf('day').format("DD-MM-YYYY HH:mm:ss"), moment().endOf('day').format("DD-MM-YYYY HH:mm:ss"));
  }

  async contarPacientes(listaTriage) { 
    if (listaTriage.length > 0){
      let lista = []
      listaTriage.forEach(element => {
        let paciente = {
          paciente: element.paciente.apellido,
          horaIngreso: element.horaIngreso,
          color: element.color.nombre,
          enfermero: element.enfermero,
          medico: element.medDerivado.nombreCompleto,
          triage: element,
        }
        lista.push(paciente)
      });
      this.ordenarPorHora(lista)
      this.dataSourceItems = new MatTableDataSource(lista);
    }
    this.cargando = false;
  }

  async getTriagesDelDia(fechaDesde, fechaHasta) {
    this.cargando = true;
    this.errorGetTriage = false;
    this.noExistenPacientes = false;
    
    let jsonTriage = {
      paciente: { },
      fechaDesde: fechaDesde,
      fechaHasta: fechaHasta
    }

    await this.urlResolverService.getHorusRestfullUrl().toPromise().then(async respGetUrl => {
      await this.tableroService.getTriageDelDia(respGetUrl, jsonTriage).toPromise().then(async resp => {
        if (resp.ok && resp.data.length > 0) {
          this.errorGetTriage = false;
          await this.contarPacientes(resp.data);
        } else {
          this.noExistenPacientes = true;
          this.cargando = false;
        }
      }, error => {
        console.log(error);
        this.errorGetTriage = true;
        this.cargando = false;
      })
    }, error => {
      console.log(error);
      this.errorGetTriage = true;
      this.cargando = false;
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceItems.filter = filterValue.trim().toLowerCase();
  }


  cambioFechaDesde(event: MatDatepickerInputEvent<Date>) {
    this.getTriagesDelDia(moment(event.value).startOf('day').format("DD-MM-YYYY HH:mm:ss"), moment(event.value).endOf('day').format("DD-MM-YYYY HH:mm:ss"));
  }

  ordenarPorHora(array){
    array = array.sort((a, b) => a.horaIngreso.localeCompare(b.horaIngreso))
  }

  verDetalleBoleta(triage){
    this.tableroService.setCurrentTriage(triage);
    this.router.navigate(['/pacientes-detalle-boleta']);
  }

  getColor(color) {
    if (color.toLowerCase() == 'amarillo'){
      return 'gold'
    }
    if (color.toLowerCase() == 'rojo'){
      return 'red'
    }
    if (color.toLowerCase() == 'verde'){
      return 'chartreuse'
    }
  }
}