<h1 mat-dialog-title class="text-center mb-1" *ngIf=!edit>Agregar novedad</h1>
<h1 mat-dialog-title class="text-center mb-1" *ngIf=edit>Modificar novedad</h1>
<div mat-dialog-content>
    <form class="ui form" [formGroup]="novedadesForm" enctype="multipart/form-data">
        <div>
            <mat-form-field>
                <textarea matInput formControlName="texto" placeholder="Texto de la novedad" required rows="3" style="resize: none"></textarea>
            </mat-form-field>
            <div class="alert alert-danger p-1 m-1" *ngIf="
          novedadesForm.get('texto').errors &&
          submitted &&
          novedadesForm.get('texto').touched
        ">
                <p class="m-0 p-0" *ngIf="novedadesForm.get('texto').hasError('required')">
                    Debe poseer texto
                </p>
            </div>
        </div>
        <div>
            <mat-form-field>
                <mat-label>Fecha de publicación</mat-label>
                <input matInput [min]="fecha_min_fp" (dateInput)="addEvent($event)" [matDatepicker]="fecha_publicacion" formControlName="fecha_publicacion" required />
                <mat-datepicker-toggle matSuffix [for]="fecha_publicacion"></mat-datepicker-toggle>
                <mat-datepicker #fecha_publicacion></mat-datepicker>
            </mat-form-field>
            <div class="alert alert-danger p-1 m-1" *ngIf="
          novedadesForm.get('texto').errors &&
          submitted &&
          novedadesForm.get('texto').touched
        ">
                <p class="m-0 p-0" *ngIf="novedadesForm.get('fecha_publicacion').hasError('required')">
                    Debe poseer fecha de publicación
                </p>
            </div>

            <!-- agregando campo fecha de fin de publicacion -->
            <mat-form-field>
                <mat-label>Fecha de fin de publicación</mat-label>
                <input matInput [min]="fecha_min_ff" [matDatepicker]="fecha_fin" formControlName="fecha_fin" />
                <mat-datepicker-toggle matSuffix [for]="fecha_fin"></mat-datepicker-toggle>
                <mat-datepicker #fecha_fin></mat-datepicker>
            </mat-form-field>

        </div>


        <div mat-dialog-actions class="justify-content-center mb-1">
            <button mat-raised-button color="primary" (click)="cancelar()">
        cancelar
      </button>
            <button mat-raised-button color="warn" (click)="onSubmit()">
        confirmar
      </button>
        </div>
        <mat-spinner diameter="22" [hidden]="!loading" style="display: inline-block; margin-left: 5%; margin-top: 1%"></mat-spinner>
    </form>
</div>