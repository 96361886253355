<div class="container-fluid">
  <div class="col-sm-12 py-1 my-1">
    <div class="container pb-3" *ngIf="cargado && cargadoFiles">
      <form
        class="ui form"
        [formGroup]="filtrosForm"
        enctype="multipart/form-data"
      >
        <div class="row mt-3">
          <div
            class="form-group col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 d-flex justify-content-center"
          >
            <mat-form-field>
              <mat-label>Numero de DNI</mat-label>
              <input
                id="input_numero_DNI"
                matInput
                type="text"
                formControlName="solicitudId"
                pattern="[0-9]{1,8}"
                inputmode="numeric"
                (keydown)="onKeyDown($event)"
                (input)="onSolicitudIdInput($event)"
              />
              <button
                mat-button
                [hidden]="!valoresFormulario.solicitudId" 
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="limpiarFiltro('solicitudId')"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div
            class="form-group col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 d-flex justify-content-center"
          >
            <mat-form-field>
              <mat-label>Estado</mat-label>
              <mat-select
                formControlName="estado"
                [(value)]="filtrosForm.value.estado"
              >
                <mat-option value="">Todas</mat-option>
                <mat-option value="PENDIENTE">Pendientes</mat-option>
                <mat-option value="APROBADA">Aprobadas</mat-option>
                <mat-option value="DENEGADA">Denegadas</mat-option>
                <mat-option value="CARGANDO">Cargando</mat-option>
                <mat-option value="ERROR">Errores</mat-option>
              </mat-select>
              <button
                mat-button
                *ngIf="valoresFormulario.estado"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="limpiarFiltroEstado()"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div
            class="form-group col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 d-flex justify-content-center"
          >
            <mat-form-field>
              <mat-label>Fecha de solicitud</mat-label>
              <input
                matInput
                [matDatepicker]="fechaSolicitud"
                formControlName="fechaSolicitud"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="fechaSolicitud"
              ></mat-datepicker-toggle>
              <mat-datepicker #fechaSolicitud></mat-datepicker>
              <button
                mat-button
                *ngIf="valoresFormulario.fechaSolicitud"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="limpiarFiltro('fechaSolicitud')"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div
            class="form-group col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 d-flex align-items-center justify-content-center"
          >
            <button
              [disabled]="filtrosForm.invalid"
              mat-raised-button
              color="primary"
              class="w-100"
              (click)="getValidaciones()"
            >
              Buscar
            </button>
          </div>
        </div>
        <div
          class="alert alert-danger"
          *ngIf="filtrosForm.get('solicitudId').errors?.['pattern']"
        >
          Verifique el número de documento ingresado.
        </div>
      </form>
      <mat-spinner
        [hidden]="!cargandoPacientes"
        style="margin: auto"
        diameter="50"
      ></mat-spinner>
      <mat-accordion
        class="example-headers-align"
        *ngIf="respuestaValidacion.ok; else elseBlock"
      >
        <mat-expansion-panel
          *ngFor="let validacion of validacionesPendientes; let i = index"
          (opened)="setStep(i)"
          hideToggle
        >
          <mat-expansion-panel-header [collapsedHeight]="'60px'">
            <mat-panel-title>
              <strong class="mr-1"> N°: </strong>
              <span class="text-muted">{{ validacion.solicitudId }}</span>
              <mat-icon class="ml-2 mr-1">account_circle</mat-icon>
              <span class="text-muted">{{ validacion.usuario }}</span>
              <ng-template
                [ngIf]="validacion.tipoValidacion == 1"
                [ngIfElse]="esCobertura"
              >
                <span class="text-muted" id="indentado"> Paciente </span>
              </ng-template>
              <ng-template #esCobertura>
                <span class="text-muted" id="indentado"> Cobertura </span>
              </ng-template>
            </mat-panel-title>
            <mat-panel-description class="flex-row-reverse">
              {{ validacion.estado | mayusPipe }}
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="row mb-1">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <strong> DNI real: </strong>
              <span class="text-muted">{{ validacion.dni }}</span>
            </div>
            <div
              class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
              *ngIf="validacion.tipoValidacion === 1"
            >
              <strong> DNI temporal: </strong>
              <span class="text-muted">{{ validacion.dniTemporal }}</span>
            </div>
            <div
              class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
              *ngIf="validacion.tipoValidacion === 2"
            >
              <strong> Paciente: </strong>
              <span class="text-muted">{{
                validacion.nombrePacienteFusion
              }}</span>
            </div>
          </div>

          <div class="row mb-1">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <strong> Fecha solicitud: </strong>
              <span class="text-muted">{{
                validacion.fechaSolicitudValidacion | date : "dd/MM/yyyy"
              }}</span>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <strong> Fecha validación: </strong>
              <span
                *ngIf="
                  (validacion.fechaValidacion | date : 'dd/MM/yyyy') !==
                  '01/01/1900'
                "
                class="text-muted"
              >
                {{ validacion.fechaValidacion | date : "dd/MM/yyyy" }}</span
              >
            </div>
          </div>
          <div class="row mb-1" *ngIf="validacion.tipoValidacion === 1 && (validacion.estado | mayusPipe) !== 'Cargando'">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <strong>Imagen del DNI:</strong>
              <img
                class="img-responsive img-fluid center-block"
                alt="Responsive image"
                *ngIf="validacion.fotoDNIFrente != 'null'"
                [src]="files + validacion.fotoDNIFrente"
                [alt]="validacion.fotoDNIFrente"
              />
              <br />
              <div *ngIf="(validacion.estado | mayusPipe) === 'Denegada'">
                <strong>Motivo de denegacion: </strong>
                <p>{{ validacion.observacion }}</p>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <strong>Foto del paciente:</strong>
              <img
                class="img-responsive img-fluid center-block"
                alt="Responsive image"
                *ngIf="validacion.fotoPaciente != 'null'"
                [src]="files + validacion.fotoPaciente"
                [alt]="validacion.fotoPaciente"
              />
            </div>
          </div>

          <div class="row mb-1" *ngIf="validacion.tipoValidacion === 2">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <strong>Imagen del Carnet:</strong>
              <img
                class="img-responsive img-fluid center-block"
                alt="Responsive image"
                *ngIf="validacion.fotoDNIFrente != 'null'"
                [src]="files + validacion.fotoDNIFrente"
                [alt]="validacion.fotoDNIFrente"
              />
              <br />
              <div *ngIf="(validacion.estado | mayusPipe) === 'Denegada'">
                <strong>Motivo de denegacion: </strong>
                <p>{{ validacion.observacion }}</p>
              </div>
            </div>
          </div>

          <div
            *ngIf="
              (validacion.estado | mayusPipe) === 'Pendiente' &&
              !!validacion.nombrePacienteFusion &&
              validacion.tipoValidacion === 1
            "
          >
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header [collapsedHeight]="'80px'">
                  <mat-panel-title>
                    <span> Datos del paciente </span>
                  </mat-panel-title>
                  <mat-panel-description class="flex-row-reverse">{{
                    validacion.nombrePacienteFusion
                  }}</mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row mb-1">
                  <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5">
                    <strong>Nombre y apellido: </strong>
                    <span class="text-muted">{{
                      validacion.nombrePacienteFusion
                    }}</span>
                  </div>
                  <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                    <strong>Fecha nacimiento: </strong>
                    <span class="text-muted">{{
                      validacion.fechaNacimiento | date : "dd/MM/yyyy"
                    }}</span>
                  </div>
                  <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <strong> E-mail: </strong>
                    <span class="text-muted">{{ validacion.email }}</span>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                    <strong> Localidad: </strong>
                    <span class="text-muted">{{ validacion.localidad }}</span>
                  </div>
                  <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                    <strong> Domicilio: </strong>
                    <span class="text-muted">{{ validacion.domicilio }}</span>
                  </div>
                  <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                    <strong> Codigo postal: </strong>
                    <span class="text-muted">{{
                      validacion.codigoPostal
                    }}</span>
                  </div>
                  <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                    <strong> Telefono: </strong>
                    <span class="text-muted">{{ validacion.telefono }}</span>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>

          <div
            class="row mb-1"
            *ngIf="
              (validacion.estado | mayusPipe) === 'Aprobada' &&
              validacion.tipoValidacion === 1
            "
          >
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <strong>Paciente Fusion:</strong>
              {{ validacion.nombrePacienteFusion }}
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <strong>DNI Paciente Fusion:</strong> {{ validacion.dniFusion }}
            </div>
          </div>

          <form
            class="ui form"
            [formGroup]="coberturaForm"
            *ngIf="
              (validacion.estado | mayusPipe) === 'Pendiente' &&
              validacion.tipoValidacion === 2
            "
          >
            <div class="row" style="border: 0px solid red; margin-top: 1%">
              <div
                class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
                style="border: 0px solid blueviolet"
              >
                <mat-form-field style="max-width: 100% !important">
                  <mat-label>Financiador</mat-label>
                  <input
                    matInput
                    type="text"
                    formControlName="nombreFinanciador"
                    required
                  />
                </mat-form-field>
              </div>
              <div
                class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
                style="border: 0px solid blue"
              >
                <mat-form-field style="max-width: 100% !important">
                  <mat-label>Seleccionar Mutual</mat-label>
                  <mat-select formControlName="idMutual" required>
                    <mat-option
                      *ngFor="let mut of listaMutualesTodas"
                      [value]="mut.codigo"
                      [hidden]="mut.Financiador != validacion.idFinanciador"
                      >{{ mut.nombre }}</mat-option
                    >
                  </mat-select>
                  <mat-error>Debe seleccionar una mutual</mat-error>
                </mat-form-field>
              </div>
              <div
                class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
                style="border: 0px solid green"
              >
                <mat-form-field style="max-width: 100% !important">
                  <mat-label>Nro. de Carnet</mat-label>
                  <input
                    matInput
                    type="text"
                    formControlName="nroCarnet"
                    required
                  />
                  <mat-error>Debe ingresar un nro. de carnet</mat-error>
                </mat-form-field>
              </div>
            </div>
          </form>

          <form
            class="ui form"
            [formGroup]="filtrosPacienteFusionarForm"
            enctype="multipart/form-data"
            *ngIf="
              (validacion.estado | mayusPipe) === 'Pendiente' &&
              validacion.tipoValidacion === 1
            "
          >
            <div class="row mt-3">
              <div
                class="form-group col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 d-flex justify-content-center"
              >
                <mat-form-field>
                  <mat-label>DNI</mat-label>
                  <input matInput type="number" formControlName="dni" />
                  <button
                    mat-button
                    *ngIf="valoresFormularioFiltrosPaciente.dni"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="limpiarFiltroPaciente('dni')"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>
              <div
                class="form-group col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 d-flex justify-content-center"
              >
                <mat-form-field>
                  <mat-label>Apellido</mat-label>
                  <input matInput type="text" formControlName="apellido" />
                  <button
                    mat-button
                    *ngIf="valoresFormularioFiltrosPaciente.apellido"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="limpiarFiltroPaciente('apellido')"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>
              <div
                class="form-group col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 d-flex justify-content-center"
              >
                <mat-form-field>
                  <mat-label>Nombre</mat-label>
                  <input matInput type="text" formControlName="nombre" />
                  <button
                    mat-button
                    *ngIf="valoresFormularioFiltrosPaciente.nombre"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="limpiarFiltroPaciente('nombre')"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>
              <div
                class="form-group col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 d-flex justify-content-center"
              >
                <button
                  mat-raised-button
                  color="primary"
                  class="w-100"
                  (click)="buscarPacientes()"
                  [hidden]="cargandoPacientes"
                >
                  Buscar
                </button>
                <mat-spinner
                  [hidden]="!cargandoPacientes"
                  style="margin: auto"
                  diameter="50"
                ></mat-spinner>
              </div>
            </div>

            <div *ngIf="pacientesCargados">
              <mat-accordion
                class="example-headers-align"
                *ngIf="
                  respuestaPacientes.ok &&
                    respuestaPacientes.paginador.elementos.length > 0;
                  else elseBlockPacientes
                "
              >
                <mat-expansion-panel
                  *ngFor="let paciente of listaPacientes; let ii = index"
                  [expanded]="stepPacientes === ii"
                  (opened)="setStepPacientes(ii)"
                  (click)="seleccionarPaciente(paciente)"
                  hideToggle
                >
                  <mat-expansion-panel-header [collapsedHeight]="'80px'">
                    <mat-radio-group aria-label="Seleccione un paciente">
                      <mat-radio-button
                        value="ii"
                        [checked]="stepPacientes === ii"
                      ></mat-radio-button>
                    </mat-radio-group>
                    <mat-panel-title>
                      <mat-icon class="mr-1">account_circle</mat-icon>
                      <span class="text-muted"> {{ paciente.apellido }} </span>
                    </mat-panel-title>
                    <mat-panel-description class="flex-row-reverse"
                      >DNI: {{ paciente.nroDocumento }}
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="row mb-1">
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                      <strong> Tipo DNI: </strong>
                      <span class="text-muted">{{
                        paciente.tipoDocumento
                      }}</span>
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                      <strong> Edad: </strong>
                      <span class="text-muted">{{ paciente.edad }}</span>
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                      <strong> Fecha nacimiento: </strong>
                      <span class="text-muted">{{
                        paciente.fechaNacimiento
                      }}</span>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>

              <ng-template #elseBlockPacientes>
                No hay pacientes con las condiciones solicitadas.</ng-template
              >
            </div>
          </form>

          <mat-action-row>
            <button
              *ngIf="(validacion.estado | mayusPipe) === 'Pendiente'"
              mat-raised-button
              color="warn"
              (click)="cambiarEstadoSolicitud(validacion)"
            >
              Denegar
            </button>
            <button
              [disabled]="!pacienteSeleccionado"
              mat-raised-button
              color="primary"
              class="ml-2"
              *ngIf="(validacion.estado | mayusPipe) === 'Pendiente'"
              (click)="aprobarSolicitud(validacion)"
            >
              Aprobar
            </button>
            <button
              mat-raised-button
              color="primary"
              *ngIf="(validacion.estado | mayusPipe) === 'Denegada'"
              (click)="cambiarEstadoSolicitud(validacion)"
            >
              Reabrir
            </button>
          </mat-action-row>
        </mat-expansion-panel>
      </mat-accordion>
      <ng-template #elseBlock>
        <div *ngIf="!cargandoPacientes">
          {{ this.respuestaValidaciones.mensaje }}
        </div>
      </ng-template>
    </div>
  </div>
</div>
