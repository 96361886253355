import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppComponent } from '../app.component';

@Injectable({
  providedIn: 'root'
})
export class CumpleanosService {

  baseUrl: string;

  constructor(
  	private httpClient: HttpClient
  ) { 
    this.baseUrl = AppComponent.baseUrl;
  }

  getCumpleanos() {
    // return this.httpClient.get<any[]>('http://localhost:8080/thot_restfull/api/usuariosLDAP/getcumpleanos');
    return this.httpClient.get<any[]>(this.baseUrl + 'usuariosLDAP/getcumpleanos');
  }
  
}
