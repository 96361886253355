import { Component, OnInit } from '@angular/core';
import { ComunicacionService } from '../services/comunicacion.service';
import { CumpleanosService } from '../services/cumpleanos.service';
import { Noticia } from '../mostrar-noticias/mostrar-noticias.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogCrearNovedadComponent } from '../dialog-crear-novedad/dialog-crear-novedad.component';
import { EmbedUrlsService } from '../services/embedUrls.service';
import { UserService } from '../services/user.service';
import { AuthenticationService } from '../services/authentication.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from '../models/user.model';
import { DialogCambiarCorreoComponent } from '../dialog-cambiar-correo/dialog-cambiar-correo.component';
import { UrlResolverService } from '../services/urlResolver.service';
import { AppComponent } from '../app.component';
import { DialogCambiarContrasenaComponent } from '../dialog-cambiar-contrasena/dialog-cambiar-contrasena.component';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})

export class HomeComponent implements OnInit {

  public novedades: Noticia[] = null;
  public cumpleanos = [];
  usuarioLog;
  editor: boolean;
  convenioForm: UntypedFormGroup;
  convenioFormSubmitted = false;
  success: boolean = false;
  fecha: String = new Date().toLocaleDateString();
  date = new UntypedFormControl(new Date());
  nombreUser: User;
  estadoConvenio;
  url_telefonos_internos: String;
  urlCalendario: string;
  baseUrl: string;
  eventosSociales = null;

  constructor(
    private cumpleService: CumpleanosService,
    public comunicacionService: ComunicacionService,
    public dialog: MatDialog,
    private EmbedUrlSer: EmbedUrlsService,
    private userService: UserService,
    private authService: AuthenticationService,
    private urlResolver: UrlResolverService,
    private router: Router,
    private fb: UntypedFormBuilder
  ) {
    this.baseUrl = AppComponent.baseUrl
    // this.usuarioLog = JSON.parse(sessionStorage.getItem('currentUser')).usuario;
    // this.authService.currentUser.subscribe(async (resp) => {
    //   await this.delay(300);
    //   this.userService.getConvenioConf(this.usuarioLog.usuario).subscribe((res) => {
    //     console.log(res)
    //     if (res != null) {
    //       if (this.usuarioLog && (res.convenioConfidencialidad == null || res.convenioConfidencialidad == 'ASO')) {
    //         this.openModalConvenioConf();
    //       } else if (res.mailActualizado) {
    //         this.openDialogCambiarCorreo(res);
    //       }
    //     }
    //   }, (error) => {
    //     console.log(error);
    //   });
    // });

    this.EmbedUrlSer.embedUrls.subscribe((funciones) => {
      if (funciones.find((funcion) => {
          return funcion.url === 'agregar-noticias';
      })) {
        this.editor = true;
      } else {
        this.editor = false;
      }
    });
  }

  async ngOnInit() {
    this.iniciarConvenioForm();
    this.iniciarUsuarioLog();
    this.getNovedadesFraccionadas();  
    this.getEventosSociales();
    this.cargarUrlTelefonosInternos();
    this.cargarCalendario();
  }

  async iniciarUsuarioLog() {
    this.usuarioLog = JSON.parse(sessionStorage.getItem('currentUser'));
    this.userService.getConvenioConf(this.usuarioLog.usuario).toPromise().then(res => {
      // console.log(res)
      if (res != null) {
        if (new Date().getTime() > new Date(res.fechaExpiracionContrasena).getTime()) {
          this.openDialogCambiarContrasena(this.usuarioLog);
        } else if (this.usuarioLog && (res.convenioConfidencialidad == null || res.convenioConfidencialidad == 'ASO')) {
          this.openModalConvenioConf();
        } 
        // else if (!res.mailActualizado) {
        //   this.openDialogCambiarCorreo(res);
        // }
      }
    })
    .catch(error => {
      console.error(error)
    });
  }

  openDialogCambiarContrasena(objUsuarioLog) {
    this.dialog.open(DialogCambiarContrasenaComponent, {
      width: '500px',
      data: objUsuarioLog,
      hasBackdrop: true,
      disableClose: true
    }).afterClosed().subscribe(logout => {
      if (logout) {
        this.authService.logout();
      }
    });
  }

  iniciarConvenioForm() {
    this.convenioForm = this.fb.group({
      check: [false, Validators.required],
    });
  }

  openDialogCambiarCorreo(usuario) {
    const dialogRef = this.dialog.open(DialogCambiarCorreoComponent, {
      data: usuario,
    });
  }

  getNovedadesFraccionadas() {
    this.comunicacionService
      .getNovedadesFraccionadas(0, 5, this.usuarioLog)
      .subscribe(
        (res) => {
          if (res.length !== 0) {
            this.novedades = res;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getCumples() {
    this.cumpleService.getCumpleanos().subscribe(
      (res) => {
        if (res != null) {
          this.cumpleanos = res;
        } else {
          this.cumpleanos = null;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getEventosSociales() {
    let hoy = new Date();
    let filtroBusqueda = {
      fechaDesde: hoy,
      fechaHasta: hoy, 
      tipoEvento: 2,
    }
    this.comunicacionService.getEventosSociales(filtroBusqueda).subscribe((res) => {
      if (res.ok) {
        this.eventosSociales = res.eventos;
      } 
    },
    (error) => {
      console.log(error);
    })
  }

  crearNovedad() {
    const dialogRef = this.dialog.open(DialogCrearNovedadComponent, {
      width: '400px',
    });
  }

  modificarNovedad(idNovedad: number): void {
    const dialogRef = this.dialog.open(DialogCrearNovedadComponent, {
      width: '400px',
      data: idNovedad,
    });
  }

  logout() {
    this.authService.logout();
    window.location.reload();
    this.router.navigate(['/login']);
  }

  submitConvenio() {
    this.userService.aceptarConvenioConf(this.usuarioLog.usuario).subscribe(
      (x) => {
        this.userService.registrarConvenioDeConf(this.usuarioLog).subscribe(
          (x) => {},
          (error) => {
            console.log(error);
          }
        );
        $('#modalConvenioConf').modal('toggle');
      },
      (error) => {
        console.log(error);
      }
    );
  }
  openModalConvenioConf() {
    document.getElementById('openModalConvenioConf').click();
    this.success = false;
  }
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  cargarUrlTelefonosInternos() {
    let nombre = 'url_telefonos_internos';
    this.urlResolver.getValorParametro(nombre).subscribe(
      (data) => {
        //console.log(data)
        if(data.paginador.elementos.length > 0){
          this.url_telefonos_internos = this.baseUrl + 'restricted/fs/getFile?pathString=' + data.paginador.elementos[0].valor;
        }
      });
  }

  cargarCalendario() {
    let nombre = 'calendario_2024';
    this.urlResolver.getValorParametro(nombre).subscribe((data) => {
      //console.log(data)
      if (data.paginador.elementos.length > 0) {
        this.urlCalendario = this.baseUrl + 'restricted/fs/getFile?pathString=' + data.paginador.elementos[0].valor;
      }
    });
  }
}
