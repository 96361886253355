<h1 class="text-center" mat-dialog-title>Motivo</h1>
<div mat-dialog-content>
    <div class="d-flex flex-column justify-content-center">
        <mat-form-field appearance="outline">
            <textarea matInput [formControl]="motivo" rows="3" maxlength="255"></textarea>
            <mat-error *ngIf="motivo.hasError('pattern') && submitted">Solo se permiten letras, numeros, puntos y comas</mat-error>
            <mat-error *ngIf="motivo.hasError('required') && submitted">El motivo es <b>obligatorio</b></mat-error>
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions class="justify-content-center">
    <button mat-raised-button color="warn" (click)="onNoClick()">Cancelar</button>
    <button mat-raised-button color="primary" (click)="confirmar()">Confirmar</button>
</div>