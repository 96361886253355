import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ValidacionesService } from '../services/validaciones-service.service';
import { AppComponent } from '../app.component';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { EmbedUrlsService } from '../services/embedUrls.service';
import { Router } from '@angular/router';
import { DialogValidacionMotivoComponent } from '../dialog-validacion-motivo/dialog-validacion-motivo.component';
import { MutualesService } from '../services/mutuales.service';
// import { INVALID } from '@angular/forms/src/model';
import { UrlResolverService } from '../services/urlResolver.service';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import { UserService } from '../services/user.service';

export interface Paciente {
  nroDocumento: number;
  tipoDocumento: number;
  apellido: string;
  fechaNacimiento: string;
  sexo: string;
  edad: number;
}
export interface Paginador {
  paginaActual: number;
  cantPorPagina: number;
  totalPaginas: number;
  hayPaginaAnterior: boolean;
  hayPaginaSiguiente: boolean;
  elementos: Paciente[];
}
export interface RespuestaPaciente {
  ok: boolean;
  codigo: number;
  mensaje: string;
  paginador: Paginador;
}
export interface Validacion {
  solicitudId: number;
  dni: number;
  tipoDocumento: number;
  dniTemporal: number;
  fotoDNIFrente: string;
  fotoPaciente: string;
  fechaValidacion: string;
  usuario: string;
  estado: string;
  fechaSolicitudValidacion: string;
  dniFusion: number;
  nombrePacienteFusion: string;
  domicilio: string;
  localidad: string;
  codigoPostal: number;
  telefono: string;
  fechaNacimiento: string;
  email: string;
  observacion: string;
  idFinanciador: number;
  nombreFinanciador: string;
  nroCarnet: string;
  tipoValidacion: number;
}
export interface RespuestaValidaciones {
  ok: boolean;
  validaciones: Validacion[];
  mensaje: string;
}
@Component({
  selector: 'app-validar-usuarios',
  templateUrl: './validar-usuarios.component.html',
  styleUrls: ['./validar-usuarios.component.css'],
})
export class ValidarUsuariosComponent implements OnInit {
  step = 0;
  stepPacientes = null;
  filtrosForm: UntypedFormGroup;
  cargado = false;
  cargadoFiles = false;
  pacientesCargados = false;
  files: string;
  baseUrl: string;
  pacienteFusionarForm: UntypedFormGroup;
  filtrosPacienteFusionarForm: UntypedFormGroup;
  respuestaValidaciones: RespuestaValidaciones;
  respuestaPacientes: RespuestaPaciente;
  coberturaForm: UntypedFormGroup;
  listaMutualesTodas: any[] = [];
  usuarioLogueado: any;
  cargandoPacientes= false;
  pacienteSeleccionado: boolean;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private validacionesServicio: ValidacionesService,
    private urlResolverService: UrlResolverService,
    public dialog: MatDialog,
    private EmbedUrlSer: EmbedUrlsService,
    private router: Router,
    private mutualesService: MutualesService,
    private userService: UserService
  ) {
    this.EmbedUrlSer.embedUrls.subscribe((funciones) => {
      if (
        funciones.find((funcion) => {
          return funcion.url === 'validar-usuarios';
        })
      ) {
      } else {
        this.router.navigate(['/inicio']);
      }
    });
    this.baseUrl = AppComponent.baseUrl;
    this.validacionesServicio.getParametroImagenesValidacion().subscribe(
      (res) => {
        const respuesta = res.paginador.elementos.find((elemento) => {
          return elemento.nombre === 'validacionUsuario';
        });
        this.files =
          this.baseUrl + 'restricted/fs/getImage?pathString=' + respuesta.valor;
        this.cargadoFiles = true;
      },
      (error: any) => {
        console.log('error al buscar parametro!');
        console.log(error);
      }
    );
  }

  ngOnInit() {
    this.getUser();
    this.obtenerMutuales();
    this.buildFiltrosForm();
    this.buildPacienteFusionarForm();
    this.buildFiltrosPacienteFusionarForm();
    this.buildCoberturaForm();
    // this.getValidaciones(this.filtrosForm.value);
    this.getValidaciones();

    
  }

  private buildCoberturaForm() {
    this.coberturaForm = this.formBuilder.group({
      nombreFinanciador: [{ value: null, disabled: true}, Validators.required],
      nroCarnet: [{ value: null }, Validators.required],
      idMutual: [undefined, Validators.required]
    });
  }

  private buildFiltrosForm() {
    let ex_regular_dni; 
    ex_regular_dni = /^[0-9]{1,8}$/;
    this.filtrosForm = this.formBuilder.group({
      solicitudId: [null, Validators.pattern(ex_regular_dni)],
      estado: ['PENDIENTE'],
      fechaSolicitud: [new Date(), Validators.maxLength(10)],
    });
    
  }

  private buildFiltrosPacienteFusionarForm() {
    this.filtrosPacienteFusionarForm = this.formBuilder.group({
      dni: [null],
      nombre: [null],
      apellido: [null],
    });
  }

  private buildPacienteFusionarForm() {
    this.pacienteFusionarForm = this.formBuilder.group({
      solicitudId: [null, Validators.required],
      tipoDniAnterior: [null, Validators.required],
      dniAnterior: [null, Validators.required],
      tipoDniActual: [null, Validators.required],
      dniActual: [null, Validators.required],
    });
  }

  async getUser() {
    this.usuarioLogueado = this.userService.getLoggedIn();
    // console.log(this.usuarioLogueado)
  }

  async obtenerMutuales() {
    await this.urlResolverService.getHorusRestfullUrl().toPromise().then(respGetUrl => {
      // console.log(respGetUrl)
      this.mutualesService.getMutuales(respGetUrl).toPromise().then(resp => {
        // console.log(resp)
        if (resp.ok && resp.paginador.elementos.length > 0) {
          this.listaMutualesTodas = resp.paginador.elementos.sort((a,b) => {
            if(a.nombre < b.nombre) { return -1; }
            if(a.nombre > b.nombre) { return 1; }
          });
        }
      }, error => {
        console.log(error)
      })
    }, error => {
      console.log(error)
    })
  }

  async setStep(index: number) {
    // console.log(this.respuestaValidaciones.validaciones[index]);

    this.step = index;
    this.filtrosPacienteFusionarForm.get('dni').setValue(this.respuestaValidaciones.validaciones[index].dni);
    this.limpiarFiltroPaciente('nombre');
    this.limpiarFiltroPaciente('apellido');
    this.pacienteFusionarForm.get('solicitudId').setValue(this.respuestaValidaciones.validaciones[index].solicitudId);
    this.pacienteFusionarForm.get('dniAnterior').setValue(this.respuestaValidaciones.validaciones[index].dniTemporal);
    this.pacienteFusionarForm.get('tipoDniAnterior').setValue(this.respuestaValidaciones.validaciones[index].tipoDocumento);
    this.pacienteFusionarForm.get('dniActual').setValue(null);
    this.pacienteFusionarForm.get('tipoDniActual').setValue(null);
    this.buscarPacientes();

    this.coberturaForm.get('nombreFinanciador').setValue(this.respuestaValidaciones.validaciones[index].nombreFinanciador);
    this.coberturaForm.get('nroCarnet').setValue(this.respuestaValidaciones.validaciones[index].nroCarnet);
    this.coberturaForm.get('idMutual').setValue(undefined);
  }

  setStepPacientes(index: number) {
    this.stepPacientes = index;
  }

  openDialog(respuesta): void {
    const dialogRef = this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: respuesta,
    });
    dialogRef.afterClosed().subscribe((res) => {
      this.getValidaciones();
    });
  }

  fusionarPacientes() {
    this.validacionesServicio.fusionarPacientes(this.pacienteFusionarForm.value).subscribe((res) => {
      this.openDialog(res);
    }, (error) => {
      console.log(error);
    });
  }

  async aprobarCobertura(solicitud: Validacion) {
    await this.validacionesServicio.setEstadoValidacion(
      solicitud.solicitudId,
      'APROBADA',
      'Se aprueba la cobertura correctamente',
      solicitud.dni,
      solicitud.tipoDocumento,
      solicitud.tipoValidacion,
      this.coberturaForm.get('idMutual').value,
      solicitud.nroCarnet,
      this.usuarioLogueado.usuario).toPromise().then((res) => {
        // console.log(res)
        if (res.ok) {
          res.mensaje = 'Se aprobo la cobertura correctamente';
        }
        
        this.openDialog(res);
      }, (error) => {
        console.log(error);
        error.ok = false;
        error.mensaje = "Error al agregar la cobertura";
        this.openDialog(error);
      }
    );
  }

  aprobarSolicitud(solicitud: Validacion) {
    if (solicitud.tipoValidacion === 1) {          // VALIDAR PACIENTE
      this.fusionarPacientes();
    } else if (solicitud.tipoValidacion === 2) {  // VALIDAR COBERTURA

      if (this.coberturaForm.invalid) {
        this.coberturaForm.get('idMutual').markAsTouched();
      } else if (this.coberturaForm.valid) {

        if (this.coberturaForm.get('nroCarnet').dirty) {
          this.dialog.open(DialogConfirmacionComponent, {
            data: '¿Esta seguro/a de modificar el Nro. de Carnet?',
          }).afterClosed().subscribe((confirmado: boolean) => {
            if (confirmado) {
              this.aprobarCobertura(solicitud);
            }
          });
        } else {
          this.aprobarCobertura(solicitud);
        }

      }

    }
  }

  cambiarEstadoSolicitud(solicitud: Validacion) {
    switch (solicitud.estado) {
      //Codigo que se ejecuta para cambiar solicitud de validacion PENDIENTE a DENEGADA
      case 'PENDIENTE':
        const dialog= this.dialog.open(DialogValidacionMotivoComponent, {
          width: '500px'
        })
    
        dialog.afterClosed().subscribe(motivo => {
          if (motivo != false) {
            //Llamo al modulo que denega la solicitud;
            this.validacionesServicio.setEstadoValidacion(
              solicitud.solicitudId,
              'DENEGADA',
              motivo,
              (solicitud.tipoValidacion === 2) ? solicitud.dni : solicitud.dniTemporal,
              solicitud.tipoDocumento,
              1,
              0,
              '',
              this.usuarioLogueado.usuario).subscribe((res) => {
                this.getValidaciones();                 
              }, (error) => {
                console.log(error);
              });
          }
        })
        break

      //Codigo que se ejecuta para cambiar solicitud de validacion DENEGADA a PENDIENTE
      case 'DENEGADA': 
      this.validacionesServicio.setEstadoValidacion(
        solicitud.solicitudId,
        'PENDIENTE',
        'Solicitud reabierta',
        solicitud.dniTemporal,
        solicitud.tipoDocumento,
        1,
        0,
        '',
        this.usuarioLogueado.usuario).subscribe((res) => {
          this.getValidaciones();
        }, (error) => {
          console.log(error);
        }
      );
    }
  }

  
  prevStep() {
    this.step--;
  }

  get f() {
    return this.filtrosForm.controls;
  }
  get valoresFormulario() {
    return this.filtrosForm.value;
  }
  get valoresFormularioFiltrosPaciente() {
    return this.filtrosPacienteFusionarForm.value;
  }
  get validacionesPendientes() {
    return this.respuestaValidaciones.validaciones;
  }
  get listaPacientes() {
    return this.respuestaPacientes.paginador.elementos;
  }
  get respuestaValidacion() {
    return this.respuestaValidaciones;
  }
  limpiarFiltro(campo) {
    this.filtrosForm.get(campo).setValue(null);
    // this.getValidaciones();
  }
  limpiarFiltroPaciente(campo) {
    this.filtrosPacienteFusionarForm.get(campo).setValue(null);
    // this.buscarPacientes();
  }
  limpiarFiltroEstado() {
    //Lo pongo por fuera para no hacer un if en el limpiar filtro y que consulte siempre de gusto
    this.filtrosForm.get('estado').setValue('');
    // this.getValidaciones();
  }
  getValidaciones() {
    this.cargandoPacientes = true;
    if (this.filtrosForm.valid) {
      this.validacionesServicio
        .getValidaciones(
          this.filtrosForm.value.solicitudId,
          this.filtrosForm.value.estado,
          this.filtrosForm.value.fechaSolicitud
        )
        .subscribe(
          (res) => {
            // console.log(res)
            this.step = 0;
            this.respuestaValidaciones = res;
            //Las ordeno por fecha de manera descenciente
            this.respuestaValidaciones.validaciones.sort((a,b)=> Date.parse(b.fechaSolicitudValidacion) - Date.parse(a.fechaSolicitudValidacion) );
            this.cargado = true;
            this.cargandoPacientes=false;
            // console.log(res)
          },
          (error) => {
            console.log(error);
          }
         
        );
        
    }
  }
  funcionBoton(){
    console.log('buscar');
  }
   buscarPacientes() {
    this.cargandoPacientes = true;
    this.stepPacientes = null;
 
    this.validacionesServicio
      .getPacientesPorFiltros(this.filtrosPacienteFusionarForm.value)
      .subscribe(
        
        (res: RespuestaPaciente) => {
           
          this.calcularEdad(res);
          this.respuestaPacientes = res;
            this.pacientesCargados = true;
           this.cargandoPacientes = false;
          
         
        },
        (error) => {
          console.log(error);
        }
      );
  }
  seleccionarPaciente(paciente: Paciente) {
    // debugger;
    // seleccionarPaciente(paciente: Paciente, validacion: Validacion) {
    // this.pacienteFusionarForm
    //   .get('solicitudId')
    //   .setValue(validacion.solicitudId);
    // this.pacienteFusionarForm
    //   .get('dniAnterior')
    //   .setValue(validacion.dniTemporal);
    // this.pacienteFusionarForm
    //   .get('tipoDniAnterior')
    //   .setValue(validacion.tipoDocumento);
    this.pacienteSeleccionado = true;
    this.pacienteFusionarForm.get('dniActual').setValue(paciente.nroDocumento);
    this.pacienteFusionarForm
      .get('tipoDniActual')
      .setValue(paciente.tipoDocumento);
    // this.stepPacientes = null;
    // console.log(paciente)
  }
  public calcularEdad(res: RespuestaPaciente): void {
    res.paginador.elementos.forEach((paciente) => {
      // debugger;
      let a = Date.now();
      let vector = paciente.fechaNacimiento.split('/');
      let timeDiff = Math.abs(
        Date.now() -
          new Date(
            parseInt(vector[2]),
            parseInt(vector[1]),
            parseInt(vector[0])
          ).getTime()
      );
      let c = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      paciente.edad = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
    });
  }

  // Esta función restringe la entrada de valores solo a numéricos, la tecla de retroceso, delete, atajo CTRL+V y seleccion con shift.  
  // Evita que se ingrese cualquier otra tecla en el campo de entrada.
  onKeyDown(event: KeyboardEvent) {
    if (
      !(
        (event.key >= '0' && event.key <= '9') ||
        event.key === 'Backspace' ||
        event.key === 'Delete' ||
        (event.ctrlKey && event.key.toLowerCase() === 'v') ||
        (event.shiftKey && (event.key === 'ArrowLeft' || event.key === 'ArrowRight'))
      )
    ) {
      event.preventDefault();
    }
  }

  onSolicitudIdInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    const numbersOnly = value.replace(/[^\d]/g, ''); // Filtra los puntos y deja solo los números
    inputElement.value = numbersOnly;
    this.filtrosForm.get('solicitudId').setValue(numbersOnly);
  }
}
