import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppComponent } from '../app.component';
import { UrlResolverService } from './urlResolver.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class ValidacionesService {
  baseUrl: string;
  horusUrl: string;
  constructor(
    private httpClient: HttpClient,
    private urlResolver: UrlResolverService
  ) {
    this.baseUrl = AppComponent.baseUrl;
    this.urlResolver.getHorusRestfullUrl().subscribe(
      (res) => {
        this.horusUrl = res;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getValidaciones(solicitudId, estado, fechaSolicitud) {
    //en datos recibe los filtros: solicitudId, estado y fechaSolicitud
    const datos: string = JSON.stringify({
      solicitudId: solicitudId,
      estado: estado === '' ? null : estado,
      fechaSolicitud: fechaSolicitud
        ? (fechaSolicitud = new DatePipe('en-US').transform(
            fechaSolicitud,
            'yyyy-MM-dd'
          ))
        : null,
    });
    return this.httpClient.post<any>(
      this.baseUrl + 'validacion/getValidaciones',
      datos,
      httpOptions
    );
  }
  getParametroImagenesValidacion() {
    return this.httpClient.get<any>(
      this.baseUrl + '/core/parametros/buscar/' + 'validacion',
      httpOptions
    );
  }

  setEstadoValidacion(
    solicitudId: number,
    estado: string,
    observacion: string,
    DNI: number,
    tipoDNI: number,
    tipoValidacion: number,
    idMutual: number,
    nroCarnet: string,
    usuario: string
  ) {
    const datos: string = JSON.stringify({
      solicitudId,
      estado,
      observacion,
      DNI,
      tipoDNI,
      tipoValidacion,
      idMutual,
      nroCarnet,
      usuario
    });
    // console.log(datos)
    return this.httpClient.post<any>(
      this.baseUrl + 'validacion/cambiarEstadoSolicitudUsuario',
      datos,
      httpOptions
    );
  }

  getPacientesPorFiltros(filtrosBusqueda) {
    let apellido: string;
    let nombre: string;
    let dni: number;
    apellido = filtrosBusqueda.apellido ? filtrosBusqueda.apellido : '-1';
    nombre = filtrosBusqueda.nombre ? filtrosBusqueda.nombre : '-1';
    dni = filtrosBusqueda.dni ? filtrosBusqueda.dni : '-1';
    return this.httpClient.get<any>(
      this.baseUrl +
        // 'pacientes/seguro/buscarPaciente/' +
        'pacientes/buscarPaciente/' +
        apellido +
        '/' +
        nombre +
        '/' +
        dni,
      httpOptions
    );
  }

  fusionarPacientes(datos) {
    // debugger;
    return this.httpClient.post<any>(
      this.baseUrl + 'validacion/fusionarPacientes',
      datos,
      httpOptions
    );
  }
}
