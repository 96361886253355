import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mayusPipe'
})
export class MayusPipePipe implements PipeTransform {

  constructor() {}

  transform(value: string): any {
    return value.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

}
