import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { ComunicacionService } from '../services/comunicacion.service';

@Component({
  selector: 'app-dialog-editar-notificaciones',
  templateUrl: './dialog-editar-notificaciones.component.html',
  styleUrls: ['./dialog-editar-notificaciones.component.css']
})



export class DialogEditarNotificacionesComponent implements OnInit {
  notificacionesForm:UntypedFormGroup;
  loading = false;
  public Editor = DecoupledEditor;
  
  constructor(
    private comunicacion:ComunicacionService,
    private formBuilder:UntypedFormBuilder,
    public dialog: MatDialog,
    private route:Router,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }
  public onReady(editor) {
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );
  }
  ngOnInit() {
    this.buildForm();
  }
  buildForm(){
    this.notificacionesForm = this.formBuilder.group({
      id:[this.data.notificacionId],
      estado:[this.data.estado],
      motivo:[this.data.motivo],
      mensaje:[this.data.contAdicional]


    })
  }
  onSubmitForm(){
    
    this.loading = true;
    
    this.comunicacion.setNotificacionesCorreo(this.notificacionesForm.value.id,this.notificacionesForm.value.estado, this.notificacionesForm.value.asunto, this.notificacionesForm.value.mensaje).subscribe(
      (data2)=>{
       
        if(data2.ok)
        {
          this.dialog.open(DialogMensajeComponent,{
            width: '500px',
            data:{
              ok: true,
              mensaje: "Notificacion modificada correctamente"
            }

        }
        
        )

      }
      }
        

      )



  }
  public onChange( { editor }: ChangeEvent ) {
    const data = editor.getData();

  }

}

