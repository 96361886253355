<div *ngIf="cargado; else elseBlock" class="container container-fluid">
    <table mat-table [dataSource]="respuesta" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="tablero">
            <th mat-header-cell *matHeaderCellDef> Tablero </th>
            <td mat-cell *matCellDef="let element" class="pr-2"> {{element.tablero}} </td>
        </ng-container>


        <!-- Symbol Column -->
        <ng-container matColumnDef="link">
            <th mat-header-cell *matHeaderCellDef class="text-center">Link</th>
            <td mat-cell class="text-center" *matCellDef="let element">
                <a mat-button color="primary" href="{{element.link}}" target="_blank">Ver</a>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

</div>

<ng-template #elseBlock>
    <div class="container container-fluid">
        <mat-progress-bar class="mt-4" mode="indeterminate"></mat-progress-bar>
    </div>
</ng-template>