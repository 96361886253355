import { Component, OnInit } from '@angular/core';
import { TableroService } from '../services/tablero.service';
import { UrlResolverService } from '../services/urlResolver.service';
import * as moment from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-pacientes-guardia-semana',
  templateUrl: './pacientes-guardia-semana.component.html',
  styleUrls: ['./pacientes-guardia-semana.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class PacientesGuardiaSemanaComponent implements OnInit {

  cargando: boolean = true;
  errorGetTriage: boolean = false;
  noExistenPacientes: boolean = false;
  dateHasta = new FormControl(moment().subtract(1, 'day'));
  maxDate = moment();
  arrayPrfesionales = [];
  arrayTriageDelDia: any;
  totalTriages: number = 0;
  totalTriageProf: number = 0;
  fechasPacientes = []
  arrayTriageTotales;
  promedio = 0
  cargandoTotal: boolean = true;

  constructor(
    private tableroService: TableroService,
    private urlResolverService: UrlResolverService
  ) { }

  ngOnInit() {
    this.calcularFechas();
    this.getTriagesDelDia(moment().subtract(7, 'day').startOf('day').format("DD-MM-YYYY HH:mm:ss"), moment().subtract(1, 'day').endOf('day').format("DD-MM-YYYY HH:mm:ss"));
  }

  async contarTriage(listaTriage) {
    //Recuenta las triages, totalizando por día y horario, y hace un promedio
    this.arrayTriageTotales = [{ total: 'Total', totalAbsoluto: 0, totalFecha1: 0, totalFecha2: 0, totalFecha3: 0, totalFecha4: 0, totalFecha5: 0, totalFecha6: 0, totalFecha7: 0 }];
    this.arrayTriageDelDia = [
      { horario: '00:00 - 01:00', total: 0, cantFecha1: '', cantFecha2: '', cantFecha3: '', cantFecha4: '', cantFecha5: '', cantFecha6: '', cantFecha7: '' },
      { horario: '01:00 - 02:00', total: 0, cantFecha1: '', cantFecha2: '', cantFecha3: '', cantFecha4: '', cantFecha5: '', cantFecha6: '', cantFecha7: '' },
      { horario: '02:00 - 03:00', total: 0, cantFecha1: '', cantFecha2: '', cantFecha3: '', cantFecha4: '', cantFecha5: '', cantFecha6: '', cantFecha7: '' },
      { horario: '03:00 - 04:00', total: 0, cantFecha1: '', cantFecha2: '', cantFecha3: '', cantFecha4: '', cantFecha5: '', cantFecha6: '', cantFecha7: '' },
      { horario: '04:00 - 05:00', total: 0, cantFecha1: '', cantFecha2: '', cantFecha3: '', cantFecha4: '', cantFecha5: '', cantFecha6: '', cantFecha7: '' },
      { horario: '05:00 - 06:00', total: 0, cantFecha1: '', cantFecha2: '', cantFecha3: '', cantFecha4: '', cantFecha5: '', cantFecha6: '', cantFecha7: '' },
      { horario: '06:00 - 07:00', total: 0, cantFecha1: '', cantFecha2: '', cantFecha3: '', cantFecha4: '', cantFecha5: '', cantFecha6: '', cantFecha7: '' },
      { horario: '07:00 - 08:00', total: 0, cantFecha1: '', cantFecha2: '', cantFecha3: '', cantFecha4: '', cantFecha5: '', cantFecha6: '', cantFecha7: '' },
      { horario: '08:00 - 09:00', total: 0, cantFecha1: '', cantFecha2: '', cantFecha3: '', cantFecha4: '', cantFecha5: '', cantFecha6: '', cantFecha7: '' },
      { horario: '09:00 - 10:00', total: 0, cantFecha1: '', cantFecha2: '', cantFecha3: '', cantFecha4: '', cantFecha5: '', cantFecha6: '', cantFecha7: '' },
      { horario: '10:00 - 11:00', total: 0, cantFecha1: '', cantFecha2: '', cantFecha3: '', cantFecha4: '', cantFecha5: '', cantFecha6: '', cantFecha7: '' },
      { horario: '11:00 - 12:00', total: 0, cantFecha1: '', cantFecha2: '', cantFecha3: '', cantFecha4: '', cantFecha5: '', cantFecha6: '', cantFecha7: '' },
      { horario: '12:00 - 13:00', total: 0, cantFecha1: '', cantFecha2: '', cantFecha3: '', cantFecha4: '', cantFecha5: '', cantFecha6: '', cantFecha7: '' },
      { horario: '13:00 - 14:00', total: 0, cantFecha1: '', cantFecha2: '', cantFecha3: '', cantFecha4: '', cantFecha5: '', cantFecha6: '', cantFecha7: '' },
      { horario: '14:00 - 15:00', total: 0, cantFecha1: '', cantFecha2: '', cantFecha3: '', cantFecha4: '', cantFecha5: '', cantFecha6: '', cantFecha7: '' },
      { horario: '15:00 - 16:00', total: 0, cantFecha1: '', cantFecha2: '', cantFecha3: '', cantFecha4: '', cantFecha5: '', cantFecha6: '', cantFecha7: '' },
      { horario: '16:00 - 17:00', total: 0, cantFecha1: '', cantFecha2: '', cantFecha3: '', cantFecha4: '', cantFecha5: '', cantFecha6: '', cantFecha7: '' },
      { horario: '17:00 - 18:00', total: 0, cantFecha1: '', cantFecha2: '', cantFecha3: '', cantFecha4: '', cantFecha5: '', cantFecha6: '', cantFecha7: '' },
      { horario: '18:00 - 19:00', total: 0, cantFecha1: '', cantFecha2: '', cantFecha3: '', cantFecha4: '', cantFecha5: '', cantFecha6: '', cantFecha7: '' },
      { horario: '19:00 - 20:00', total: 0, cantFecha1: '', cantFecha2: '', cantFecha3: '', cantFecha4: '', cantFecha5: '', cantFecha6: '', cantFecha7: '' },
      { horario: '20:00 - 21:00', total: 0, cantFecha1: '', cantFecha2: '', cantFecha3: '', cantFecha4: '', cantFecha5: '', cantFecha6: '', cantFecha7: '' },
      { horario: '21:00 - 22:00', total: 0, cantFecha1: '', cantFecha2: '', cantFecha3: '', cantFecha4: '', cantFecha5: '', cantFecha6: '', cantFecha7: '' },
      { horario: '22:00 - 23:00', total: 0, cantFecha1: '', cantFecha2: '', cantFecha3: '', cantFecha4: '', cantFecha5: '', cantFecha6: '', cantFecha7: '' },
      { horario: '23:00 - 00:00', total: 0, cantFecha1: '', cantFecha2: '', cantFecha3: '', cantFecha4: '', cantFecha5: '', cantFecha6: '', cantFecha7: '' },
    ];

    listaTriage.forEach(triage => {
      
      //const partes = triage.horaIngreso.split(':');

    // Verificar si las horas están entre 00 y 00 y los minutos entre 00 y 59
      /*if (parseInt(partes[0]) === 0 && parseInt(partes[1]) >= 0 && parseInt(partes[1]) < 60) {
        console.log(triage.horaIngreso);
        console.log(triage.fechaIngreso);
      }*/
      this.arrayTriageDelDia[moment(triage.fechaIngreso +' '+triage.horaIngreso, 'DD/MM/YYYY HH:mm').hour()].total++;
      this.arrayTriageTotales[0].totalAbsoluto++;
      this.totalTriages++;
      switch (moment(triage.fechaIngreso, 'DD-MM-YYYY').format('DD/MM/YYYY')) {
        case this.fechasPacientes[0]:
          this.arrayTriageDelDia[moment(triage.fechaIngreso +' '+triage.horaIngreso, 'DD/MM/YYYY HH:mm').hour()].cantFecha1++;
          this.arrayTriageTotales[0].totalFecha1++;
          break;
        case this.fechasPacientes[1]:
          this.arrayTriageDelDia[moment(triage.fechaIngreso +' '+triage.horaIngreso, 'DD/MM/YYYY HH:mm').hour()].cantFecha2++;
          this.arrayTriageTotales[0].totalFecha2++;
          break;
        case this.fechasPacientes[2]:
          this.arrayTriageDelDia[moment(triage.fechaIngreso +' '+triage.horaIngreso, 'DD/MM/YYYY HH:mm').hour()].cantFecha3++
          this.arrayTriageTotales[0].totalFecha3++;
          break;
        case this.fechasPacientes[3]:
          this.arrayTriageDelDia[moment(triage.fechaIngreso +' '+triage.horaIngreso, 'DD/MM/YYYY HH:mm').hour()].cantFecha4++
          this.arrayTriageTotales[0].totalFecha4++;
          break;
        case this.fechasPacientes[4]:
          this.arrayTriageDelDia[moment(triage.fechaIngreso +' '+triage.horaIngreso, 'DD/MM/YYYY HH:mm').hour()].cantFecha5++;
          this.arrayTriageTotales[0].totalFecha5++;
          break;
        case this.fechasPacientes[5]:
          this.arrayTriageDelDia[moment(triage.fechaIngreso +' '+triage.horaIngreso, 'DD/MM/YYYY HH:mm').hour()].cantFecha6++;
          this.arrayTriageTotales[0].totalFecha6++
          break;
        case this.fechasPacientes[6]:
          this.arrayTriageDelDia[moment(triage.fechaIngreso +' '+triage.horaIngreso, 'DD/MM/YYYY HH:mm').hour()].cantFecha7++
          this.arrayTriageTotales[0].totalFecha7++;
          break;
        default:
          break;
      }
    });
    this.promedio = Number(((this.arrayTriageTotales[0].totalAbsoluto) / 7).toFixed(2));
  }

  async getTriagesDelDia(fechaDesde, fechaHasta) {
    this.cargando = true;
    this.errorGetTriage = false;
    this.noExistenPacientes = false;
    
    this.totalTriages = 0;
    this.totalTriageProf = 0;

    let jsonTriage = {
      paciente: { },
      fechaDesde: fechaDesde,
      fechaHasta: fechaHasta
    }

    await this.urlResolverService.getHorusRestfullUrl().toPromise().then(async respGetUrl => {
      // console.log(respGetUrl);
      let startTime = performance.now();
      await this.tableroService.getTriagePorFecha(respGetUrl, jsonTriage).toPromise().then(async resp => {
        //console.log(resp);
        let endTime = performance.now()
        let tiempo = endTime - startTime
        console.log('La ejecución tardó ' + tiempo + ' miliseconds')
        if (resp.ok && resp.data.length > 0) { 
          this.errorGetTriage = false;
          await this.contarTriage(resp.data).then(()  => {
            this.cargando = false;
            this.cargandoTotal = false;
            });
        } else {
          this.noExistenPacientes = true;
          this.cargando = false;
        }
      }, error => {
        console.log(error);
        this.errorGetTriage = true;
        this.cargando = false;
      })
    }, error => {
      console.log(error);
      this.errorGetTriage = true;
      this.cargando = false;
    })
  }

  calcularFechas(event: MatDatepickerInputEvent<Date> = null) {
    //Devuelve un arreglo con las fechas a evaluar
    this.fechasPacientes = []
    var i: number;
    if (event != null) {
      for (i=0 ; i <= 6 ; i++) {
        var aux = moment(event.value).subtract(6, "days")
        this.fechasPacientes[i] = aux.add(i, "days").format("DD/MM/YYYY")
      }
    } else {
      for (i=0 ; i <= 6 ; i++) {
        var aux = moment().subtract(7 - i, 'days')
        this.fechasPacientes[i] = aux.format("DD/MM/YYYY")
      }
    }
  }

  cambioFechaDesde(event: MatDatepickerInputEvent<Date>) {
    //calcula la fecha desde con la fecha recibida y devuelve las triages atendidas entre estas
    var startDate = moment(event.value)
    startDate = startDate.subtract(6, "days")
    this.calcularFechas(event);
    this.getTriagesDelDia(startDate.startOf('day').format("DD-MM-YY HH:mm:ss"), moment(event.value).endOf('day').format("DD-MM-YYYY HH:mm:ss"));   
  }
}




  