<!-- <div class="container-fluid">

    <div class="container">

        <h2 style="text-align: left; padding-top: 2%;">Acceder a Intranet</h2>

        <form id="loginForm" [formGroup]="loginForm" (ngSubmit)="onSubmit()">

            <mat-form-field style="border: 0px solid red; font-size: 18px; width: 60%;">
                <mat-label>Usuario</mat-label>
                <input matInput formControlName="username">
            </mat-form-field><br>

            <mat-form-field style="border: 0px solid red; font-size: 18px; width: 60%;">
                <mat-label>Contrase&ntilde;a</mat-label>
                <input matInput type="password" formControlName="password">
            </mat-form-field>
            
            <div *ngIf="msje != null">
                <p style="color: red">{{msje}}</p>
            </div>
            
            <div>
                <button mat-raised-button color="primary">Iniciar Sesi&oacute;n</button>
                <mat-spinner diameter="22" [hidden]="!loading" style="display: inline-block; margin-left: 5%; margin-top: 1%;"></mat-spinner>
                <br><br>
                <a href="http://redmine/redmine/login?back_url=http%3A%2F%2Fredmine%2Fredmine%2Fprojects%2Fincidencias%2Fissues%2Fnew" target="_blank">
                    Env&iacute;ar Mensaje a Soporte</a>
            </div>
        </form>

    </div>

</div> -->

<div class="container-fluid center-block">
  <br />

  <mat-card style="text-align: center" style="padding: 16px">
    <mat-card-title
      class="mat-card-title"
      style="color: #0d3453; font-family: Arial, Helvetica; text-align: center"
      ><img
        src="../../assets/images/login/logo HE.png"
        alt="hospital_logo"
        style="width: 55px; height: 45px; margin: auto !important"
      />
      <span class="align-middle ml-3">intranet</span></mat-card-title
    >
    <form [formGroup]="loginForm"(keydown.enter)="$event.preventDefault()">
      <mat-card-content>
        <div class="alert alert-danger p-1 m-2" *ngIf="msje != null">
          {{ msje }}
        </div>
        <br />

        <mat-form-field>
          <input
            matInput
            type="text"
            formControlName="username"
            [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
            (keyup) = "convertirEnMinusculas()"
            placeholder="Usuario"
            required
          />
        </mat-form-field>

        <div
          *ngIf="submitted && f.username.errors"
          class="alert alert-danger p-1 m-1"
        >
          <div *ngIf="f.username.errors.required">El usuario es requerido</div>
        </div>
        <mat-form-field>
          <!-- <input
            matInput
            type="password"
            formControlName="password"
            [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
            placeholder="Contraseña"
            required
          /> -->
          <input (keyup.enter)="onSubmit()" matInput [type]=" showPass ? 'text' : 'password' " formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="Contraseña" required />
          <button matTooltip="{{showPass ? 'Ocultar contraseña' : 'Mostrar contraseña'}}"
                  matSuffix
                  (click)="showHidePassword()"
                  color="primary"
                  mat-icon-button>
          <mat-icon>{{showPass ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
        <div
          *ngIf="submitted && f.password.errors"
          class="alert alert-danger p-1 m-1"
        >
          <div *ngIf="f.password.errors.required">
            La contraseña es requerida
          </div>
        </div>
        <div id="mayus" style="display:none" class="alert alert-danger m-1">
      
          Las mayúsculas están activadas.
       
        </div >
      </mat-card-content>

      <mat-card-actions>
        <button
          [disabled]="loading"
          type="button"
          class="btn btn-lg btn-outline-info"
          type="submit"
          (click)="onSubmit()"
        >
          iniciar sesión
        </button>
        <mat-progress-bar
          mode="indeterminate"
          *ngIf="loading"
        ></mat-progress-bar>
      </mat-card-actions>
    </form>

    <div class="container">
      <div class="row">
        <div class="col align-self-center" style="text-align: center;">
            <a href="javascript:void(0);" (click)="recuperarClave()">
              <img src="./../../assets/images/login/OlvideMisDatos.png" class="" style="cursor: pointer; width: 40px;"/>
              <p style="text-align: center; line-height: 100%;font-size: 12px;">Recuperar clave</p>
            </a>
        </div>
        <div class="col align-self-center" style="text-align: center;">
          <a href="#SoporteTecnicoModal" data-toggle="modal" data-target="#SoporteTecnicoModal">
            <img src="./../../assets/images/login/soporteTecnico.png" class="" style="cursor: pointer; width: 40px;"/>
            <p style="text-align: center; line-height: 100%;font-size: 12px;">Soporte técnico</p>
          </a>
        </div>
      </div>
    </div>

    <div class="container-imagenes" *ngIf="!loading else cargando2">
      <img
        style="margin-right: 16px"
        style="margin-right: 10%"
        id="efector-logo"
        src="../../assets/images/login/logo_efector.jpg"
      />
      <a href="https://cidcam.org"
        ><img
          id="cidam-logo"
          style="margin-right: 9%"
          src="../../assets/images/login/logo_cidcam.jpg"
        />
      </a>
      <a href="http://www.cenas.org.ar/">
        <img id="cenas-logo" src="../../assets/images/login/logo_cenas.jpg" />
      </a>
    </div>
    <ng-template #cargando2>
      <div class="container-imagenes">
        <img
        style="margin-right: 16px"
        style="margin-right: 10%"
        id="efector-logo"
        src="../../assets/images/login/logo_efector.jpg"
        />
        <img
          id="cidam-logo"
          style="margin-right: 9%"
          src="../../assets/images/login/logo_cidcam.jpg"
        />
        <img id="cenas-logo" src="../../assets/images/login/logo_cenas.jpg" />
      </div>
    </ng-template>
  </mat-card>
</div>

<!-- Modal Soporte Tecnico -->

<div
  class="modal fade"
  id="SoporteTecnicoModal"
  data-backdrop="false"
  data-dismiss="modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="SoporteTecnicoModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="SoporteTecnicoModalLabel">
          Contáctese con soporte técnico
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!--<p>Complete el formulario por dudas,
			consultas, problemas relacionados al Sitio Intranet.</p>-->
        <form [formGroup]="supportMessageForm" (ngSubmit)="supportMessage()">
          <div class="form-group">
            <label for="nombreApellido">Nombre y apellido *</label>
            <input
              type="text"
              formControlName="nombreApellido"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  supportMessageSubmitted && sp.nombreApellido.errors
              }"
            />
            <div
              *ngIf="supportMessageSubmitted && sp.nombreApellido.errors"
              class="invalid-feedback"
            >
              <div *ngIf="sp.nombreApellido.errors.required">
                Este campo es requerido
              </div>
              <div *ngIf="sp.nombreApellido.errors.pattern">Solo letras</div>
            </div>
          </div>
          <div class="form-group">
            <label for="email">Email *</label>
            <input
              type="text"
              formControlName="email"
              class="form-control"
              [ngClass]="{
                'is-invalid': supportMessageSubmitted && sp.email.errors
              }"
            />
            <div
              *ngIf="supportMessageSubmitted && sp.email.errors"
              class="invalid-feedback"
            >
              <div *ngIf="sp.email.errors.required">
                Este campo es requerido
              </div>
              <div *ngIf="sp.email.errors.pattern">
                Este campo debe ser mail
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="asunto">Asunto *</label>
            <input
              type="text"
              formControlName="asunto"
              class="form-control"
              [ngClass]="{
                'is-invalid': supportMessageSubmitted && sp.asunto.errors
              }"
            />
            <div
              *ngIf="supportMessageSubmitted && sp.asunto.errors"
              class="invalid-feedback"
            >
              <div *ngIf="sp.asunto.errors.required">Este campo es requerido</div>
            </div>
          </div>
          <div class="form-group">
            <label for="mensaje">Mensaje *</label>
            <input
              type="text"
              formControlName="mensaje"
              class="form-control"
              [ngClass]="{
                'is-invalid': supportMessageSubmitted && sp.mensaje.errors
              }"
            />
            <div
              *ngIf="supportMessageSubmitted && sp.mensaje.errors"
              class="invalid-feedback"
            >
              <div *ngIf="sp.mensaje.errors.required">Este campo es requerido</div>
            </div>
          </div>
            <!-- agregar Telefono de Contacto-->
            <div class="form-group"> 
              <label for="telefonoContacto">Telefono de Contacto *</label>
              <input
                type="text"
                formControlName="telefonoContacto"
                class="form-control"
                [ngClass]="{
                  'is-invalid': supportMessageSubmitted && sp.telefonoContacto.errors
                }"
              />
              <div
                *ngIf="supportMessageSubmitted && sp.telefonoContacto.errors"
                class="invalid-feedback"
              >
              
                <div *ngIf="sp.telefonoContacto.errors.required">Este campo es requerido</div>
                <div *ngIf="sp.telefonoContacto.errors.pattern">Solo numeros</div>
              
             
            </div>

            
          </div>

          <button type="submit" 
            mat-raised-button color="primary"
            [disabled]="supportMessageLoading"
            [hidden]="supportMessageSent"
            style="float: right;width:fit-content ;"
          >
            Enviar
          </button>
          <img
            *ngIf="supportMessageLoading"
            class="img-fluid"
            style="max-width: 4%; margin-left: 5%"
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
          />
        </form>
      </div>
    </div>
  </div>
</div>
