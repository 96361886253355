<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <br />
      <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

      <div *ngIf="dataSource">
        <h2>MIS RECIBOS</h2>
        <button
          id="botonVolver"
          mat-raised-button
          (click)="irAtras()"
          *ngIf="verPdf"
        >
          Volver
        </button>

        <mat-form-field *ngIf="!verPdf">
          <mat-select placeholder="A&ntilde;o" class="select-anio">
            <mat-option
              *ngFor="let item of filtroAniosSinRepetir"
              (click)="applyFilter(item)"
              >{{ item }}</mat-option
            >
            <mat-option (click)="applyFilter(0)">Todos</mat-option>
          </mat-select>
        </mat-form-field>

        <table
          mat-table
          *ngIf="!verPdf"
          [dataSource]="dataSource"
          matSort
          class="mat-elevation-z3"
        >
          <ng-container matColumnDef="mes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Mes</th>
            <td mat-cell *matCellDef="let element">
              {{ meses[element.mes - 1] }}
            </td>
          </ng-container>

          <ng-container matColumnDef="anio">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              A&ntilde;o
            </th>
            <td mat-cell *matCellDef="let element">{{ element.anio }}</td>
          </ng-container>

          <ng-container matColumnDef="liquidacion">
            <th mat-header-cell *matHeaderCellDef>Liquidaci&oacute;n</th>
            <td mat-cell *matCellDef="let element">
              {{ element.descripcionLiq }}
            </td>
          </ng-container>
          <ng-container matColumnDef="icon">
            <th mat-header-cell *matHeaderCellDef style="text-align: center">
              PDF
            </th>
            <td mat-cell class="icon" *matCellDef="let element">
              <!-- <a target="_blank"
                  href="{{getFiles}}{{element.ubicacion}}{{element.nombrePdf}}"
                  class="link-pdf" matTooltip="Ver PDF" (click)="guardarDatosUsuario(element.nombrePdf)">
                  <mat-icon fontSet="material-icons-outlined">picture_as_pdf</mat-icon>
                </a> -->
              <mat-icon
                style="cursor: pointer; color: rgb(13, 52, 80)"
                (click)="guardarDatosUsuario(element)"
                >picture_as_pdf</mat-icon
              >
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <mat-card class="mensajeCard" *ngIf="mensajeError">
        <mat-card-content>
          <mat-icon class="iconError">error_outline</mat-icon>
          <p>No se pudo obtener los Recibos.</p>
        </mat-card-content>
      </mat-card>

      <br /><br /><br />
      <div class="container" *ngIf="verPdf">
        <object
          id="desktop"
          *ngIf="!esIE"
          type="application/pdf"
          [data]="urlPdf | safeUrl"
          width="100%"
          height="500"
        >
        <p><a target="_blank" [href]="urlPdf">Haga click aquí para descargar el recibo.</a></p></object>
        <iframe
          *ngIf="esIE"
          type="application/pdf"
          [src]="urlPdf | safeUrl"
          width="100%"
          height="500"
        ></iframe>
      </div>
    </div>
  </div>
</div>
