<div class="container-fluid">
    <mat-card class="mat-card mat-focus-indicator example-card" style="margin-top: 1%;">
    <div class="row" id="rowBack" style="border: 0px solid red;">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <span style="float: left; margin-right: 5%;">
            <button mat-icon-button color="primary" (click)="volver()">
              <mat-icon aria-label="Example icon-button with a heart icon">keyboard_backspace</mat-icon> Volver
            </button>
          </span>
        </div>
        <br>
    </div>
    <mat-card-title style="text-align: center;">Editar notificacion</mat-card-title>
    
    <div class="col" style="margin-top: 1%;">
      <div style="margin-left: 0.5%;"><strong>Motivo: </strong></div>
      <mat-form-field appearance="outline" style="margin-left: 0.5%;">
          <input matInput [value]="notificacion.motivo" disabled>
      </mat-form-field>
  </div>
  
  <div class="col">
    <div style="margin-left: 0.5%;"><strong>Sector turno: </strong></div>
    <mat-form-field  appearance="outline" style="margin-left: 0.5%;">
        <input matInput [value]="this.notificacion.sectorTurno" disabled>
    </mat-form-field>
  </div>

  <form [formGroup]="notificacionesForm">

    <div class="col">
      <div style="margin-left: 0.5%;"><strong>Asunto: </strong></div>
      <mat-form-field appearance="outline" style="margin-left: 0.5%; width: 30%;">
        <textarea matInput formControlName="asunto" rows="3"></textarea>
      </mat-form-field>
    </div>
    

    <!-- <div style="margin-left: 1.5%;"><strong>Asunto: </strong></div>
    <div class="row" style="width: fit-content; margin: 0.5%;">
      <div class="col">
      <ckeditor [editor]="editor" formControlName="asunto" [data]="this.notificacion.asunto" (ready)="onReady($event)"
      [config]="{
        toolbar: [
          'heading',
          '|',
          'fontsize',
          'fontfamily',
          '|',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'highlight',
          '|',
          'alignment',
          '|',
          'numberedlist',
          'bulletedlist',
          '|',
          'link',
          'blockquote',
          'inserttable',
          '|',
          'undo',
          'redo'
        ]
      }"></ckeditor>
      </div>
    </div> -->

  <div style="margin-left: 1.5%; margin-top: 1%;"><strong>Contenido adicional: </strong></div>

    
    <div class="row" style="width: fit-content; margin: 0.5%;">
      <div class="col">
      <ckeditor [editor]="editor" formControlName="contAdicional" [data]="this.notificacion.contAdicional" (ready)="onReady($event)"
      [config]="{
        toolbar: [
          'heading',
          '|',
          'fontsize',
          'fontfamily',
          '|',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'highlight',
          '|',
          'alignment',
          '|',
          'numberedlist',
          'bulletedlist',
          '|',
          'link',
          'blockquote',
          'inserttable',
          '|',
          'undo',
          'redo'
        ]
      }"></ckeditor>
      </div>
  </div>
  <br>
    <div class="row" >
      <div class="col" style="text-align: center;">
          <button mat-raised-button class="btn-block mt-2" style="width: 250px; margin-bottom: 1.5%;" [hidden]="loading" [disabled]="!notificacionesForm.dirty" (click)="onSubmitForm()" color="primary">
               Guardar
          </button>

      <mat-spinner
                diameter="22"
                [hidden]="!loading"
                style="display: inline-block; margin-top: 1%"
      ></mat-spinner>
    </div>
  </div>
  </form>

    
      
    </mat-card>     
    <br> 
</div>