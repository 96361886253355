import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import {
  DialogMensajeComponent,
  RespuestaCarga,
} from '../dialog-mensaje/dialog-mensaje.component';
import { Router } from '@angular/router';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import { ComunicacionService } from '../services/comunicacion.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Noticia } from '../mostrar-noticias/mostrar-noticias.component';
import { SafeHTMLPipe } from '../pipes/safe-html.pipe';

@Component({
  selector: 'app-dialog-crear-novedad',
  templateUrl: './dialog-crear-novedad.component.html',
  styleUrls: ['./dialog-crear-novedad.component.css'],
  providers: [DatePipe],
})
export class DialogCrearNovedadComponent implements OnInit {
  fecha_min_fp: Date;
  fecha_min_ff: Date;
  novedadesForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  private usuarioLog: string;
  edit = false;

  addEvent(event: MatDatepickerInputEvent<Date>) {
    this.fecha_min_ff = new Date(
      event.value.getFullYear(),
      event.value.getMonth(),
      event.value.getDate() + 1
    );
  }

  constructor(
    public dialogo: MatDialogRef<DialogCrearNovedadComponent>,
    public dialog: MatDialog,
    private router: Router,
    private comunicacionService: ComunicacionService,
    private form: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public novedad: Noticia
  ) {
    this.usuarioLog = JSON.parse(sessionStorage.getItem('currentUser')).usuario;
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDay = new Date().getDate();
    if (novedad != null) {
      this.edit = true;
      this.fecha_min_fp = new Date(novedad.fechaPublicacion);
      this.fecha_min_ff = new Date(currentYear, currentMonth, currentDay + 1);
    } else {
      this.fecha_min_fp = new Date(currentYear, currentMonth, currentDay);
    }
  }

  ngOnInit() {
    this.buildForm();
  }
  private buildForm() {
    if (!this.edit) {
      this.novedadesForm = this.form.group({
        texto: [, Validators.required],
        fecha_publicacion: [, Validators.required],
        // Nuevo dato fecha de fin de publicacion
        fecha_fin: [],
      });
    } else {
      this.novedadesForm = this.form.group({
        texto: [
          decodeURIComponent(escape(atob(this.novedad.texto))),
          Validators.required,
        ],
        fecha_publicacion: [
          new Date(this.novedad.fechaPublicacion),
          Validators.required,
        ],
        // Nuevo dato fecha de fin de publicacion
        fecha_fin: [new Date(this.novedad.fechaFinPublicacion)],
      });
    }
  }
  openDialog(respuesta: RespuestaCarga): void {
    const dialogRef = this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: respuesta,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (respuesta.ok) {
        this.dialog.closeAll();
        window.location.reload();
      }
    });
  }
  public cancelar() {
    this.dialog
      .open(DialogConfirmacionComponent, {
        data: `¿Seguro desea cancelar la creación de la noticia?`,
      })
      .afterClosed()
      .subscribe((confirmado: boolean) => {
        if (confirmado) {
          this.dialog.closeAll();
        }
      });
  }
  onSubmit() {
    this.submitted = true;
    if (this.novedadesForm.valid) {
      this.loading = true;
      const dateSendingToServerFP = new DatePipe('en-US').transform(
        this.novedadesForm.value.fecha_publicacion,
        'medium'
      );
      const dateSendingToServerFF = new DatePipe('en-US').transform(
        this.novedadesForm.value.fecha_fin,
        'medium'
      );
      if (!this.edit) {
        this.comunicacionService
          .setNovedad(
            this.usuarioLog,
            btoa(unescape(encodeURIComponent(this.novedadesForm.value.texto))),
            dateSendingToServerFP,
            dateSendingToServerFF
          )
          .subscribe(
            (res) => {
              this.loading = false;
              this.openDialog(res);
            },
            (error) => {
              console.log('error' + error);
            }
          );
      } else {
        this.comunicacionService
          .editarNoticia(
            this.novedad.idComunicacion,
            this.usuarioLog,
            null,
            btoa(unescape(encodeURIComponent(this.novedadesForm.value.texto))),
            null,
            null,
            dateSendingToServerFP,
            null,
            null,
            null,
            dateSendingToServerFF
          )
          .subscribe(
            (res) => {
              this.loading = false;
              this.openDialog(res);
            },
            (error) => {
              console.log('error' + error);
            }
          );
      }
    }
  }
}
