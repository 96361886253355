<div class="container-fluid">
    <div class="col-sm-12 py-1 my-1">
      <div class="container py-0 my-0">
        <mat-card class="py-1 my-1" style="text-align: center">
          <div *ngIf="seBuscaModificar;else elseBlock">
            <mat-card-title class="py-0 my-0" *ngIf="seBuscaModificar; else elseBlock">Modificar Evento</mat-card-title>
          </div>
          <ng-template #elseBlock>
            <mat-card-title #elseBlock class="py-0 my-0">Agregar Evento</mat-card-title>
          </ng-template>
        

          <form
            class="ui form"
            [formGroup]="eventoForm"
            enctype="multipart/form-data"
          >
            <mat-card-content>
                <div class="container-fluid">
                    <div class="row" *ngIf="!seBuscaModificar;else templateModificar">
                      <!-- COLUMNA DE DESCRIPCION -->
                      <div
                      class="col-xs-3">
                        <mat-form-field>
                            <input
                            matInput
                            formControlName="descripcion"
                            placeholder="Descripción"
                            required
                            />
                        </mat-form-field>
                        <div
                        class="alert alert-danger p-1 m-1"
                        *ngIf="eventoForm.get('descripcion').errors && eventoForm.get('descripcion').touched"
                        >
                            <p *ngIf="eventoForm.get('descripcion').hasError('required')">
                            Debe especificiar una descripción
                            </p>
                        </div>
                      </div>
                                      
                      <!-- COLUMNA DE TIPO EVENTO -->
                      <div
                        class="col-xs-3"
                      >
                        <mat-form-field>
                            <mat-select
                            formControlName="tipo"                      
                            placeholder="Tipo"
                            required
                            >
                            <mat-option
                                *ngFor="let tipo of listaTipoEvento"
                                [value]="tipo"
                                >{{ tipo.descripcion }}
                            </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div
                            class="alert alert-danger p-1 m-1"
                            *ngIf="
                            eventoForm.get('tipo').errors &&
                            eventoForm.get('tipo').touched
                            "
                        >
                            <p
                            *ngIf="
                                eventoForm.get('tipo').hasError('required')
                            "
                            >
                            Debe especificar el tipo de evento
                            </p>
                        </div>
                      </div>

                      <!-- COLUMNA DE SECTOR -->
                      <div
                      class="col-xs-3"
                      >
                      <mat-form-field>
                        <mat-select
                        formControlName="sector"                      
                        placeholder="Sector"
                        required
                        >
                        <mat-option
                            *ngFor="let sector of listaSectores"
                            [value]="sector"
                            >{{ sector.descripcion }}
                        </mat-option>
                        </mat-select>
                    </mat-form-field>
                        <div
                            class="alert alert-danger p-1 m-1"
                            *ngIf="
                            eventoForm.get('sector').errors &&
                            eventoForm.get('sector').touched
                            "
                        >
                            <p *ngIf="eventoForm.get('sector').hasError('required')">
                            Debe especificiar el sector
                            </p>
                        </div>
                      </div>
      
                      <!-- COLUMNA DE FECHA -->
                      <!-- <div
                      class="form-group col-xs-4 col-sm-4 col-md-4 col-lg-3 col-xl-4"
                      > -->
                      <div
                        class="col-xs-3"
                      >
                      <mat-form-field>
                          <mat-label>Fecha</mat-label>
                          <input
                          matInput
                          [matDatepicker]="fecha"
                          formControlName="fecha"
                          required
                          />
                          <mat-datepicker-toggle
                          matSuffix
                          [for]="fecha"
                          ></mat-datepicker-toggle>
                          <mat-datepicker #fecha></mat-datepicker>
                          <!-- <input
                              matInput
                              type="date"
                              formControlName="fecha_publicacion"
                              placeholder="Fecha de publicacion"
                              required
                          /> -->
                      </mat-form-field>
                      <div
                          class="alert alert-danger p-1 m-1"
                          *ngIf="
                          eventoForm.get('fecha').errors &&
                          eventoForm.get('fecha').touched
                          "
                      >
                          <p
                          *ngIf="
                              eventoForm.get('fecha').hasError('required')"
                          >
                          Debe especificar la fecha del evento
                          </p>
                      </div>
                      </div>
                  </div>

                  <ng-template #templateModificar>
                    <div class="row">
                      <!-- COLUMNA DE DESCRIPCION -->
                      <div
                      class="col-xs-3">
                        <mat-form-field>
                            <input
                            matInput
                            formControlName="descripcion"
                            placeholder="Descripcion"
                            [value] = "descripcion"                            
                            />
                        </mat-form-field> 
                        <div
                        class="alert alert-danger p-1 m-1"
                        *ngIf="eventoForm.get('descripcion').errors && eventoForm.get('descripcion').touched"
                        >
                            <p *ngIf="eventoForm.get('descripcion').hasError('required')">
                              Debe especificiar una descripción
                            </p>
                        </div>                       
                      </div>
                      
                                      
                      <!-- COLUMNA DE TIPO EVENTO -->
                      <div
                        class="col-xs-3"
                      >
                        <mat-form-field>
                              <mat-select placeholder="{{ eventoForm.get('tipo').value.descripcion }}" formControlName="tipo">
                                <mat-option *ngFor="let t of listaTipoEvento" [value]="t">
                                  {{ t.descripcion }}
                                </mat-option>
                              </mat-select>                                         
                        </mat-form-field>
                        <div
                            class="alert alert-danger p-1 m-1"
                            *ngIf="
                            eventoForm.get('tipo').errors &&
                            eventoForm.get('tipo').touched
                            "
                        >
                            <p
                            *ngIf="
                                eventoForm.get('tipo').hasError('required')
                            "
                            >
                            Debe especificar el tipo de evento
                            </p>
                        </div>                        
                      </div>

                      <!-- COLUMNA DE SECTOR -->
                      <div
                      class="col-xs-3"
                      >
                      <mat-form-field>
                          <mat-select
                          formControlName="sector"                      
                          placeholder="{{ eventoForm.get('sector').value.descripcion }}"
                          required
                          >
                          <mat-option
                              *ngFor="let sector of listaSectores"
                              [value]="sector"
                              >{{ sector.descripcion }}
                          </mat-option>
                          </mat-select>
                      </mat-form-field> 
                        <div
                            class="alert alert-danger p-1 m-1"
                            *ngIf="
                            eventoForm.get('sector').errors &&
                            eventoForm.get('sector').touched
                            "
                        >
                            <p *ngIf="eventoForm.get('sector').hasError('required')">
                            Debe especificiar el sector
                            </p>
                        </div>                       
                      </div>
      
                      <!-- COLUMNA DE FECHA -->                     
                      <div
                        class="col-xs-3"
                      >
                      <mat-form-field>
                          <input matInput [matDatepicker]="fecha" formControlName="fecha"/>
                          <mat-datepicker-toggle matSuffix [for]="fecha"></mat-datepicker-toggle>
                          <mat-datepicker #fecha></mat-datepicker>                          
                      </mat-form-field>
                        <div
                        class="alert alert-danger p-1 m-1"
                        *ngIf="
                        eventoForm.get('fecha').errors &&
                        eventoForm.get('fecha').touched
                        "
                        >
                            <p
                            *ngIf="
                                eventoForm.get('fecha').hasError('required')"
                            >
                            Debe especificar la fecha del evento
                            </p>
                        </div>                    
                      </div>
                    </div>
                </ng-template>


                </div>              
            </mat-card-content>
  
            <mat-card-actions class="p-0 m-0">
              <div>
                <button
                  mat-raised-button
                  color="warn"
                  type="button"
                  class="btn btn-primary btn"
                  style="margin: 2px"
                  (click)="cancelar()">
                  Cancelar
                </button>
                <button *ngIf="seBuscaModificar;else botonAgregar"
                  mat-raised-button
                  color="primary"
                  [disabled]="eventoForm.invalid || loadingSubmit"
                  style="margin: 2px"
                  type="submit"
                  (click)="onModificar()">
                  Modificar
                </button>
                <ng-template #botonAgregar>
                  <button 
                  mat-raised-button
                  color="primary"
                  [disabled]="eventoForm.invalid || loadingSubmit"
                  style="margin: 2px"
                  type="submit"
                  (click)="onSubmit()">
                    Cargar evento                    
                  </button>
                </ng-template>                
                <mat-spinner
                  diameter="22"
                  [hidden]="!loadingSubmit"
                  style="display: inline-block; margin-left: 5%; margin-top: 1%">
                </mat-spinner>
              </div>
            </mat-card-actions>
          </form>
        </mat-card>
      </div>
    </div>
  </div>
  
