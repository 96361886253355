import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { MatSidenav } from '@angular/material/sidenav';
import { EmbedUrlsService } from '../services/embedUrls.service';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-accordion',
  templateUrl: './menu-accordion.component.html',
  styleUrls: ['./menu-accordion.component.css'],
})
export class MenuAccordionComponent implements OnInit {
  @Input() inputSideNav: MatSidenav;

  usuarioLog: any;
  permitido: boolean;
  publicandoRecibos: boolean;
  mensaje: string;
  respuesta: boolean;
  roles;
  perfiles;
  funciones = [];
  esDirectivo: boolean;
  grouped: any[];

  constructor(
    private authenticationService: AuthenticationService,
    private embedUrls: EmbedUrlsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.permitido = true;
    this.esDirectivo = true;

    this.obtenerNombreUsuario();
  }

  obtenerNombreUsuario() {
    this.authenticationService.currentUser.subscribe(
      (resp) => {
        this.funciones = [];
        if (resp) {
          this.usuarioLog = resp;
          this.roles = this.usuarioLog.roles;
          this.roles.forEach((rol) => {
            this.perfiles = rol.perfiles;
            this.perfiles.forEach((perfil) => {
              this.funciones.push.apply(this.funciones, perfil.funciones);
            });
          });
          this.funciones = this.funciones.filter(
            (funcion, index, self) =>
              index ===
              self.findIndex(
                (f) =>
                  f.url === funcion.url &&
                  f.nombreMenu == funcion.nombreMenu &&
                  f.nombreSubMenu == funcion.nombreSubMenu
              )
          );
          this.embedUrls.setUrls(this.funciones);

          this.grouped = this.groupBy(this.funciones, function (item) {
            return [item.nombreMenu];
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  groupBy(list, fn) {
    var groups = {};
    for (var i = 0; i < list.length; i++) {
      var group = JSON.stringify(fn(list[i]));
      if (group in groups) {
        groups[group].push(list[i]);
      } else {
        groups[group] = [list[i]];
      }
    }
    return this.arrayFromObject(groups);
  }

  navigate(sector: String){
    this.inputSideNav.toggle(),
    this.router.navigate(['/organigrama-sector', sector])
  }

  arrayFromObject(obj) {
    var arr = [];
    for (var i in obj) {
      arr.push(obj[i]);
    }
    return arr;
  }
}
