<div *ngIf = "!hayNoticias; else cargado">
  <h6>Se está cargando la información, aguarde un momento</h6>

  <mat-spinner [diameter]="40"></mat-spinner>
  
</div>
<ng-template #cargado>
<mat-card class="py-1 my-1">
    <mat-card-title style="text-align:center ;">Noticias</mat-card-title>

<!-- Buscador -->
<div class="containerinfo">
  <mat-form-field>
  <mat-label >Buscar</mat-label>
    <input matInput placeholder="Buscar noticia" (keyup)="buscarNoticia($event)">
  </mat-form-field>
  <div class="infotv alert alert-warning" role="alert">
    <p><b>Las noticias de Tv Turnos se modifican desde la sección Agregar Noticias, seleccionando dicho sitio</b></p>
  </div>
</div>
<!-- Tabla -->

<table mat-table [dataSource]="dataSourceItems" class="mat-elevation-z8" style="align-items: center;">
    <div class="row">
    <!-- Titulo -->
    <div style="text-align: center; margin-right: 1px">
    <ng-container matColumnDef="Titulo">
      <th mat-header-cell *matHeaderCellDef> Titulo</th>
      <td mat-cell *matCellDef="let element"> <strong>{{element.titulo}}</strong> </td>
    </ng-container>
    </div>
    <!-- Descripcion -->
    <div style="text-align: center; margin-right: 1px">
        <ng-container matColumnDef="Descripcion">
        <th mat-header-cell *matHeaderCellDef> Descripcion </th>
        <td mat-cell *matCellDef="let element"> <strong><p class="contenido"  [innerHTML]="element.texto.slice(0,20) | safeHTML"></p> </strong></td>
      </ng-container> 
    </div>
    <!-- Nombre Imagen-->
    <div style="text-align: center; margin-right: 1px">
      <ng-container matColumnDef="NombreImagen">
        <th mat-header-cell *matHeaderCellDef> Nombre imagen </th>
        <td mat-cell *matCellDef="let element"> <strong>{{element.nombreImagen}}</strong> </td>
      </ng-container> 
      </div>
    <!-- Sitios -->
    <div style="text-align: center; margin-right: 1px">
    <ng-container matColumnDef="Sitios">
      <th mat-header-cell *matHeaderCellDef> Sitios </th>
      <td mat-cell *matCellDef="let element"><p *ngFor="let sitio of element.sitiosPublicacion;let i = index"  [attr.data-index]="i" ><strong>{{sitio.nombre}}</strong></p></td>
    </ng-container>
    </div>
    <!-- Fecha Publicacion -->
    <div style="text-align: center; margin-right: 1px">
    <ng-container matColumnDef="FechaPublicacion">
      <th mat-header-cell *matHeaderCellDef> Fecha Publicacion </th>
      <td mat-cell *matCellDef="let element" > 
         <div *ngIf="compararFechaPublicacion(element); else sincolor"style="color: red;"> 
          <p>{{element.st_fechaPublicacion}}</p>
        </div> 
         <ng-template #sincolor>
          <p>{{element.st_fechaPublicacion}}</p>
        </ng-template>            
      </td>
    </ng-container>
    </div>
     <!-- Fecha Fin -->
   <div style="text-align: center; margin-right: 1px">
    <ng-container matColumnDef="FechaFin">
       <th mat-header-cell *matHeaderCellDef> Fecha Fin </th>
       <td mat-cell *matCellDef="let element"> <strong>{{element.fechaFin}}</strong></td>
     </ng-container>
     </div>
   <!-- Acciones -->
   <div style="text-align: center; margin-right: 1px">
   <ng-container matColumnDef="Acciones">
    <th mat-header-cell *matHeaderCellDef> Acciones </th>
    <td mat-cell *matCellDef="let element"> 
    <!-- Boton editar notificacion -->
    <button
      matTooltip="Editar notificacion"
      (click)="modificarNoticia(element.idComunicacion)"
      color="primary"
      mat-icon-button
      >
      <mat-icon>edit</mat-icon>  
     </button>
    </td>
  </ng-container>
  </div>
  </div>
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" ></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  
  <mat-paginator [pageSizeOptions]="[5]" aria-label="Seleccionar pagina" ></mat-paginator>

  <br>
</mat-card>
</ng-template>