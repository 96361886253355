<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12" [hidden]="!loading" style="padding-top: 1%;">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>

        <div class="col-sm-12" [hidden]="loading" style="padding-top: 1%;">
            
            <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header [hidden]="mensajeError">
                  <mat-panel-title>
                    Tecnología de la Información
                  </mat-panel-title>
                  <mat-panel-description>
                    Organigrama
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <!-- <h2 [hidden]="mensajeError">{{ sector == 'TI' ? 'Tecnología de la Información' : 'sector' }}</h2> -->
            
                <figure style="overflow-x: auto; overflow-y: hidden;">
                <p-organizationChart [value]="data" selectionMode="single" [(selection)]="selectedNodes" [preserveSpace]="false" 
                    (onNodeSelect)="abrirCV($event)" (onNodeUnselect)="closeNode($event)" [hidden]="mensajeError"> 
                    <ng-template let-node pTemplate="person">
                        <div class="p-2 text-center">
                            <img [src]="node.data.image" class="mb-3 w-3rem h-3rem" width="90px"/>
                            <div class="font-bold">{{ node.data.name }}</div>
                            <div>{{ node.data.title }}</div>
                        </div>
                    </ng-template>
                </p-organizationChart>
                </figure>
              </mat-expansion-panel>
              

           

            <mat-tree id="mat-tree" [dataSource]="dataSource" [treeControl]="treeControl" class="dataSource" [hidden]="mensajeError" style="margin-top: 2%;">
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
                    <button mat-icon-button disabled></button>
                    <mat-icon class="type-icon" [attr.aria-label]="node.type + 'icon'">
                        {{ node.type === 'File' ? 'description' : 'folder' }}
                    </mat-icon>
                    <a target="_blank" href="{{ files }}{{ node.url }}">{{ node.name | mayusPipe }}</a>
                </mat-tree-node>

                <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                    </button>
                    <mat-icon class="type-icon" [attr.aria-label]="node.type + 'icon'">
                        {{ node.type === 'file' ? 'description' : 'folder' }}
                    </mat-icon>
                    {{ node.name | mayusPipe }}
                </mat-tree-node>
            </mat-tree>

            <mat-card class="mensajeCard" [hidden]="!mensajeError">
                <mat-card-content>
                    <mat-icon class="errorIcon">error_outline</mat-icon>
                    <p>Ocurri&oacute; un error.</p>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>