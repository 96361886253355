import { Component, OnInit } from '@angular/core';
import { ReciboService } from '../services/recibo.service';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-publicar-recibos',
  templateUrl: './publicar-recibos.component.html',
  styleUrls: ['./publicar-recibos.component.css'],
})
export class PublicarRecibosComponent implements OnInit {
  publicandoRecibos: boolean;
  respuesta: boolean;
  mensaje: string;
  usuarioLog: any;

  constructor(
    private reciboService: ReciboService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.usuarioLog = JSON.parse(sessionStorage.getItem('currentUser'));
  }

  copiarRecibos() {
    this.reciboService.copiarRecibos().subscribe((res) => {
      this.publicandoRecibos = true;
      if (res.ok) {
        this.publicandoRecibos = false;
        this.respuesta = res.ok;
        this.mensaje = res.mensaje;
      } else {
        this.publicandoRecibos = false;
        this.respuesta = res.ok;
        this.mensaje = res.mensaje;
      }
    });

    this.authenticationService
      .guardarDatosPublicarRecibos(this.usuarioLog.usuario)
      .subscribe((res) => {});
  }
}
