<div class="container-fluid">
  <div class="col-sm-12 py-1 my-1">
    <div class="container py-0 my-0">
      <mat-card class="py-1 my-1" style="text-align: center">
        <mat-card-title class="py-0 my-0">Alta de Comunicación</mat-card-title>
        <form
          class="ui form"
          [formGroup]="noticiasForm"
          enctype="multipart/form-data"
        >
          <mat-card-content>
            <div class="form-row">
              <div
                class="form-group col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
              >
                <mat-form-field *ngIf="!tvTurnos">
                  <input
                    matInput
                    formControlName="titulo"
                    placeholder="Título"
                    required
                  />
                </mat-form-field>
                <div
                  class="alert alert-danger p-1 m-1"
                  *ngIf="
                    noticiasForm.get('titulo').errors &&
                    noticiasForm.get('titulo').touched
                  "
                >
                  <p *ngIf="noticiasForm.get('titulo').hasError('required')">
                    Debe poseer titulo
                  </p>
                </div>
              </div>

              <div
                class="form-group col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
              >
                <mat-form-field>
                  <mat-select
                    formControlName="aplicaciones"
                    multiple
                    placeholder="Sitios a mostrar"
                    required
                    (selectionChange)="changeSitio($event.value)"
                  >
                    <mat-option
                      *ngFor="let aplicacion of listaAplicaciones"
                      [value]="aplicacion.app_id"
                      [disabled]="(aplicacion.app_id !== 32 && noticiasForm.get('aplicaciones').value.includes(32)) || ((aplicacion.app_id === 32 || aplicacion.app_id === 37) && bloqPortal) || (aplicacion.app_id !== 37 && noticiasForm.get('aplicaciones').value.includes(37))"
                      >{{ aplicacion.nombre | mayusPipe }}
                      </mat-option
                    >
                  </mat-select>
                </mat-form-field>
                <div
                  class="alert alert-danger p-1 m-1"
                  *ngIf="
                    noticiasForm.get('aplicaciones').errors &&
                    noticiasForm.get('aplicaciones').touched
                  "
                >
                  <p
                    *ngIf="
                      noticiasForm.get('aplicaciones').hasError('required')
                    "
                  >
                    Debe poseer al menos una aplicacion
                  </p>
                </div>
              </div>
              <hr />

              <div
                class="form-group col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
              >
                <mat-form-field *ngIf="!tvTurnos">
                  <mat-label>Fecha de publicacion</mat-label>
                  <input
                    matInput
                    [matDatepicker]="fecha_publicacion"
                    formControlName="fecha_publicacion"
                    required
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="fecha_publicacion"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #fecha_publicacion></mat-datepicker>
                  <!-- <input
                      matInput
                      type="date"
                      formControlName="fecha_publicacion"
                      placeholder="Fecha de publicacion"
                      required
                    /> -->
                </mat-form-field>
                <div
                  class="alert alert-danger p-1 m-1"
                  *ngIf="
                    noticiasForm.get('fecha_publicacion').errors &&
                    noticiasForm.get('fecha_publicacion').touched
                  "
                >
                  <p
                    *ngIf="
                      noticiasForm.get('fecha_publicacion').hasError('required')
                    "
                  >
                    Debe poseer fecha de publicacion
                  </p>
                </div>
              </div>
            </div>

            <div class="form-row-centre py-0 my-0">
              <ckeditor
                [editor]="editor"
                type="ck-editor__editable"
                [config]="{
                  toolbar: [
                    'heading',
                    '|',
                    'fontsize',
                    'fontfamily',
                    '|',
                    'bold',
                    'italic',
                    'underline',
                    'strikethrough',
                    'highlight',
                    '|',
                    'alignment',
                    '|',
                    'numberedlist',
                    'bulletedlist',
                    '|',
                    'link',
                    'blockquote',
                    'inserttable',
                    '|',
                    'undo',
                    'redo'
                  ]
                }"
                formControlName="texto"
                (ready)="onReady($event)"
                *ngIf="!esconderTexto"
                required
              ></ckeditor>

              <!-- DRAG AND DROP TV-TURNOS -->
              <div *ngIf="tvTurnos">
                <h6>Arrastre y suelte las imagenes segun el orden deseado. Se admiten un máximo de 10 noticias</h6>
                <div class="drag-container" cdkDropListGroup>
                  <div
                    *ngFor="let item of tvNoticias; let i = index"
                    cdkDropList
                    [cdkDropListData]="i"
                  >
                    <div
                      cdkDrag
                      [cdkDragData]="i"
                      (cdkDragEntered)="dragEntered($event)"
                      class="drag-box"
                    >
                      <img class="imagen" src="{{files}}{{item.nombreImagen}}" alt="{{ item.titulo }}">
                      <div class="botones">
                        <button mat-raised-button color="primary" (click)="modificarNoticiaTv(i)" class="eliminarb">
                          <mat-icon matTooltip="Modificar duración de la noticia" fontIcon="timer"></mat-icon>
                        </button>
                        <button mat-raised-button color="warn" (click)="eliminarNoticiaTv(i)" class="eliminarb">
                          <mat-icon matTooltip="Eliminar noticia" fontIcon="delete"></mat-icon>
                        </button>
                      </div>
    
                    </div>

                  </div>                 
  
                </div>
                <div class="buttoncont">
                  <button mat-raised-button color="primary" class="guardarb" (click)="abrirDialogTvAgregarNoticia()" [disabled]="tvNoticias.length >= 10">Agregar noticia</button>
                  <button mat-raised-button color="primary" class="guardarb" (click)="guardarOrden()" [disabled]="saveDragAndDrop">Guardar noticias ordenadas</button>
                </div>
                   
              </div>
              
              <div
                class="alert alert-danger p-1 m-1"
                *ngIf="
                  noticiasForm.get('texto').errors &&
                  noticiasForm.get('texto').touched
                "
              >
                <p *ngIf="noticiasForm.get('texto').hasError('required')">
                  Debe poseer un cuerpo
                </p>
              </div>
            </div>
          </mat-card-content>

          <mat-card-actions class="p-0 m-0" *ngIf="!tvTurnos">
            <div style="text-align: left">
              <input
                type="file"
                (change)="onFileChange($event)"
                accept="image/png, image/jpeg"
              />
            </div>
            <div>
              <button
                mat-raised-button
                color="warn"
                type="button"
                class="btn btn-primary btn"
                style="margin: 2px"
                (click)="cancelar()"
              >
                Cancelar
              </button>
              <button
                mat-raised-button
                color="primary"
                [disabled]="noticiasForm.invalid"
                class="btn btn-primary btn"
                style="margin: 2px"
                type="submit"
                (click)="onSubmit()"
              >
                Cargar noticia
              </button>
              <!-- 
              <button
                [disabled]="noticiasForm.invalid"
                type="button"
                class="btn btn-primary btn"
                style="margin: 2px;"
                type="submit"
                (click)="onSubmit()"
              >
                Cargar noticia
              </button> -->
              <mat-spinner
                diameter="22"
                [hidden]="!loading"
                style="display: inline-block; margin-left: 5%; margin-top: 1%"
              ></mat-spinner>
            </div>
          </mat-card-actions>
        </form>
      </mat-card>
    </div>
  </div>
</div>
