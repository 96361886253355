import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ComunicacionService } from '../services/comunicacion.service';
import { UserService } from '../services/user.service';

import {
  DialogMensajeComponent,
  RespuestaCarga,
} from '../dialog-mensaje/dialog-mensaje.component';

export interface TipoEvento{
  id: number;
  descripcion: string;
}
interface TipoEventos extends Array<TipoEvento>{}

export interface TipoSector{
  sector: number;
  descripcion: string;
}
interface TipoSectores extends Array<TipoSector>{}

@Component({
  selector: 'app-agregar-evento',
  templateUrl: './agregar-evento.component.html',
  styleUrls: ['./agregar-evento.component.css']
})
export class AgregarEventoComponent implements OnInit {
  eventoForm: UntypedFormGroup;
  loading: boolean;
  listaTipoEvento: TipoEventos;
  listaSectores: TipoSectores;
  usuarioLogueado: any;
  seBuscaModificar: boolean;
  loadingSubmit = false;
  tipoSeleccionado: string;

  constructor(    
    private router: Router,
    private comunicacionService: ComunicacionService,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog
    ) 
  { 
    this.loading = false;   
  }
  
  ngOnInit() {
    this.getUser();
    this.buildForm(); 
    this.setSeBuscaModificar(); 
    this.getEventosSocialesTipo(); 
    this.getSectores();
  }
  
  private buildForm() {
    this.eventoForm = this.formBuilder.group({
      id: ['-1'],
      tipo: ['', Validators.required],
      descripcion: ['', Validators.required],
      tipoDocumento: [''],
      numeroDocumento: [''],
      numeroLegajo: [''],
      numeroMatricula: [''],
      fecha: ['', Validators.required],
      sector: ['', Validators.required], 
      estado: [''],    
      fechaCambioEstado: [''],
      usuarioEstado: [''],
      fechaCarga: [''],
      usuarioCarga: [''],
      login_estado: [this.usuarioLogueado.usuario],      
    });
  }

  public cancelar() {
    this.dialog
      .open(DialogConfirmacionComponent, {
        data: `¿Desea cancelar la creación del evento?`,
      })
      .afterClosed()
      .subscribe((confirmado: boolean) => {
        if (confirmado) {
          this.router.navigate(['/eventosSociales']);
        }
      });
  }

  onSubmit() {
    this.loadingSubmit = true;
    if (this.eventoForm.valid) {
      this.loading = true;    
      
      this.comunicacionService.setEventoSocial(
        this.eventoForm
      )
      .subscribe(
        (res) => {
          this.openDialog(res);
        },
        (error) => {
          this.loadingSubmit = false;
          console.log('error' + error);
        }
      ); 
    }
  }

  onModificar() {
    this.loadingSubmit = true;
    this.comunicacionService.modificarEventoSocial(this.eventoForm)
    .subscribe(
      (res) => {
        this.loading = false;
        this.openDialog(res);
      },
      (error) => {
        this.loadingSubmit = false;
        console.log('error' + error);
      }
    ); 
  }

  openDialog(respuesta: RespuestaCarga): void {
    const dialogRef = this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: respuesta,
    });
    this.loadingSubmit = false;
    dialogRef.afterClosed().subscribe((res) => {
      if (respuesta.ok) {
        this.router.navigate(['/eventosSociales']);
      }
    });
  }
  
  getEventosSocialesTipo() {
    this.comunicacionService.getEventosSocialesTipo().toPromise().then(
      (respuesta) => {
        this.listaTipoEvento = respuesta.tipo_eventos;                       
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getSectores() {
    this.comunicacionService.getSectores().toPromise().then(
      (respuesta) => {
        this.listaSectores = respuesta.sectores;                       
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getUser() {
    this.usuarioLogueado = this.userService.getLoggedIn();
  }

  setSeBuscaModificar(){
    let resul = this.comunicacionService.getCurrentEvento();
    if( resul  ){
      this.seBuscaModificar = true;      
      this.setValoresPorDefecto(resul);
    }else{
      this.seBuscaModificar = false;
    }
  }

  setValoresPorDefecto(resul) {   
    this.eventoForm.get('id').setValue(resul.id);
    
    this.eventoForm.get('tipo').setValue(resul.tipo);

    this.eventoForm.get('descripcion').setValue(resul.descripcion);
    this.eventoForm.get('tipoDocumento').setValue(resul.tipoDocumento);
    this.eventoForm.get('numeroDocumento').setValue(resul.numeroDocumento);
    this.eventoForm.get('numeroLegajo').setValue(resul.numeroLegajo);
    this.eventoForm.get('numeroMatricula').setValue(resul.numeroMatricula);   

    let cositas = resul.fecha.split("/");
    let fecha = new Date(cositas[2], cositas[1]-1, cositas[0]);  
    this.eventoForm.get('fecha').setValue(fecha);

    this.eventoForm.get('sector').setValue(resul.sector);
    this.eventoForm.get('estado').setValue(resul.estado);
    this.eventoForm.get('fechaCambioEstado').setValue(resul.fechaCambioEstado);
    this.eventoForm.get('usuarioEstado').setValue(resul.usuarioEstado);
    this.eventoForm.get('fechaCarga').setValue(resul.fechaCarga);
    this.eventoForm.get('usuarioCarga').setValue(resul.usuarioCarga);
  }

}
