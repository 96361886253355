<div class="container-fluid">
    <mat-progress-bar mode="indeterminate" [hidden]="!cargando"></mat-progress-bar>

    <mat-card [tabindex]="0" class="card-principal" [hidden]="cargando">
        <h2>Ver detalle de boleta</h2>
        <div class="container-fluid">
            <div class="row">
                <div class="col">
                <mat-divider></mat-divider>
                <mat-card-header style="margin-left: -16px;margin-top:1.5%">
                
                    <mat-card-title>Paciente: {{ dataTriage.paciente.apellido | titlecase}} </mat-card-title>
        
                    <mat-card-subtitle>
                    <span>Enfermero:</span> <b> {{ dataTriage.enfermero | titlecase}}</b>
                    |
                    <span>Médico:</span><b> <span *ngIf="dataTriage.medDerivado.nombreCompleto != ''">{{ dataTriage.medDerivado.nombreCompleto | titlecase}}</span>  
                                            <span *ngIf="dataTriage.medDerivado.nombreCompleto == ''"> Sin asignar</span></b> 
                    </mat-card-subtitle>
        
                </mat-card-header>
                </div>
            </div>

            <div class="row">
                <div class="col">
                <mat-divider></mat-divider>
                <mat-card-header style="margin-left: -16px;margin-top:1.5%">
                
                    <mat-card-title>Información del paciente </mat-card-title>
        
                    <mat-card-subtitle>
                    <span>Presión sistólica:</span> <b> {{ dataTriage.presionSistolica }}</b>
                    |
                    <span>Presión diastólica:</span><b> {{ dataTriage.presionDiastolica }} </b>
                    |
                    <span>Pulso:</span><b> {{ dataTriage.pulso }} </b> 
                    |
                    <span>Frecuencia respiratoria:</span><b> {{ dataTriage.frecuenciaRespiratoria }} </b> 
                    |
                    <span>Temperatura:</span><b> {{ dataTriage.temperatura }} </b>
                    <br>
                    <span>Observación:</span><b> {{ dataTriage.observacion }} 
                        <span *ngIf="dataTriage.observacion == ''"> Sin observaciones</span></b> 
                    </mat-card-subtitle>
        
                </mat-card-header>
                </div>
            </div>
            <mat-divider></mat-divider>

            <mat-card-header style="margin-left: -16px;margin-top:1.5%">
                <mat-card-title>Información de las prácticas efectuadas al paciente</mat-card-title>
            </mat-card-header>
            <div class="contenedor-tabla">
                <table
                mat-table
                [dataSource]="dataDet"
                class="mat-elevation-z8"
                *ngIf="!noExisteDetalle"
                >
                <div style="text-align: center; margin-right: 1px">
                    <ng-container matColumnDef="matriculaEfector">
                    <th mat-header-cell *matHeaderCellDef>Matrícula del efector</th>
                    
                    <td mat-cell *matCellDef="let data">
                        {{ data.matriculaEfector }}
                    </td>
                    
                    </ng-container>
                </div>
    
                <div style="text-align: center; margin-right: 1px">
                    <ng-container matColumnDef="nombreEfector">
                    <th mat-header-cell *matHeaderCellDef>Nombre del efector</th>
                    
                    <td mat-cell *matCellDef="let data">
                        {{ data.efector | titlecase}}
                    </td>
                    
                    </ng-container>
                </div>
                    
                <div style="text-align: center; margin-right: 1px">
                    <ng-container matColumnDef="practica">
                        <th mat-header-cell *matHeaderCellDef>Número de práctica</th>
                        
                        <td mat-cell *matCellDef="let data">
                            {{ data.practica }}
                        </td>
                    
                    </ng-container>
                    </div>

                    <div style="text-align: center; margin-right: 1px">
                        <ng-container matColumnDef="detalle">
                            <th mat-header-cell *matHeaderCellDef>Detalle de práctica</th>
                            
                            <td mat-cell *matCellDef="let data">
                                {{ data.detalle }}
                            </td>
                        
                        </ng-container>
                        </div>
    
                <tr mat-header-row *matHeaderRowDef="displayedColumnasItems; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnasItems"></tr>
                </table>
                </div>
                <div style="text-align: center; margin-top: 2%;  " *ngIf="noExisteDetalle">
                    <mat-card class="sin-pacientes-card">
                    <h6 style="color:rgb(13, 52, 80)" >No existe información de las prácticas efectuadas al paciente</h6>
                    </mat-card>
                </div>
        </div> 
      </mat-card>
