<h2 mat-dialog-title>Agregar una nueva noticia</h2>
    <mat-dialog-content>
      <form [formGroup]="noticiasForm">
        <div class="container">
          <div class="file-upload-container">
            <input
              formControlName="imagen"
              type="file"
              accept="image/png, image/jpeg"
              (change)="onFileChange($event)"
            />
          </div>
  
          <mat-form-field>
            <input matInput type="text" [(ngModel)]="titleValue" name="numberInput" placeholder="Ingrese el título" formControlName="titulo"
            />
          </mat-form-field>
  
          <mat-form-field>
            <input matInput type="number" [(ngModel)]="numberValue" name="numberInput" placeholder="Ingrese la duración en segundos" formControlName="tiempo" max="500" min="1"/>
            <mat-hint class="duracion">La duración debe ser mayor a 0 y menor o igual a 300 segundos (5 minutos)</mat-hint>
          </mat-form-field>

          <mat-form-field class="field">
            <input matInput type="date" [(ngModel)]="dateValue" name="dateInput" placeholder="Seleccione fecha de publicación" formControlName="fecha_publicacion"/>
          </mat-form-field>
        </div>
      </form>
      
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-raised-button color="warn" (click)="onCancelClick()">Cancelar</button>
      <button mat-raised-button color="primary" (click)="onSaveClick()" [disabled]="noticiasForm.invalid">Agregar noticia</button>
    </mat-dialog-actions> 