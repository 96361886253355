import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UrlResolverService } from '../services/urlResolver.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-dialog-recuperar-contrasena',
  templateUrl: './dialog-recuperar-contrasena.component.html',
  styleUrls: ['./dialog-recuperar-contrasena.component.css']
})

export class DialogRecuperarContrasenaComponent implements OnInit {

  recuperarForm: UntypedFormGroup;
  loadingSubmit: boolean = false;
  respRecupClave: { ok: boolean; mensaje: string; } = { ok: false, mensaje: '' };
  mostrarMsje: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private urlResolverService: UrlResolverService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.buildForm();
  }

  private buildForm() {
    this.recuperarForm = this.formBuilder.group({
      nombreUsuario: [ null, Validators.required] ,
    })
  }

  async guardar() {
    if (this.recuperarForm.valid) {
      this.loadingSubmit = true;
      this.mostrarMsje = false;
      await this.userService.recuperarClave(this.recuperarForm.controls['nombreUsuario'].value).toPromise().then(rpRecuperarClave => {
        // console.log(rpRecuperarClave)
        this.respRecupClave = {
          ok: rpRecuperarClave.ok,
          mensaje: rpRecuperarClave.mensaje
        }
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => {
        this.loadingSubmit = false;
        this.mostrarMsje = true;
      })
    }
  }

}
