import { Component, OnInit } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { of as observableOf } from 'rxjs';
import { FlatTreeControl } from '@angular/cdk/tree';
import { DocumentsService } from '../services/documents.service';
import { AuthenticationService } from '../services/authentication.service';
import { AppComponent } from '../app.component';
import { FileNode, FlatTreeNode } from '../docstree/docstree.component';
import { ActivatedRoute, Router } from '@angular/router';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'app-organigrama-sector',
  templateUrl: './organigrama-sector.component.html',
  styleUrls: ['./organigrama-sector.component.css']
})

export class OrganigramaSectorComponent implements OnInit {
  
  /** The TreeControl controls the expand/collapse state of tree nodes.  */
  treeControl: FlatTreeControl<FlatTreeNode>;

  /** The TreeFlattener is used to generate the flat list of items from hierarchical data. */
  treeFlattener: MatTreeFlattener<FileNode, FlatTreeNode>;

  /** The MatTreeFlatDataSource connects the control and flattener to provide data. */
  dataSource: MatTreeFlatDataSource<FileNode, FlatTreeNode>;

  baseUrl: string;
  loading: boolean = true;
  mensajeError: boolean = false;
  sector: string;
  files: string;
  usuarioLog;
  selectedNodes: TreeNode[];
  data: TreeNode[] = [ {
    expanded: true,
    type: 'person',
    data: {
      image: '../assets/images/ti/pablonavajas.jpeg',
      name: 'Pablo Navajas',
      cv: '../assets/pdfs/CVs/TI/pablonavajas.pdf',
      title: 'Jefe'
    },
    children: [ {
      expanded: false,
      label: 'Responsable de Información Médica',
      children: [ {
        type: 'person',
        data: {
          image: '../assets/images/ti/icono-mujer.avif',
          name: 'Bazterrica María Luz',
          // cv: '../assets/pdfs/CVs/TI/javierlopedebarrios.pdf',
        },
      } ]
    }, {
      expanded: false,
      label: 'Desarrolladores',
      children: [ {
        type: 'person',
        data: {
          image: '../assets/images/ti/andreapellegrini.png',
          name: 'Andrea Beatriz Pellegrini',
          cv: '../assets/pdfs/CVs/TI/andreapellegrini.pdf',
        },
      }, {
        type: 'person',
        data: {
          image: '../assets/images/ti/francomartini.jpg',
          name: 'Franco Martini',
          cv: '../assets/pdfs/CVs/TI/francomartini.pdf',
        },
      }, {
        type: 'person',
        data: {
          image: '../assets/images/ti/barbaraib.jpg',
          name: 'Barbara Ibañez',
          cv: '../assets/pdfs/CVs/TI/barbaraib.pdf',
        },
      }, {
        type: 'person',
        data: {
          image: '../assets/images/ti/foto_cv.jpg',
          name: 'Mauricio Nordio',
          cv: null,
        },
      }, {
        type: 'person',
        data: {
          image: '../assets/images/ti/agustinrampinini.jpg',
          name: 'Agustin Rampinini',
          cv: '../assets/pdfs/CVs/TI/agustinrampinini.pdf',
        },
      }, {
        type: 'person',
        data: {
          image: '../assets/images/ti/ailenpanigo.jpeg',
          name: 'Ailen Panigo',
          cv: '../assets/pdfs/CVs/TI/ailenpanigo.pdf',
        },
      }, {
        type: 'person',
        data: {
          image: '../assets/images/ti/larafons.jpeg',
          name: 'Lara Fons',
          cv: '../assets/pdfs/CVs/TI/larafons.pdf',
        },
      }, {
        type: 'person',
        data: {
          image: '../assets/images/ti/teozaratiegui.jpeg',
          name: 'Teo Zaratiegui',
          cv: '../assets/pdfs/CVs/TI/teozaratiegui.pdf',
        },
      } ]
    }, {
      expanded: false,
      label: 'Implementadores',
      children: [ {
        type: 'person',
        data: {
          image: '../assets/images/ti/fernandacomelli.jpg',
          name: 'Fernanda Comelli',
          cv: '../assets/pdfs/CVs/TI/fernandacomelli.pdf',
        },
      } ]
    }, {
      expanded: false,
      label: 'Soporte',
      children: [ {
        type: 'person',
        data: {
          image: '../assets/images/ti/lisandroalmazan.jpg',
          name: 'Lisandro Almazan',
          cv: '../assets/pdfs/CVs/TI/lisandroalmazan.pdf',
        },
      }, {
        type: 'person',
        data: {
          image: '../assets/images/ti/foto_cv.jpg',
          name: 'Santino Fabini',
          cv: null,
        },
      } ]
    }, {
      expanded: false,
      label: 'Operaciones e Infraestructura',
      children: [ {
        type: 'person',
        data: {
          image: '../assets/images/ti/matiasisla.jpg',
          name: 'Matias Isla',
          cv: '../assets/pdfs/CVs/TI/matiasisla.pdf',
        },
      }, {
        type: 'person',
        data: {
          image: '../assets/images/ti/fabionasso.jpg',
          name: 'Fabio Nasso',
          cv: null,
        },
      }, {
        type: 'person',
        data: {
          image: '../assets/images/ti/foto_cv.jpg',
          name: 'Carlos Pereyra',
          cv: null,
        },
      } ]
    }]
  }];

  constructor(
    private documentsService: DocumentsService,
    private authService: AuthenticationService,
    private rutaActiva: ActivatedRoute,
    private router: Router,
  ) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.baseUrl = AppComponent.baseUrl;
    this.files = this.baseUrl + 'restricted/fs/getFile?pathString=';
  }

  ngOnInit() {
    this.usuarioLog = JSON.parse(sessionStorage.getItem('currentUser'));
    this.rutaActiva.params.subscribe((parametro) => {
      parametro ? (this.sector = parametro.sector) : this.router.navigate(['/inicio']);
    });

    this.getDocumentosSector(this.sector);
  }

  transformer(node: FileNode, level: number) {
    return {
      name: node.name,
      type: node.type,
      url: node.url,
      level: level,
      expandable: !!node.children,
    };
  }

  /** Get the level of the node */
  getLevel(node: FlatTreeNode) {
    return node.level;
  }

  /** Get whether the node is expanded or not. */
  isExpandable(node: FlatTreeNode) {
    return node.expandable;
  }

  /** Get whether the node has children or not. */
  hasChild(index: number, node: FlatTreeNode) {
    return node.expandable;
  }

  /** Get the children for the node. */
  getChildren(node: FileNode) {
    return observableOf(node.children);
  }

  abrirCV(event) {
    if (event.node.type == 'person' && event.node.data.cv != null) {
      window.open(event.node.data.cv, '_blank');
    } else if (event.node.type != 'person') {
      event.node.expanded = !event.node.expanded;
    }
  }

  closeNode(event) {
    if (event.node.type != 'person') {
      event.node.expanded = !event.node.expanded;
    }
  }

  async getDocumentosSector(sector) {
    await this.documentsService.getOrganigrama(sector).toPromise().then(res => {
      if (res.ok) {
        this.dataSource.data = res.data.children;     
      } else {
        this.mensajeError = true;
      }
    }).catch(error => {
      console.error(error);
      // this.mensajeError = true;
    }).finally(() => {
      this.loading = false;
    });
  }

  logout() {
    this.authService.logout();
  }
}