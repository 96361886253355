import { Component, OnInit } from '@angular/core';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { of as observableOf } from 'rxjs';
import { FlatTreeControl } from '@angular/cdk/tree';
import { DocumentsService } from '../services/documents.service';
import { AuthenticationService } from '../services/authentication.service';
import { AppComponent } from '../app.component';
import { TreeModule } from 'primeng/tree';
import { TreeNode } from 'primeng/api';
import { PrimeIcons } from 'primeng/api';

export interface Folder {
  ok: boolean;
  mensaje: string;
  data: FileNode;
}

/** File node data with possible child nodes. */

export interface FileNode {
  name: string;
  type: string;
  url: string;
  children: FileNode[];
}

/**
 * Flattened tree node that has been created from a FileNode through the flattener. Flattened
 * nodes include level index and whether they can be expanded or not.
 */

export interface FlatTreeNode {
  name: string;
  type: string;
  url: string;
  level: number;
  expandable: boolean;
}

@Component({
  selector: 'app-docstree',
  templateUrl: './docstree.component.html',
  styleUrls: ['./docstree.component.css'],
})
export class DocstreeComponent implements OnInit {
  baseUrl: string;
  loading: boolean;
  mensajeError: boolean;

  name;

  /** The TreeControl controls the expand/collapse state of tree nodes.  */
  treeControl: FlatTreeControl<FlatTreeNode>;

  /** The TreeFlattener is used to generate the flat list of items from hierarchical data. */
  treeFlattener: MatTreeFlattener<FileNode, FlatTreeNode>;

  /** The MatTreeFlatDataSource connects the control and flattener to provide data. */
  dataSource: MatTreeFlatDataSource<FileNode, FlatTreeNode>;

  files: string;
  usuarioLog;

  archivos!: TreeNode[];

  archivosAMostrar!: TreeNode[];

  selectedFile: TreeNode;

  constructor(
    private documentsService: DocumentsService,
    private authService: AuthenticationService
  ) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.baseUrl = AppComponent.baseUrl;
    this.treeControl = new FlatTreeControl(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );
    this.files = this.baseUrl + 'restricted/fs/getFile?pathString=';
  }

  // http://172.20.32.246:8090/horus_restfull/api/restricted/fs/getFile?pathString=  ---> HORUS_RESTFULL

  ngOnInit() {
    this.loading = true;

    this.usuarioLog = JSON.parse(sessionStorage.getItem('currentUser'));

    this.documentsService.getDocuments(this.usuarioLog.usuario).subscribe(
      (res) => {
        if (res.ok) {
          this.loading = false;
          this.dataSource.data = res.data.children;
          //console.log(this.dataSource.data);
          let aux: TreeNode[] = [];
          this.dataSource.data.forEach(archivo => aux.push(this.constructTreeNode(archivo)));
          this.archivos = aux;
          this.archivosAMostrar = [...this.archivos];
        } else if (!res.ok) {
          this.mensajeError = true;
        }
      },
      (error) => {
        this.mensajeError = true;
        console.log(error);
      }
    );
  }

  constructTreeNode(data: any): TreeNode {

    const node: TreeNode = {
      key: data.name,
      "label": data.name,
      data: data.url,
      type: data.type,
      collapsedIcon: data.type == 'Directory' ? 'pi pi-folder text-warning' : 'pi pi-file text-primary',
      expandedIcon: data.type == 'Directory' ? 'pi pi-folder-open text-warning' : 'pi pi-file text-primary',
      expanded: false,
      children: []
    };

    if (data.children && data.children.length > 0) {
      data.children.forEach((childData: any) => {
        node.children.push(this.constructTreeNode(childData));
      });
    }

    return node;
  
  }

  nodeSelect(event: any) {
    let node: TreeNode = event.node;
    if (node.type != 'Directory') {
      const url = this.files + node.data;
      window.open(url, "_blank");
    } else {
      node.expanded = !node.expanded;
    }
    
  }

  /** Transform the data to something the tree can read. */
  transformer(node: FileNode, level: number) {
    return {
      name: node.name,
      type: node.type,
      url: node.url,
      level: level,
      expandable: !!node.children,
    };
  }

  /** Get the level of the node */
  getLevel(node: FlatTreeNode) {
    return node.level;
  }

  /** Get whether the node is expanded or not. */
  isExpandable(node: FlatTreeNode) {
    return node.expandable;
  }

  /** Get whether the node has children or not. */
  hasChild(index: number, node: FlatTreeNode) {
    return node.expandable;
  }

  /** Get the children for the node. */
  getChildren(node: FileNode) {
    return observableOf(node.children);
  }

  logout() {
    this.authService.logout();
  }
}
