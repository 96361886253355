<button
  mat-icon-button
  style="float: right; top: -24px; right: -24px"
  [mat-dialog-close]="false"
>
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>

<h1 mat-dialog-title style="padding-left: 0%;padding-right: 3.5%;">Editar Notificaciones</h1>
<div mat-dialog-content>
  <form [formGroup]="notificacionesForm">
    <div><strong>Motivo: {{ this.data.motivo }}</strong></div>

    
    <div class="row">
      <div class="col">
      <ckeditor [editor]="Editor" formControlName="mensaje" data="this.data.contAdicional" (change)="onChange($event)" (ready)="onReady($event)" 
      [config]="{
        toolbar: [
          'heading',
          '|',
          'fontsize',
          'fontfamily',
          '|',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'highlight',
          '|',
          'alignment',
          '|',
          'numberedlist',
          'bulletedlist',
          '|',
          'link',
          'blockquote',
          'inserttable',
          '|',
          'undo',
          'redo'
        ]
      }"></ckeditor>
      </div>
  </div>
    <div class="row">
      <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 text-left">
          <button mat-raised-button class="btn-block mt-2" (click)="onSubmitForm()" color="primary">
               Guardar
          </button>
      </div>
  </div>
      <!-- <div class="col-6 justify-content-center">
        <mat-form-field>
          <mat-label>Notificacion</mat-label>
              <textarea matInput  style="width:600px; height:300px;">
                {{this.data.contAdicional}}

              </textarea>
              
        </mat-form-field>
      </div> -->

   
  </form>


</div>
