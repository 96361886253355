import { Component, OnInit, ViewChild } from '@angular/core';
import { ReciboService } from '../services/recibo.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppComponent } from '../app.component';
import { AuthenticationService } from '../services/authentication.service';

export interface Response {
  ok: boolean;
  mensaje: string;
  data: Recibo[];
}

export interface DatosUsuario {
  ok: boolean;
  mensaje: string;
  texto: string;
}

export interface Recibo {
  id: number;
  legajo: number;
  anio: number;
  mes: number;
  tipoLiquidacion: number;
  ubicacion: string;
  nombrePdf: string;
  descripcionLiq: string;
}

export interface Legajo {
  letra: string;
  numero: bigint;
}

@Component({
  selector: 'app-recibos',
  templateUrl: './recibos.component.html',
  styleUrls: ['./recibos.component.css'],
})
export class RecibosComponent implements OnInit {
  displayedColumns: string[] = ['mes', 'anio', 'liquidacion', 'icon'];
  meses = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  // dataSource = ELEMENT_DATA;
  dataSource: any;
  recibosPorAnio: Recibo[];
  usuarioLog: any;
  codigoRol = 'EMP';
  nroLegajo: bigint;
  baseUrl: string;
  getFiles: string;
  loading: boolean;
  mensajeError: boolean;
  filtroAnios: number[] = [];
  filtroAniosSinRepetir = new Set();
  mensajeParaGuardar: string;
  cadenaLegajo = [];
  verPdf = false;
  urlPdf;
  navegador: string;
  esIE: boolean;

  constructor(
    private reciboService: ReciboService,
    private authenticationService: AuthenticationService
  ) {
    (this.baseUrl = AppComponent.baseUrl),
      (this.getFiles = this.baseUrl + 'restricted/fs/getFile?pathString=');
  }

  @ViewChild(MatSort, ({ static: false } as any)) sort: MatSort;

  ngOnInit() {
    this.loading = true;
    this.obtenerNavegador();
    this.obtenerNroLegajo();
    this.obtenerRecibos();
  }

  obtenerNavegador() {
    this.navegador = navigator.userAgent.toLocaleLowerCase();
    this.esIE = this.navegador.indexOf('trident') > -1;
  }

  obtenerNroLegajo() {
    this.usuarioLog = JSON.parse(sessionStorage.getItem('currentUser'));
    this.cadenaLegajo = this.usuarioLog.legajo.split('-');
    this.nroLegajo = this.cadenaLegajo[1];
  }

  obtenerRecibos() {
    this.reciboService.obtenerRecibos(this.nroLegajo).subscribe(
      (res) => {
        if (res.ok) {
          this.dataSource = new MatTableDataSource<Recibo>(res.data);
          this.dataSource.sort = this.sort;
          res.data.forEach((element) => {
            this.filtroAniosSinRepetir.add(element.anio);
          });
        } else if (!res.ok) {
          this.mensajeError = true;
        }
        this.loading = false;
      },
      (error) => {
        this.mensajeError = true;
        console.log(error);
        this.loading = false;
      }
    );
  }

  applyFilter(value: number) {
    if (value == 0) {
      this.obtenerRecibos();
    } else {
      this.dataSource.filter = value.toString().trim().toLowerCase();
    }
  }

  guardarDatosUsuario(unRecibo: Recibo) {
    this.urlPdf = this.getFiles + unRecibo.ubicacion + unRecibo.nombrePdf;
    this.verPdf = true;

    // this.urlPdf = this.getFiles + 'C:/Users/francoma/Desktop/2020-01-MES-050120-00040.pdf';
    // PROD

    this.authenticationService
      .guardarDatosUsuarioRecibos(
        unRecibo.nombrePdf,
        this.usuarioLog.usuario,
        this.nroLegajo
      )
      .subscribe((res) => {});
  }

  irAtras() {
    this.verPdf = false;
    this.urlPdf = null;
    this.ngOnInit();
  }
}
