import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { ComunicacionService } from '../services/comunicacion.service';
import { DatePipe } from '@angular/common';
import { SplitStringPipe } from '../pipes/split-string.pipe';
import { Router } from '@angular/router';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { EmbedUrlsService } from '../services/embedUrls.service';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import {
  DialogMensajeComponent,
  RespuestaCarga,
} from '../dialog-mensaje/dialog-mensaje.component';
import { AppComponent } from '../app.component';
import {CdkDragEnter, moveItemInArray} from '@angular/cdk/drag-drop';
import { DialogAgregarNoticiaTvComponent } from '../dialog-agregar-noticia-tv/dialog-agregar-noticia-tv.component';
import { DialogModificarNoticiaTvComponent } from '../dialog-modificar-noticia-tv/dialog-modificar-noticia-tv.component';

export interface Aplicacion {
  app_id: number;
  nombre: string;
}

@Component({
  selector: 'app-agregar-noticias',
  templateUrl: './agregar-noticias.component.html',
  styleUrls: ['./agregar-noticias.component.css'],
  providers: [SplitStringPipe],
})
export class AgregarNoticiasComponent implements OnInit {
  public editor = DecoupledEditor;
  noticiasForm: UntypedFormGroup;
  listaAplicaciones: Aplicacion[];
  loading: boolean;
  submitted: boolean;
  private usuarioLog: string;
  esconderTexto: boolean;
  bloqPortal: boolean = false;
  tvTurnos: boolean;
  tvNoticias: Array<any> = [];
  files: String;
  baseUrl: string;
  saveDragAndDrop: boolean = true;

  public onReady(editor) {
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );
  }
  constructor(
    private formBuilder: UntypedFormBuilder,
    private comunicacionService: ComunicacionService,
    private splitString: SplitStringPipe,
    private router: Router,
    private bottomSheet: MatBottomSheet,
    private EmbedUrlSer: EmbedUrlsService,
    public dialog: MatDialog
  ) {
     this.baseUrl = AppComponent.baseUrl;
    this.EmbedUrlSer.embedUrls.subscribe((funciones) => {
      if (
        funciones.find((funcion) => {
          return funcion.url === 'agregar-noticias';
        })
      ) {
      } else {
        this.router.navigate(['/inicio']);
      }
    });
    this.loading = false;
    this.submitted = false;
    this.usuarioLog = JSON.parse(sessionStorage.getItem('currentUser')).usuario;
  }
  ngOnInit() {
    this.buildForm();
    this.getAplicaciones();
    this.comunicacionService.getParametroComunicacion().subscribe(
      (res) => {
        const respuesta = res.paginador.elementos.find((elemento) => {
          return elemento.nombre === 'comunicacion';
        });
        this.files = this.baseUrl + 'restricted/fs/getImage?pathString=' + respuesta.valor;
        this.comunicacionService.getNoticiasFraccionadas(37, null, 10, null).subscribe((data) => {
          if (data){
            this.tvNoticias= this.tvNoticias = data.sort((a, b) => a.orden - b.orden);
          }
        })
      },
      (error: any) => {
        console.log('error al buscar parametro!');
        console.log(error);
      }
    );
    this.loading = false;
    this.submitted = false;
    this.usuarioLog = JSON.parse(sessionStorage.getItem('currentUser')).usuario;
    }

  openDialog(respuesta: RespuestaCarga): void {
    const dialogRef = this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: respuesta,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (respuesta.ok) {
        this.router.navigate(['/inicio']);
      }
    });
  }

  private buildForm() {
    this.noticiasForm = this.formBuilder.group({
      aplicaciones: ['', Validators.required],
      titulo: ['', Validators.required],
      texto: ['', Validators.required],
      imagen: ['', Validators.required],
      fecha_publicacion: ['', Validators.required],
    });
  }
  public cancelar() {
    this.dialog
      .open(DialogConfirmacionComponent, {
        data: `¿Seguro desea cancelar la creación de la noticia?`,
      })
      .afterClosed()
      .subscribe((confirmado: boolean) => {
        if (confirmado) {
          this.router.navigate(['/inicio']);
        }
      });
  }

  getNoticias(){
    this.comunicacionService.getNoticiasFraccionadas(37, null, 10, null).subscribe((data) => {
      this.tvNoticias= this.tvNoticias = data.sort((a, b) => a.orden - b.orden);
    })
  }

  getAplicaciones() {
    this.comunicacionService.getAplicaciones(this.usuarioLog).subscribe(
      (res) => {
        if (res != null) {
          this.listaAplicaciones = res;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.noticiasForm.get('imagen').setValue({
          nombre: file.name,
          tipo: '.' + this.splitString.transform(file.name),
          valor: (reader.result as string).split(',')[1],
        });
      };
    }
  }

  changeSitio(event) {
    if (event.length > 0 && event.includes(32)){
      this.esconderTexto= true
      this.noticiasForm.get('imagen').setValidators(Validators.required);
      if (!this.noticiasForm.get('imagen').value.valor){
        this.noticiasForm.get('imagen').setErrors({required: true})
      }
      this.noticiasForm.get('texto').clearValidators()
      this.noticiasForm.get('texto').setErrors(null)
      this.noticiasForm.get('texto').setValue('')
      this.tvTurnos= false;
    } else {
      if (event.length > 0 && event.includes(37)){
        this.tvTurnos= true
        this.esconderTexto= true
        this.noticiasForm.get('texto').clearValidators()
        this.noticiasForm.get('texto').setErrors(null)
        this.noticiasForm.get('texto').setValue('')
      } else { 
        if (event.length > 0){
          this.bloqPortal= true;
        } else {
          this.bloqPortal= false;
        }
        this.tvTurnos= false;
        this.esconderTexto= false
        this.noticiasForm.get('texto').setValidators(Validators.required);
        this.noticiasForm.get('imagen').clearValidators()
        this.noticiasForm.get('imagen').setErrors(null)
      }
    }
  }

  onSubmit() {
    if (this.noticiasForm.valid) {
      this.loading = true;
      const dateSendingToServer = new DatePipe('en-US').transform(
        this.noticiasForm.value.fecha_publicacion,
        'medium'
      );
      this.comunicacionService
        .setNoticias(
          this.usuarioLog,
          this.noticiasForm.value.titulo,
          btoa(unescape(encodeURIComponent(this.noticiasForm.value.texto))),
          this.noticiasForm.value.imagen.nombre,
          this.noticiasForm.value.imagen.valor,
          this.noticiasForm.value.aplicaciones,
          dateSendingToServer
        )
        .subscribe(
          (res) => {
            this.loading = false;
            this.openDialog(res);
          },
          (error) => {
            console.log('error' + error);
          }
        );
    }
  }
  
  // LOGICA DRAG AND DROP
  dragEntered(event: CdkDragEnter<number>) {
    const drag = event.item;
    const dropList = event.container;
    const dragIndex = drag.data;
    const dropIndex = dropList.data;
    const phContainer = dropList.element.nativeElement;
    const phElement = phContainer.querySelector('.cdk-drag-placeholder');
    phContainer.removeChild(phElement);
    phContainer.parentElement.insertBefore(phElement, phContainer);
    moveItemInArray(this.tvNoticias, dragIndex, dropIndex);
    this.saveDragAndDrop= false; //habilito el boton para guardar el orden
    this.tvNoticias.forEach((noticia, index) => {
      noticia.orden = index;
    });
  }

  eliminarNoticiaTv(i){
    this.dialog
      .open(DialogConfirmacionComponent, {
        data: `¿Desea eliminar la noticia?`,
      })
      .afterClosed()
      .subscribe((confirmado: boolean) => {
        if (confirmado) {
          this.comunicacionService.editarNoticia(
            this.tvNoticias[i].idComunicacion,
            this.usuarioLog,
            null,
            null,
            null,
            null,
            this.tvNoticias[i].fechaPublicacion,
            'INA',
            [],
            null,
            null,
            null,
            null,
          ).subscribe((data) => {
            this.getNoticias();
          })
        }
      });
  }

  modificarNoticiaTv(i){
    this.dialog
      .open(DialogModificarNoticiaTvComponent, {
        data: this.tvNoticias[i],
        width: '50%',
      })
      .afterClosed()
      .subscribe((duracion) => {
        if (duracion) {
          const apps: Aplicacion[] = [{
            app_id: 37,
            nombre: 'TV-Turnos'
          }];
          this.comunicacionService.editarNoticia(
            this.tvNoticias[i].idComunicacion,
            this.usuarioLog,
            null,
            null,
            null,
            null,
            this.tvNoticias[i].fechaPublicacion,
            null,
            apps,
            this.listaAplicaciones,
            null,
            null,
            duracion,
          ).subscribe((data) => {
            this.getNoticias()
          })
        }
      });
  }

  guardarOrden(){
    const apps: Aplicacion[] = [{
      app_id: 37,
      nombre: 'TV-Turnos'
    }];
    for (const noticia of this.tvNoticias){
      this.comunicacionService.editarNoticia(
        noticia.idComunicacion,
        this.usuarioLog,
        noticia.titulo,
        null,
        null,
        null,
        noticia.fechaPublicacion,
        null,
        apps,
        this.listaAplicaciones,
        null,
        noticia.orden+1,
        null
      ).subscribe((data) => { 
        this.getNoticias();
      })
    }
      const data: RespuestaCarga = {
        ok: true,
        mensaje: 'Se modificó el orden de las noticias correctamente'
      };
      this.dialog.open(DialogMensajeComponent, {
        width: '500px',
        data: data,
      });
      this.saveDragAndDrop= true;
  }

  abrirDialogTvAgregarNoticia(): void {
    const dialogRef = this.dialog.open(DialogAgregarNoticiaTvComponent, {
      width: '40%',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.tvNoticias.push(result)
        const dateSendingToServer = new DatePipe('en-US').transform(
         result.fechaPublicacion,
          'medium'
        );
        this.comunicacionService.setNoticias(
          this.usuarioLog,
          result.titulo,
          btoa(unescape(encodeURIComponent(this.noticiasForm.value.texto))),
          result.nombre,
          result.valor,
          this.noticiasForm.value.aplicaciones,
          dateSendingToServer,
          this.tvNoticias.length,
          result.tiempo
        ).subscribe((data) => {
          this.getNoticias();
        })
      }
    });
  }
}
