import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'safeHTML',
})
export class SafeHTMLPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(datos: string) {
    return this.sanitizer.bypassSecurityTrustHtml(
      decodeURIComponent(escape(atob(datos)))
    );
  }
}

/*  methods:{
    modifyUrl(url) {
        let endpoint = url;
        endpoint = endpoint.replace('oembed', 'iframe');
        endpoint = endpoint.replace('url', 'src');
        endpoint = endpoint.replace('watch?v=', 'embed/');
        endpoint = endpoint.replace('oembed', 'iframe');
        return endpoint;
      },
  } */
