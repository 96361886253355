<div class="container-fluid" style="border: 0px solid blue;">
    <h1 mat-dialog-title class="text-center mb-1">Campa&ntilde;a de actualizaci&oacute;n de correo</h1>
    <p>Por Favor, ingres&aacute; un email* al cual te podamos enviar el enlace para recuperar la contrase&ntilde;a, en caso de ser requerido.</p>
    <p>*El email ingresado no debe ser de extensi&oacute;n: @hospitalespanol.org.ar</p>

    <mat-spinner [hidden]="!loadButton" style="margin: auto;"></mat-spinner>

    <form [formGroup]="cambiarCorreoForm" [hidden]="loadButton" style="border: 0px solid blue;">

        <div class="row" style="border: 0px solid yellow; margin-left: 0;">
            <mat-form-field class="full-width">
              <mat-label>Email Actual</mat-label>
              <input matInput formControlName="emailActual" required/>
              <!-- <mat-error *ngIf="cambiarCorreoForm.get('emailNuevo').invalid">{{getErrorMessage('emailNuevo')}}</mat-error> -->
            </mat-form-field>
        </div>

        <div class="row" style="border: 0px solid yellow; margin-left: 0;">
            <mat-form-field class="full-width">
              <mat-label>Email Nuevo</mat-label>
              <input matInput formControlName="emailNuevo" required/>
              <mat-error *ngIf="cambiarCorreoForm.get('emailNuevo').invalid">{{getErrorMessage('emailNuevo')}}</mat-error>
            </mat-form-field>
        </div>

        <div class="row" style="border: 0px solid yellow; margin-top: 1%; margin-left: 0;">
            <mat-form-field class="full-width">
              <mat-label>Confirmar Email</mat-label>
              <input matInput formControlName="confirmarEmail" required/>
              <mat-error *ngIf="cambiarCorreoForm.get('confirmarEmail').invalid">{{getErrorMessage('confirmarEmail')}}</mat-error>
            </mat-form-field>
        </div>

        <div class="row" style="border: 0px solid blue; margin-top: 1%; margin-left: 0;">
            <button mat-raised-button color="primary" (click)="cambiarCorreo()" style="margin-right: 1%;" [disabled]="actualizo">Actualizar</button>
            <button mat-raised-button color="warn" [mat-dialog-close]="false">{{ actualizo ? 'Cerrar' : 'Mas Tarde'}}</button>
        </div>

        <p class="alert alert-success" style="margin-top: 1%;" *ngIf="hayMsje && actualizo">El correo se actualizo correctamente.</p>
        <p class="alert alert-danger" style="margin-top: 1%;" *ngIf="hayMsje && !actualizo">Ocurrio un error al guardar.</p>
        <p class="alert alert-danger" style="margin-top: 1%;" *ngIf="emailsDistintos">El email nuevo y la confirmacion deben ser iguales.</p>
    </form>

</div>
