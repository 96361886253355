<div class="container-fluid" style="margin-top: 1%">
    <mat-card [tabindex]="0" class="card-principal">
      <div class="container-fluid">
          
          <h2>
            Eventos sociales
          </h2>
       
          <div
            class="d-flex justify-content-center"
            style="margin-top: 5%"
            *ngIf="loading">
            <mat-spinner [diameter]="60"></mat-spinner>            
          </div>
          
  
        <div [hidden]="loading" class="row" style="width: 70%;">
          <div class="col"> 
            <form [formGroup]="filtroBusquedaForm">
              <div class="row" >
                <div class="col-xs-8 col-sm-8 col-md-4 col-lg-3 col-xl-3">
                  <mat-form-field style="width: 100%">
                    <mat-label>Fecha desde *</mat-label>
                      <input matInput formControlName="fechaDesde"  [matDatepicker]="pickerfechaDesde">
                      <mat-datepicker-toggle matSuffix [for]="pickerfechaDesde" matTooltip="Seleccionar fecha" ></mat-datepicker-toggle>
                      <mat-datepicker #pickerfechaDesde ></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-xs-8 col-sm-8 col-md-4 col-lg-3 col-xl-3">
                    <mat-form-field style="width: 100%;">
                      <mat-label>Fecha hasta *</mat-label>
                      <input matInput formControlName="fechaHasta" [matDatepicker]="pickerfechaHasta">
                      <mat-datepicker-toggle matSuffix [for]="pickerfechaHasta" matTooltip="Seleccionar fecha"></mat-datepicker-toggle>
                      <mat-datepicker #pickerfechaHasta ></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-xs-8 col-sm-8 col-md-4 col-lg-3 col-xl-3">
                  <mat-form-field style="width: 100%;">
                    <mat-label>Tipo de evento</mat-label>
                    <mat-select formControlName="tipoEvento">
                      <mat-option *ngFor="let tipo of this.tipoEventos" [value]="tipo.id">
                          {{ tipo.descripcion }}
                      </mat-option>
                  </mat-select>
                  </mat-form-field>
              </div>
                <div class="col-xs-8 col-sm-8 col-md-4 col-lg-3 col-xl-2">
                  <div id="div_buscar_eventos">
                    <span>
                      <button mat-raised-button id="boton_simple"
                        color="primary" 
                        (click)="buscarEventosSociales()" 
                        style="border-radius: 20px;">
                        <mat-icon>search</mat-icon>
                        Buscar eventos
                      </button>
                    </span>
                  </div>              
                </div>
                <div id="div_agregar_evento">
                  <span> 
                    <button mat-raised-button id="boton_simple"
                      color="primary" 
                      style="border-radius: 20px;"
                      (click)="agregarEvento()">                      
                      <mat-icon>add</mat-icon>
                      Agregar Evento
                    </button>                    
                  </span>
                </div>
              </div> 
            </form>
           </div>  
        </div> 
  
        <mat-card-content>
          
        <div class="row" style="margin-top: 1%;">
          <div [hidden]="loading" class="col" style="overflow: auto;">           
            
            <div style="text-align: center; margin-top: 5%;  " *ngIf="sinEventos">
              <mat-card class="sin-elementos-card">
                <h5 style="color:#3F51B5">{{mensajeSP}}</h5>
              </mat-card>
            </div>
  
            <mat-form-field *ngIf="!sinEventos">
              <mat-label>Buscar</mat-label>
              <input matInput (keyup)="applyFilter($event)" autocomplete="off"/>
            </mat-form-field>
            <span style="margin-left:1%;"></span>

            
            <div class="contenedor-tabla">
            <table
              mat-table
              [dataSource]="dataSourceItems"
              #tablaCamas
              class="mat-elevation-z8"
              *ngIf="!sinEventos" 
            >
           
              <!-- Columna Tipo -->
              <div style="text-align: center; margin-right: 1px">
                <ng-container matColumnDef="tipoEvento">
                  <th mat-header-cell *matHeaderCellDef> Tipo de Evento </th>
                    
                  <td mat-cell *matCellDef="let evento"> 
                    {{ evento.tipo.descripcion }}
                  </td>
                                  
                </ng-container>
              </div>
              
              <!-- Columna Fecha -->
              <div style="text-align: center; margin-right: 1px">
                <ng-container matColumnDef="fechaEvento">
                  <th mat-header-cell *matHeaderCellDef> Fecha </th>
                    
                  <td mat-cell *matCellDef="let evento">
                    {{ evento.fecha }}
                  </td>
                 
                </ng-container>
              </div>
  
              <!-- Columna Sector -->
              <div style="text-align: center; margin-right: 1px">
                <ng-container matColumnDef="sectorEvento">
                  <th mat-header-cell *matHeaderCellDef> Sector </th>
                  
                  <td mat-cell *matCellDef="let evento">
                    {{ evento.sector.descripcion }}
                  </td>
                 
                </ng-container>
              </div>
  
              <!-- Columna Descripción -->
              <div style="text-align: center; margin-right: 1px">
                <ng-container matColumnDef="descripcionEvento">
                  <th mat-header-cell *matHeaderCellDef> Descripción </th>
                  
                  <td mat-cell *matCellDef="let evento">
                    {{ evento.descripcion }}
                  </td>
                 
                </ng-container>
              </div>              
  
              <!-- Columna Acciones -->
              <div style="text-align: center; margin-right: 1px">
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef>Acciones</th>
                  <td mat-cell *matCellDef="let evento" class="action-link">
  
                    <!-- Botón Editar Evento -->
                      <button
                        matTooltip="Editar"
                        (click)="editarEvento(evento)"
                        color="primary"
                        mat-icon-button
                      >
                      <mat-icon>edit</mat-icon>  
                      </button>

                    <!-- Botón Eliminar Evento -->
                      <button
                        matTooltip="Eliminar"
                        (click)="abrirDialogConfirmacion(evento)"
                        color="warn"
                        mat-icon-button
                      >
                      <mat-icon>delete_outline</mat-icon>  
                      </button>
                  </td>
                </ng-container>
              </div>
  
              <tr mat-header-row *matHeaderRowDef="displayedColumnasItems; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnasItems"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[10, 15, 25]" aria-label="Seleccionar pagina" *ngIf="!sinEventos"></mat-paginator>
          </div>
  
          </div>
        </div>
      </mat-card-content>
  
      </div>
    </mat-card>
    <br>
  </div>
  