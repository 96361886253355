import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup , UntypedFormBuilder} from '@angular/forms';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { ComunicacionService } from '../services/comunicacion.service';
import { DialogEditarNotificacionesComponent } from '../dialog-editar-notificaciones/dialog-editar-notificaciones.component';
import { Router } from '@angular/router';
import { SlicePipe } from '@angular/common';

@Component({
  selector: 'app-notificacion-correo-pacientes',
  templateUrl: './notificacion-correo-pacientes.component.html',
  styleUrls: ['./notificacion-correo-pacientes.component.css']
  
})
export class NotificacionCorreoPacientesComponent implements OnInit {
  displayedColumns: string[] = ['Motivo' , 'sectorTurno', 'Asunto', 'Mensaje','Acciones'];
  dataSourceItems: any;
  currentPatient:any;
  notificacionesForm:UntypedFormGroup;
  notis:any[];
  

  constructor(
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private comunicacionService: ComunicacionService
  ) { 
    
  }

  ngOnInit() {
    this.iniciarFormulario();
    this.listarNotificacionesCorreo();
  }

  iniciarFormulario(){
    this.notificacionesForm = this.formBuilder.group({
      
      Motivo: [''],
      sectorTurno: [''],
      Asunto: [''],
      Mensaje: [''],
      estado: ['ACT'] 

    })

  }
  async listarNotificacionesCorreo(){
    
    
    await this.comunicacionService.getNotificacionesCorreo('ACT').toPromise().then(
      (data)=>{
      console.log(data);
      if(data.ok){
        // console.log(data)
        this.notis = data.elementos;
        if(data.elementos.length > 0){
          this.dataSourceItems = new MatTableDataSource(this.notis);
        }
      }
    }
    )

  }

  //editar notificaciones

  openeditarNotificacion(notificacion) {
      this.comunicacionService.setCurrentNotificacion(notificacion);
      this.router.navigate(['/editar-notificacion']);
    
    
    /*
    this.dialog.open(DialogEditarNotificacionesComponent, {
        width: '600px',
        height:'600px',
        data: notificacion,
      }).afterClosed().subscribe(() => {  
          this.route.navigate(['/notificacionPaciente']);
          window.location.reload();
      })
    */
}

};
   
   
    
   
   
  