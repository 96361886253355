import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { ComunicacionService } from '../services/comunicacion.service';
import { DatePipe } from '@angular/common';
import { SplitStringPipe } from '../pipes/split-string.pipe';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { RespuestaCarga } from './../dialog-mensaje/dialog-mensaje.component';
import { MatDialog } from '@angular/material/dialog';
import { EmbedUrlsService } from '../services/embedUrls.service';
import { Aplicacion } from './../agregar-noticias/agregar-noticias.component';
import { Noticia } from './../mostrar-noticias/mostrar-noticias.component';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';

export class Sitio implements Aplicacion{
  app_id: number;
  nombre: string;
}
interface IAplicacionCheckBox extends Aplicacion{
  formControl: string;
}
@Component({
  selector: 'app-editar-noticias',
  templateUrl: './editar-noticias.component.html',
  styleUrls: ['./editar-noticias.component.css'],
  providers: [DatePipe, SplitStringPipe],
})
export class EditarNoticiasComponent implements OnInit {
  @ViewChild('mySelect') mySelect;
  private IDAPLICACION = 33;
  
  public editor = DecoupledEditor;
  noticiasForm:  FormGroup = new FormGroup({
    titulo: new FormControl(),
    texto: new FormControl(),
    imagen: new FormControl(),
    fecha_publicacion: new FormControl(),
    estado: new FormControl(),
    intranet: new FormControl(),
    sitioMed: new FormControl(),
    sitioEnf: new FormControl(),
    portalPac: new FormControl(),
  });

  noticiaModificar: Noticia;
  loading: boolean;
  submitted: boolean;
  private usuarioLog: string;
  private ordenNoticia: number;

  private sitios: IAplicacionCheckBox[];
  private sitiosActivos: Aplicacion[];

  todos_seleccionados: boolean = false;
  mostrartexto: boolean = true;
  portalactivo: boolean = false;

  public onReady(editor) {
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private comunicacionService: ComunicacionService,
    private splitString: SplitStringPipe,
    public dialog: MatDialog,
    private EmbedUrlSer: EmbedUrlsService,
    private router: Router,
    private datePipe: DatePipe,
    private rutaActiva: ActivatedRoute
  ) {
    this.EmbedUrlSer.embedUrls.subscribe((funciones) => {
      if (
        funciones.find((funcion) => {
          return funcion.url === 'agregar-noticias';
        })
      ) {
      } else {
        this.router.navigate(['/inicio']);
      }
    });
    this.loading = false;
    this.submitted = false;
  }
  
  async ngOnInit() {
    this.usuarioLog = JSON.parse(sessionStorage.getItem('currentUser')).usuario;
    
    this.rutaActiva.params.subscribe((parametro) => {
      parametro
        ? (this.ordenNoticia = parametro.numero)
        : this.router.navigate(['/inicio']);
    });
    
    this.getNoticiaEditar();   
  }

  getNoticiaEditar() {
    this.comunicacionService
      .getNoticiaEditar(this.ordenNoticia, this.usuarioLog)
      .subscribe(
        (res: Noticia) => {
          if (res) {
            this.noticiaModificar = res;
         
            this.buildForm();
            this.cargarSelectAplicaciones();
          } else {
            this.router.navigate(['/inicio']);
          }
        },
        (error) => {
          console.log(error);
        }
      ); 
  }

  private buildForm() {
    
    this.noticiasForm = this.formBuilder.group({
      titulo: [this.noticiaModificar.titulo, Validators.required],
      texto: [
        decodeURIComponent(escape(atob(this.noticiaModificar.texto))),
        Validators.required,
      ],
      imagen: [],
      fecha_publicacion: [
        new Date(this.noticiaModificar.fechaPublicacion),
        Validators.required,
      ],
      estado: [this.noticiaModificar.estado, Validators.required],
      sitioMed: [],
      intranet: [],
      sitioEnf: [],
      portalPac: [],      
    });
  }
  public cancelar() {
    this.dialog
      .open(DialogConfirmacionComponent, {
        data: `¿Seguro desea cancelar la modificación de la noticia?`,
      })
      .afterClosed()
      .subscribe((confirmado: boolean) => {
        if (confirmado) {
          this.router.navigate(['/listar-noticias']);
        }
      });
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.noticiasForm.get('imagen').setValue({
          nombre: file.name,
          tipo: '.' + this.splitString.transform(file.name),
          valor: (reader.result as string).split(',')[1],
        });
      };
    }
  }
  openDialog(respuesta: RespuestaCarga): void {
    const dialogRef = this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: respuesta,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (respuesta.ok) {
        this.router.navigate(['/listar-noticias']);
      }
    });
  }

  onSubmit() {
    if (this.noticiasForm.valid) {
      
      this.loading = true;
      const dateSendingToServer = new DatePipe('en-US').transform(
        this.noticiasForm.value.fecha_publicacion,
        'medium'
      );      
      
      // creo el vector con los sitios activos
      this.sitiosActivos = new Array<IAplicacionCheckBox>();
      this.sitios.filter(s => this.noticiasForm.get(s.formControl).value == true).forEach(s => {      
        this.sitiosActivos.push(s);
      });

      // noticia en estado INA cuando no está en ningun sitio
      if(this.sitiosActivos.length > 0){        
        this.noticiasForm.value.estado = 'ACT';
      } else {
        this.noticiasForm.value.estado = 'INA';
      }


      this.comunicacionService
        .editarNoticia(
          this.ordenNoticia,
          this.usuarioLog,
          this.noticiasForm.value.titulo,
          btoa(unescape(encodeURIComponent(this.noticiasForm.value.texto))),
          this.noticiasForm.value.imagen
            ? this.noticiasForm.value.imagen.nombre
            : null,
          this.noticiasForm.value.imagen
            ? this.noticiasForm.value.imagen.valor
            : null,
          dateSendingToServer,
          this.noticiasForm.value.estado,
          this.sitiosActivos,
          this.sitios,
          null
        )
        .subscribe(
          (res) => {
            this.loading = false;
            this.openDialog(res);
          },
          (error) => {
            console.log('error' + error);
          }
        );
    }
  }

  async cargarSelectAplicaciones(){
    this.sitios = new Array<IAplicacionCheckBox>();

    this.comunicacionService.getAplicaciones(this.usuarioLog).subscribe(
      (resp: Aplicacion[]) => {
        if (resp) {
          //this.sitios = resp;
          resp.forEach(r => {
            let sitio: IAplicacionCheckBox = {
              app_id: r.app_id,
              nombre: r.nombre,
              formControl: ''
            };
            
            let agregar = false;
            switch (r.nombre) {
              case 'Intranet':
                sitio.formControl = 'intranet';
                agregar = true;
                break;
              case 'Sitio Medico':
                sitio.formControl = 'sitioMed';
                agregar = true;
                break; 
              // case 'Sitio Enfermeria':
              //   sitio.formControl = 'sitioEnf';
              //   break;
               case 'Portal de Pacientes':
                 sitio.formControl = 'portalPac';
                 agregar = true;
                 break;                            
              default:
                break;
            }
            
            if(agregar){
              this.noticiasForm.get(sitio.formControl).setValue(false);
              this.sitios.push(sitio);
            }     
            
          });
          //console.log(this.sitios);
        }
      },
      (error) => {
        console.log(error);
      }
  );
        
    this.comunicacionService.getAplicacionesActivas(this.ordenNoticia, this.usuarioLog).subscribe(
      (resp: Aplicacion[]) => {     
               
        if (resp) {
          resp.forEach(r => {            
            this.sitios.filter(s => s.app_id == r.app_id).forEach(s => {              
              this.noticiasForm.get(s.formControl).setValue(true);       
              if (s.app_id === 32){
                this.mostrartexto= false
                this.noticiasForm.get('texto').clearValidators()
                this.noticiasForm.get('texto').setErrors(null)
                this.noticiasForm.get('sitioMed').disable();
                this.noticiasForm.get('intranet').disable();
                this.noticiasForm.get('sitioEnf').disable();
              }
              if (s.app_id !== 32) {
                this.noticiasForm.get('portalPac').disable();
              }
            }
            );            
          });                              
        }   
      },
      (error) => {
        console.log(error);        
      }
    );
  }

  noHaySitioSeleccionado() {
    return !this.sitios.some(s => this.noticiasForm.get(s.formControl).value === true);
  }
  
}

