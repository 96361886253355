
  <mat-card class="py-1 my-1" style="text-align: center">
    <mat-card-title>Notificaciones de correo</mat-card-title>
      <br>
  

  <!-- Tabla -->
  <table mat-table [dataSource]="dataSourceItems" class="mat-elevation-z8">
    <div class="row">
    <!-- Motivo -->
    <ng-container matColumnDef="Motivo">
      <th mat-header-cell *matHeaderCellDef> Motivo </th>
      <td mat-cell *matCellDef="let element"> <strong>{{element.motivo}}</strong> </td>
    </ng-container>
       <!-- sectorTurno -->
       <ng-container matColumnDef="sectorTurno">
        <th mat-header-cell *matHeaderCellDef> Sector turno </th>
        <td mat-cell *matCellDef="let element"> <strong>{{element.sectorTurno}}</strong> </td>
      </ng-container>
    <!-- Asunto --> 
    <ng-container matColumnDef="Asunto">
      <th mat-header-cell *matHeaderCellDef> Asunto </th>
      <td mat-cell *matCellDef="let element" [innerHTML]="element.asunto"><p class="contenido" >{{element.asunto}}</td>
    </ng-container>
    <!-- Mensaje -->
    <ng-container matColumnDef="Mensaje">
      <th mat-header-cell *matHeaderCellDef> Mensaje </th>
      <td mat-cell *matCellDef="let element" ><p class="contenido" [innerHTML]="element.contAdicional.slice(0,90)" >{{element.contAdicional}}</p>...</td>
    </ng-container>
    <!-- Acciones -->
    <ng-container matColumnDef="Acciones">
      <th mat-header-cell *matHeaderCellDef> Acciones </th>
      <td mat-cell *matCellDef="let element" class="action-link"> 
      <!-- Boton editar notificacion -->

      <button mat-icon-button class="icon-button"  color="primary" matTooltip="Editar notificacion" class="icon" (click)="openeditarNotificacion(element)">
        <mat-icon>edit</mat-icon>
      </button>
      
      </td>
    </ng-container>
  </div>
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" ></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <br>
</mat-card>
