import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-dialog-cambiar-contrasena',
  templateUrl: './dialog-cambiar-contrasena.component.html',
  styleUrls: ['./dialog-cambiar-contrasena.component.css']
})

export class DialogCambiarContrasenaComponent {

  PASSWORD_MIN_LENGTH: string = 'passMinLength';
  REGEX_MIN_LENGTH: string = '^.{11,255}$';
  PASSWORD_UPPER_CASE: string = 'passUppercase';
  REGEX_UPPER_CASE: string = '^(?=.*[A-Z])';
  PASSWORD_LOWER_CASE: string = 'passLowercase';
  REGEX_LOWER_CASE: string = '^(?=.*[a-z])';
  PASSWORD_MIN_ONE_NUM: string = 'passNum';
  REGEX_MIN_ONE_NUM: string = '^(?=.*[0-9])';
  // PASSWORD_WHITESPACE: string = 'passWhitespace';
  // REGEX_WHITESPACE: string = "^(?=.*[\\s+$])";     // SI CONTIENE ESPACIO EN BLANCO
  PASSWORD_END_WHITESPACE: string = 'passEndWhitespace';
  REGEX_END_WHITESPACE: string = "\\s+$";            // SI TERMINA EN ESPACIO EN BLANCO
  loading = true;

  actualizarContraForm: FormGroup = new FormGroup({
    actual: new FormControl(),
    password: new FormControl(),
    confirmPassword: new FormControl(),
  }, {
    validators: []
  });
  
  userData;
  submitted = false;
  mensaje;
  loadingSubmit: boolean = false;
  showPass = [ false, false, false ];
  valueProgressBar: number = 0;
  colorProgressBar: string = 'redStyle';
  arrayPasswordStrength = [];
  existeError: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public userService: UserService,
    public dialogRef: MatDialogRef<DialogCambiarContrasenaComponent>,
    public dialog: MatDialog,
    public authService: AuthenticationService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.userData = data;
    this.loading = false;
  }

  async ngOnInit() {
    await this.crearFormulario();
  }

  async crearFormulario() {
    this.actualizarContraForm = this.formBuilder.group({
      actual: [ '', Validators.compose([ Validators.required ]) ],
      password: [ '',
        Validators.compose([ Validators.required, this.passwordValidator(this.PASSWORD_MIN_LENGTH), Validators.maxLength(255), 
          this.passwordValidator(this.PASSWORD_UPPER_CASE), this.passwordValidator(this.PASSWORD_LOWER_CASE), 
          this.passwordValidator(this.PASSWORD_MIN_ONE_NUM), this.passwordEndWhitespaceValidator(this.PASSWORD_END_WHITESPACE) ])
      ],
      confirmPassword: [ '', Validators.compose([ Validators.required ]) ]
    }, {
      validators: Validators.compose([ this.passMustNotMatch(), this.passMustMatch() ])
    });
  }

  async onSubmit() {
    this.loadingSubmit = true;
    this.submitted = true;
    this.existeError = false;

    if (this.actualizarContraForm.invalid) {
      this.actualizarContraForm.markAllAsTouched();
      this.loadingSubmit = false;
      return;
    }
    
    await this.userService.passwordChange(this.userData.usuario, this.actualizarContraForm.value).toPromise().then(data => {
      // console.log(data)
      this.abrirDialogMensaje(data);
      this.cerrar(false);
    }).catch(error => {
      console.error(error);
      this.existeError = true;

      // const respuesta = {
      //   ok: false,
      //   mensaje: 'Ocurrio un error al intentar cambiar la contraseña. Intente nuevamente.'
      // }

      this.loadingSubmit = false;
    }).finally(() => {
      // this.loadingSubmit = false;
    })

    this.actualizarContraForm.reset();
    this.submitted = false;
  }

  showHidePassword(index) {
    this.showPass[index] = !this.showPass[index];
  }

  abrirDialogMensaje(resp) {
    this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      // height: '100px',
      hasBackdrop: true,
      data: resp,
    }).afterClosed().subscribe((confirmado: boolean) => {
        // console.log(confirmado)
      }, (error) => {
        console.error(error);
    });
  }

  cerrar(paramLogout) {
    this.dialogRef.close(paramLogout);
  }

  getPasswordStrength() {
    this.valueProgressBar = this.arrayPasswordStrength.length * 25;

    switch (this.valueProgressBar) {
      case 0:
        this.colorProgressBar = 'redStyle';
        break;
      case 25:
        this.colorProgressBar = 'redStyle';
        break;
      case 50:
        this.colorProgressBar = 'orangeStyle';
        break;
      case 75:
        this.colorProgressBar = 'yellowStyle';
        break;
      case 100:
        this.colorProgressBar = 'greenStyle';
        break;
      default:
        break;
    }
  }

  addPasswordStrength(elemento: string) {
    if (!this.arrayPasswordStrength.includes(elemento)) {
      this.arrayPasswordStrength.push(elemento);
      this.getPasswordStrength();
    }
  }

  removePasswordStrength(elemento: string) {
    if (this.arrayPasswordStrength.includes(elemento)) {
      this.arrayPasswordStrength = this.arrayPasswordStrength.filter(elmt => elmt != elemento);
      this.getPasswordStrength();
    }
  }

  getRegex(validator: string) {
    switch (validator) {
      case this.PASSWORD_MIN_LENGTH:
        return this.REGEX_MIN_LENGTH;
      case this.PASSWORD_UPPER_CASE:
        return this.REGEX_UPPER_CASE;
      case this.PASSWORD_LOWER_CASE:
        return this.REGEX_LOWER_CASE;
      case this.PASSWORD_MIN_ONE_NUM:
        return this.REGEX_MIN_ONE_NUM;
      case this.PASSWORD_END_WHITESPACE:
        return this.REGEX_END_WHITESPACE;
      default:
        break;
    }
  }

  passwordEndWhitespaceValidator(validator: string) {
    return () => {
      const passwordControl = this.actualizarContraForm.controls['password'];
      const REGEX = this.getRegex(validator);

      if (passwordControl.value && passwordControl.value.match(REGEX)) {
        this.addPasswordStrength(validator);
        return { [validator]: true }
      } else {
        this.removePasswordStrength(validator);
        return null;
      }
    }
  }

  passwordValidator(validator: string) {
    return () => {
      const passwordControl = this.actualizarContraForm.controls['password'];
      const REGEX = this.getRegex(validator);

      if (passwordControl.value && passwordControl.value.match(REGEX)) {
        this.addPasswordStrength(validator);
        return null
      } else {
        this.removePasswordStrength(validator);
        return { [validator]: true }
      }
    }
  }

  passMustNotMatch() {
    return () => {
      const actualPass = this.actualizarContraForm.controls['actual'];
      const pass = this.actualizarContraForm.controls['password'];

      if (actualPass.value === pass.value) {
        return { passMustNotMatch: true }
      } else {
        return null
      }
    };
  }

  passMustMatch() {
    return () => {
      const pass = this.actualizarContraForm.controls['password'];
      const confPass = this.actualizarContraForm.controls['confirmPassword'];

      if (pass.value !== confPass.value) {
        return { passMustMatch: true }
      } else {
        return null
      }
    };
  }
}
