<div class="row" id="row-header" style="border: 0px solid green">
  <div
    class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 m-auto"
    id="col-left"
    style="border: 0px solid violet"
  >
    <a>
      <h1 style="font-family: Helvetica">
        <img
          (click)="goHome()"
          id="logo-intranet"
          src="../../assets/images/login/logo HE.png"
        />
        intranet
      </h1>
    </a>
  </div>
  <!--
  <div
    class="d-none d-md-block col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 m-auto"
    id="col-mid"
    style="border: 0px solid violet"
  >
    <a><img src="../../assets/images/login/1_efector_com.png" alt="" /></a>

    <a id="link" href="http://www.cenas.org.ar" target="_blank"
      ><img src="../../assets/images/login/2_cenas.png" alt=""
    /></a>

    <a href="http://www.cidcam.org" target="_blank"
      ><img src="../../assets/images/login/3_cidam.png" alt=""
    /></a>
  </div>
  -->

  <div
    class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 m-auto"
    id="col-right"
    style="border: 0px solid violet"
  >
    <div *ngIf="usserLogged">
      <span>{{ usserLogged.nombreCompleto }}</span>
      <button
        mat-icon-button
        class="menu"
        mat-button
        [matMenuTriggerFor]="belowMenu"
        id="boton"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #belowMenu="matMenu" yPosition="below">
        <button mat-menu-item (click)="verConvenioConf()" style="outline: none">Ver convenio de confidencialidad</button>
        <button mat-menu-item (click)="cambiarContrasena()" style="outline: none">Actualizar Contraseña</button>
        <button mat-menu-item (click)="logout()" style="outline: none">Cerrar sesión</button>
      </mat-menu>
    </div>
  </div>
</div>

<!-- Modal  ver convenio de confidencialidad -->
<button
  id="openModalVerConvenioConf"
  type="button"
  class="btn btn-info btn-lg"
  data-toggle="modal"
  data-target="#modalVerConvenioConf"
  [hidden]="true"
>
  Open The Modal Box 
</button>
<div
  class="modal fade "
  id="modalVerConvenioConf"
  tabindex="-1"
  data-backdrop="false"
  data-dismiss="modal"
  role="dialog"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content ">
      <div class="modal-header">
        <h5 class="modal-title">Convenio de confidencialidad firmado</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div  style="
				  position: relative;
    			padding-bottom: 56.25%;
    			height: 0;
    			overflow: hidden; ">
        <iframe id= "pdfDinamico"
              style="
					      position: absolute ;
					      top: 0;
					      left: 0;
					      width: 100%;
					      height: 100%;"
              type="application/pdf">
        </iframe>
        </div>
      </div>
    </div>
  </div>
</div>

